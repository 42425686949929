import styled from "@emotion/styled";
import { Card } from "@mui/material";

const StyledCard = styled(Card)`
  // styles here
`;

StyledCard.propTypes = Card.propTypes;
StyledCard.displayName = "Card";

export { StyledCard };
