import React from "react";
import { StyledIconButton } from "./styles";


/**
 * General IconButton component in JSDOC
 * @param {object} props Component props
 *
 */
const IconButton = React.forwardRef((props, ref) => {
  return (
    <StyledIconButton {...props} ref={ref}/>
  );
});
IconButton.displayName = StyledIconButton.displayName;
IconButton.propTypes = StyledIconButton.propTypes;
export default IconButton;
