import styled from "@emotion/styled";
import { DialogTitle } from "@mui/material";

const StyledDialogTitle = styled(DialogTitle)`
  // styles here
`;

StyledDialogTitle.propTypes = DialogTitle.propTypes;
StyledDialogTitle.displayName = "DialogTitle";

export { StyledDialogTitle };
