import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

const StyledIconButton = styled(IconButton)`
  // styles here
`;

StyledIconButton.propTypes = IconButton.propTypes;
StyledIconButton.displayName = "IconButton";

export { StyledIconButton };
