import styled from "@emotion/styled";
import { StepContent } from "@mui/material";

const StyledStepContent = styled(StepContent)`
  // styles here
`;

StyledStepContent.propTypes = StepContent.propTypes;
StyledStepContent.displayName = "StepContent";

export { StyledStepContent };
