import styled from "@emotion/styled";
import { Table } from "@mui/material";

const StyledTable = styled(Table)`
  // styles here
`;

StyledTable.propTypes = Table.propTypes;
StyledTable.displayName = "Table";

export { StyledTable };
