import styled from "@emotion/styled";
import { DialogContent } from "@mui/material";

const StyledDialogContent = styled(DialogContent)`
  // styles here
`;

StyledDialogContent.propTypes = DialogContent.propTypes;
StyledDialogContent.displayName = "DialogContent";

export { StyledDialogContent };
