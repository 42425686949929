import styled from "@emotion/styled";
import { FormControlLabel, Radio } from "@mui/material";

const StyledFormControlLabel = styled(FormControlLabel)`
  // styles here
`;

StyledFormControlLabel.propTypes = FormControlLabel.propTypes;
StyledFormControlLabel.displayName = "FormControlLabel";

export { StyledFormControlLabel };
