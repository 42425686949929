import React from "react";
import { StyledToolbar } from "./styles";


/**
 * General Toolbar component in JSDOC
 * @param {object} props Component props
 *
 */
const Toolbar = React.forwardRef((props, ref) => {
  return (
    <StyledToolbar {...props} ref={ref}/>
  );
});
Toolbar.displayName = StyledToolbar.displayName;
Toolbar.propTypes = StyledToolbar.propTypes;
export default Toolbar;
