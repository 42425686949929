import React from "react";
import { StyledContainer } from "./styles";


/**
 * General Container component in JSDOC
 * @param {object} props Component props
 *
 */
const Container = React.forwardRef((props, ref) => {
  return (
      <StyledContainer {...props} ref={ref} />
  );
});
Container.displayName = StyledContainer.displayName;
Container.propTypes = StyledContainer.propTypes;
export default Container;
