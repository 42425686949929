import React from "react";
import { StyledLink } from "./styles";


/**
 * General Link component in JSDOC
 * @param {object} props Component props
 *
 */
const Link = React.forwardRef((props, ref) => {
  return (
    <StyledLink {...props} ref={ref}/>
  );
});
Link.displayName = StyledLink.displayName;
Link.propTypes = StyledLink.propTypes;
export default Link;
