import styled from "@emotion/styled";
import { TableCell } from "@mui/material";

const StyledTableCell = styled(TableCell)`
  // styles here
`;

StyledTableCell.propTypes = TableCell.propTypes;
StyledTableCell.displayName = "TableCell";

export { StyledTableCell };
