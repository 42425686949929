import styled from "@emotion/styled";
import { Stepper } from "@mui/material";

const StyledStepper = styled(Stepper)`
  // styles here
`;

StyledStepper.propTypes = Stepper.propTypes;
StyledStepper.displayName = "Stepper";

export { StyledStepper };
