import React from "react";
import { StyledImageListItemBar } from "./styles";


/**
 * General ImageListItemBar component in JSDOC
 * @param {object} props Component props
 *
 */
const ImageListItemBar = React.forwardRef((props, ref) => {
  return (
    <StyledImageListItemBar {...props} ref={ref}/>
  );
});
ImageListItemBar.displayName = StyledImageListItemBar.displayName;
ImageListItemBar.propTypes = StyledImageListItemBar.propTypes;
export default ImageListItemBar;
