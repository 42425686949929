import styled from "@emotion/styled";
import { Autocomplete } from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)`
  // styles here
`;

StyledAutocomplete.propTypes = Autocomplete.propTypes;
StyledAutocomplete.displayName = "Autocomplete";

export { StyledAutocomplete };
