import React from "react";
import { StyledButtonGroup } from "./styles";


/**
 * General ButtonGroup component in JSDOC
 * @param {object} props Component props
 *
 */
const ButtonGroup = React.forwardRef((props, ref) => {
  return (
    <StyledButtonGroup {...props} ref={ref}/>
  );
});
ButtonGroup.displayName = StyledButtonGroup.displayName;
ButtonGroup.propTypes = StyledButtonGroup.propTypes;
export default ButtonGroup;
