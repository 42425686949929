import React from "react";
import { StyledTableBody } from "./styles";


/**
 * General TableBody component in JSDOC
 * @param {object} props Component props
 *
 */
const TableBody = React.forwardRef((props, ref) => {
  return (
    <StyledTableBody {...props} ref={ref}/>
  );
});
TableBody.displayName = StyledTableBody.displayName;
TableBody.propTypes = StyledTableBody.propTypes;
export default TableBody;
