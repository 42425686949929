import React from "react";
import { StyledSelect } from "./styles";


/**
 * General Select component in JSDOC
 * @param {object} props Component props
 *
 */
const Select = React.forwardRef((props, ref) => {
  return (
    <StyledSelect {...props} ref={ref}/>
  );
});
Select.displayName = StyledSelect.displayName;
Select.propTypes = StyledSelect.propTypes;
export default Select;
