import styled from "@emotion/styled";
import { Collapse } from "@mui/material";

const StyledCollapse = styled(Collapse)`
  // styles here
`;

StyledCollapse.propTypes = Collapse.propTypes;
StyledCollapse.displayName = "Collapse";

export { StyledCollapse };
