import styled from "@emotion/styled";
import { Fab } from "@mui/material";

const StyledFab = styled(Fab)`
  // styles here
`;

StyledFab.propTypes = Fab.propTypes;
StyledFab.displayName = "Fab";

export { StyledFab };
