import React from "react";
import { StyledPopover } from "./styles";


/**
 * General Popover component in JSDOC
 * @param {object} props Component props
 *
 */
const Popover = React.forwardRef((props, ref) => {
  return (
    <StyledPopover {...props} ref={ref}/>
  );
});
Popover.displayName = StyledPopover.displayName;
Popover.propTypes = StyledPopover.propTypes;
export default Popover;
