import React from "react";
import { StyledImageList } from "./styles";


/**
 * General ImageList component in JSDOC
 * @param {object} props Component props
 *
 */

const ImageList = React.forwardRef((props, ref) => {
  return (
    <StyledImageList {...props} ref={ref} />
  );
});

ImageList.displayName = StyledImageList.displayName;
ImageList.propTypes = StyledImageList.propTypes;
export default ImageList;
