import React from "react";
import { StyledCardActionArea } from "./styles";


/**
 * General CardActionArea component in JSDOC
 * @param {object} props Component props
 *
 */
const CardActionArea = React.forwardRef((props, ref) => {
  return (
    <StyledCardActionArea {...props} ref={ref}/>
  );
});
CardActionArea.displayName = StyledCardActionArea.displayName;
CardActionArea.propTypes = StyledCardActionArea.propTypes;
export default CardActionArea;
