import styled from "@emotion/styled";
import { RadioGroup } from "@mui/material";

const StyledRadioGroup = styled(RadioGroup)`
  // styles here
`;

StyledRadioGroup.propTypes = RadioGroup.propTypes;
StyledRadioGroup.displayName = "RadioGroup";

export { StyledRadioGroup };
