import styled from "@emotion/styled";
import { ListItemText } from "@mui/material";

const StyledListItemText = styled(ListItemText)`
  // styles here
`;

StyledListItemText.propTypes = ListItemText.propTypes;
StyledListItemText.displayName = "ListItemText";

export { StyledListItemText };
