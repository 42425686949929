import React from "react";
import { StyledFilledInput } from "./styles";


/**
 * General FilledInput component in JSDOC
 * @param {object} props Component props
 *
 */
const FilledInput = React.forwardRef((props, ref) => {
  return (
    <StyledFilledInput {...props} ref={ref} />
  );
});
FilledInput.displayName = StyledFilledInput.displayName;
FilledInput.propTypes = StyledFilledInput.propTypes;
export default FilledInput;
