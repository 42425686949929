import styled from "@emotion/styled";
import { ImageListItemBar } from "@mui/material";

const StyledImageListItemBar = styled(ImageListItemBar)`
  // styles here
`;

StyledImageListItemBar.propTypes = ImageListItemBar.propTypes;
StyledImageListItemBar.displayName = "ImageListItemBar";

export { StyledImageListItemBar };
