import React from "react";
import { StyledSlider } from "./styles";


/**
 * General Slider component in JSDOC
 * @param {object} props Component props
 *
 */
const Slider = React.forwardRef((props, ref) => {
  return (
    <StyledSlider {...props} ref={ref}/>
  );
});
Slider.displayName = StyledSlider.displayName;
Slider.propTypes = StyledSlider.propTypes;
export default Slider;
