import React from "react";
import { StyledNativeSelect } from "./styles";


/**
 * General NativeSelect component in JSDOC
 * @param {object} props Component props
 *
 */
const NativeSelect = React.forwardRef((props, ref) => {
  return (
    <StyledNativeSelect {...props} ref={ref}/>
  );
});
NativeSelect.displayName = StyledNativeSelect.displayName;
NativeSelect.propTypes = StyledNativeSelect.propTypes;
export default NativeSelect;
