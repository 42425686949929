import React from "react";
import { StyledTypography } from "./styles";


/**
 * General Typography component in JSDOC
 * @param {object} props Component props
 *
 */
const Typography = React.forwardRef((props, ref) => {
  return (
    <StyledTypography {...props} ref={ref}/>
  );
});
Typography.displayName = StyledTypography.displayName;
Typography.propTypes = StyledTypography.propTypes;
export default Typography;
