import React from "react";
import { StyledCardHeader } from "./styles";


/**
 * General CardHeader component in JSDOC
 * @param {object} props Component props
 *
 */
const CardHeader = React.forwardRef((props, ref) => {
  return (
    <StyledCardHeader {...props} ref={ref}/>
  );
});
CardHeader.displayName = StyledCardHeader.displayName;
CardHeader.propTypes = StyledCardHeader.propTypes;
export default CardHeader;
