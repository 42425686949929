import React from "react";
import { StyledGrow } from "./styles";


/**
 * General Grow component in JSDOC
 * @param {object} props Component props
 *
 */
const Grow = React.forwardRef((props, ref) => {
  return (
    <StyledGrow {...props} ref={ref}/>
  );
});
Grow.displayName = StyledGrow.displayName;
Grow.propTypes = StyledGrow.propTypes;
export default Grow;
