import styled from "@emotion/styled";
import { LinearProgress } from "@mui/material";

const StyledLinearProgress = styled(LinearProgress)`
  // styles here
`;

StyledLinearProgress.propTypes = LinearProgress.propTypes;
StyledLinearProgress.displayName = "LinearProgress";

export { StyledLinearProgress };
