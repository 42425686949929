import React from "react";
import { DataGridPro, LicenseInfo, GridToolbar } from "@mui/x-data-grid-pro";
import { Container, Button } from "../../components";
import { Header } from "./Header";

import { rows, columns } from "./data";

LicenseInfo.setLicenseKey(
  "d895c9b125a530bf8bb21848cde30ee0T1JERVI6MzQ0MzQsRVhQSVJZPTE2NzEyMjEwNDkwMDAsS0VZVkVSU0lPTj0x"
);

export const Assets = () => {
  const [rowsData, setRowsData] = React.useState(rows);
  return (
    <>
      <Header />
      <Container>
        {/* <div style={{ height: "calc(80vh)", width: "100%", marginBottom: 16 }}> */}
        <DataGridPro
          checkboxSelection
          rows={rowsData}
          columns={columns}
          pagination
          components={{ Toolbar: GridToolbar }}
          disableSelectionOnClick
          autoHeight={true}
          // pinnedColumns={{ left: ["__check__"] }}
          // isRowSelectable={false}`
        />
        <Button
          style={{ marginTop: 10 }}
          variant="contained"
          onClick={() =>
            setRowsData([
              ...rowsData,
              {
                id: rowsData.length + 1,
                asset: "https://place-hold.it/60x60",
                parent: "",
                company: "New Data " + rowsData.length,
                site: "site",
                inspected: "",
                status: "status",
                actions: "actions",
              },
            ])
          }
        >
          Add Assets to a Route
        </Button>
        {/* </div> */}
      </Container>
    </>
  );
};
