import React, {
  useEffect,
  useState,
  // useEffect
} from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { termsOfUse } from "./termsOfUse";
import * as Yup from "yup";
import { InfoOutlined, Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Page,
  // Modal,
  // FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Stack,
  Autocomplete,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "../../components";

import Dialog from "../../components/Dialog/Dialog";
import useNotification from "./useNotification";
import { countries } from "../../utils/countries";
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   height: "100%",
//   overflowY: "scroll",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   bgcolor: "background.paper",
//   boxShadow: 24,
// };

export const CreateAccount = ({
  signInPath = "/sign-in",
  Auth,
  product,
  signUp,
  Mode,
  ssoTimeout = 5000,
}) => {
  const [privacyPolicy] = useState(termsOfUse);
  const [authMethod, setAuthMethod] = useState("sso");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { showNotification, NotificationComponent } = useNotification();
  const formik = useFormik({
    initialValues: {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      privacyPolicy: false,
      termsOfUse: false,
      receiveNews: "yes",
      // locale: null,
      local: {
        code: "US",
        label: "United States",
        phone: "1",
        suggested: true,
      },
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      username: Yup.string()
        .trim()
        .min(5)
        .max(15)
        .required("Username is required"),
      password: Yup.string()
        .min(6)
        .max(255)
        .required("Password is required")
        .matches(/[A-Z]/, "Need at least an uppercase letter")
        .matches(/[a-z]/, "Need at least a lowercase letter")
        .matches(/[0-9]/, "Need at least a number")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
          "Need at least a special character"
        ),
      // .matches(/[!@#%&]/, "Need at least a special character"),
      // Update special character
      confirmPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      privacyPolicy: Yup.boolean().oneOf([true], "This field must be checked"),
      termsOfUse: Yup.boolean().oneOf([true], "This field must be checked"),
      receiveNews: Yup.string().required("This field must be checked"),
      // locale: Yup.object().nullable().required("Country / Region is required"),
      locale: Yup.object().nullable(),
    }),
    onSubmit: () => {
      async function submit() {
        // console.log("formik", formik);
        let {
          email,
          password,
          privacyPolicy,
          termsOfUse,
          username,
          receiveNews,
          // locale,
        } = formik.values;
        const attributes = {
          email,
          // locale: locale?.label,
          locale: "United States",
          name: username,
          "custom:privacy_policy": termsOfUse ? "yes" : "no",
          "custom:terms_of_use": privacyPolicy ? "yes" : "no",
          "custom:email_notification": receiveNews,
        };
        try {
          if (authMethod === "sso") {
            setTimeout(() => {
              setError("User already exists.");
              formik.setSubmitting(false);
            }, ssoTimeout);
            signUp &&
              (await signUp(
                password,
                email,
                username,
                self.crypto.randomUUID()
              ));
            showNotification(
              "Success! \nPlease check your email for verification link.",
              "success",
              () => {
                navigate(`${signInPath}`);
              }
            );
          } else {
            await onSubmit(username, password, attributes);
            showNotification(
              "Thank you for signing up! \nA verification email has been sent to the email address you provided. \nPlease verify your email to unlock full site features.",
              "success",
              () => {
                let maskedEmail = email.split("@");
                maskedEmail[0] = maskedEmail[0][0] + "***";
                if (maskedEmail.length > 1) {
                  let maskedUsername = maskedEmail[1].split(".");
                  maskedUsername[0] = maskedUsername[0][0] + "***";
                  maskedEmail =
                    maskedEmail[0] +
                    "@" +
                    maskedUsername[0] +
                    "." +
                    maskedUsername[1];
                }
                navigate(`/verify-email`, {
                  state: { maskedEmail, email, username },
                });
              }
            );
          }
        } catch (error) {
          if (error.error?.code === "UsernameExistsException") {
            setError("Sorry! \nUsername already exists");
          } else if (error.error.code === "InvalidParameterException") {
            setError("Please! \nEnter a valid username");
          } else {
            error?.error?.message?.split("error ")?.[1]
              ? setError(error?.error?.message?.split("error ")?.[1])
              : setError(error?.error?.message);
          }

          formik.setSubmitting(false);
        }
      }
      submit();
    },
  });

  const onSubmit = async (username, password, attributes) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.signUp({
          username: username,
          password: password,
          attributes,
        });
        /* Once the user successfully signs up, update form state to show the confirm sign up form for MFA */
        resolve({ payload: "SUCCESS" });
      } catch (error) {
        reject({ error: error });
      }
    });
  };
  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError("");
  };
  // useEffect(() => {
  //   formik.resetForm({
  //     values: {
  //       countries: countries.find(
  //         (country) => country.label === "United States"
  //       ),
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (Mode) {
      let mode = Mode;
      // convert to lowercase
      mode = mode.toLowerCase();
      // remove all spaces
      mode = mode.replace(/\s/g, "");
      // check if mode is sso
      if (mode === "sso") {
        setAuthMethod("sso");
      } else {
        setAuthMethod("regular");
      }
    }
  }, [Mode]);
  return (
    <Page title={product ? `Create Account | ${product}` : "Create Account"}>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
          // bgcolor: "background.paper",
        }}
      >
        <Container maxWidth="sm">
          <Snackbar
            open={String(error).trim() !== ""}
            autoHideDuration={6000}
            onClose={handleCloseToast}
            sx={{
              position: "relative",
              top: "16px",
              left: "0px !important",
              pb: 2,
            }}
            // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseToast}
              severity="error"
              sx={{ width: "100%" }}
              variant="filled"
            >
              {error}
            </Alert>
          </Snackbar>

          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography color="textPrimary" variant="h4">
                Create a new account
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Use your email to create a new account
              </Typography>
            </Box>
            {/* MUI Dropdown for selecting authentication method */}
            {!Mode && (
              <Box sx={{ mb: 1 }}>
                <Typography
                  fontWeight={700}
                  sx={{ mb: "8px", pb: 0, mt: "16px" }}
                  variant="body2"
                >
                  Sign-Up Method
                </Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="auth-method-label"
                    value={authMethod}
                    onChange={(e) => setAuthMethod(e.target.value)}
                    displayEmpty
                    fullWidth
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="regular">Legacy</MenuItem>
                    <MenuItem value="sso">SSO</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            <TextField
              error={Boolean(formik.touched.username && formik.errors.username)}
              fullWidth
              autoComplete="new-password"
              helperText={
                formik.touched.username && formik.errors.username ? (
                  formik.errors.username
                ) : (
                  <Stack
                    component="p"
                    direction="row"
                    sx={{ mt: 1, mb: 0 }}
                    spacing={1}
                  >
                    <Tooltip
                      title={`You can choose any name you like with at least 5 characters using
          numbers, letters, underscores "_" and dots "." This will be your
          display name so keep it professional and, business and family
          friendly.`}
                    >
                      <InfoOutlined size="small" color="info" />
                    </Tooltip>
                    <span>
                      This is the name that will appear in messages, mentions,
                      and forum posts. Your user name is unique to you, keep
                      your user name even if your email, organization, or
                      company changes.
                    </span>
                  </Stack>
                )
              }
              label="Username"
              margin="normal"
              name="username"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.username}
              type="text"
            />
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              label="Email Address"
              autoComplete="new-password"
              margin="normal"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
              variant="outlined"
            />
            <FormControl
              fullWidth
              margin="normal"
              name="password"
              variant="outlined"
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <OutlinedInput
                id="password"
                fullWidth
                label="Password"
                name="password"
                autoComplete="new-password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {Boolean(formik.touched.password && formik.errors.password) && (
                <FormHelperText error>{formik.errors.password}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              margin="normal"
              name="password"
              variant="outlined"
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              error={Boolean(
                formik.touched.confirmPassword && formik.errors.confirmPassword
              )}
            >
              <InputLabel htmlFor="confirmPassword">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="confirmPassword"
                fullWidth
                label="Confirm Password"
                margin="normal"
                autoComplete="new-password"
                name="confirmPassword"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                variant="outlined"
                type={showConfirmPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {Boolean(
                formik.touched.confirmPassword && formik.errors.confirmPassword
              ) && (
                <FormHelperText error>
                  {formik.errors.confirmPassword}
                </FormHelperText>
              )}
            </FormControl>
            <div>
              <Autocomplete
                id="country-select"
                sx={{ mt: 1 }}
                disabled={true}
                disablePortal
                ListboxProps={{ style: { maxHeight: "200px" } }}
                options={countries}
                autoHighlight
                // size="small"
                getOptionLabel={(option) => option.label}
                margin="normal"
                name="locale"
                limitTags={4}
                onBlur={formik.handleBlur}
                onChange={(value, newValue) => {
                  formik.setFieldValue("locale", newValue);
                }}
                fullWidth
                // value={formik.values.locale}
                defaultValue={{
                  code: "US",
                  label: "United States",
                  phone: "1",
                  suggested: true,
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select"
                    helperText={formik.touched.locale && formik.errors.locale}
                    label="Country / Region"
                    error={Boolean(
                      formik.touched.locale && formik.errors.locale
                    )}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "off", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </div>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                ml: -1,
              }}
            >
              <Checkbox
                name="termsOfUse"
                checked={formik.values.termsOfUse}
                onChange={formik.handleChange}
              />
              <Typography color="textSecondary" variant="body2">
                I have read and I accept the{" "}
                <Dialog
                  formik={formik}
                  trigger={
                    <>
                      FLUKEWORKS<sup>TM</sup> MASTER SERVICES AGREEMENT
                    </>
                  }
                  content={privacyPolicy}
                  title={
                    <>
                      FLUKEWORKS<sup>TM</sup> MASTER SERVICES AGREEMENT
                    </>
                  }
                  // subTitle={"We are currently using Cubyt terms of use"}
                />
              </Typography>
            </Box>
            {Boolean(formik.touched.termsOfUse && formik.errors.termsOfUse) && (
              <FormHelperText error>{formik.errors.termsOfUse}</FormHelperText>
            )}
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                ml: -1,
              }}
            >
              <Checkbox
                name="privacyPolicy"
                checked={formik.values.privacyPolicy}
                onChange={formik.handleChange}
              />
              <Typography color="textSecondary" variant="body2">
                I have read and I accept the{" "}
                <Link
                  href="https://www.fluke.com/en-us/fluke/privacy-policy"
                  target="_blank"
                  color="primary"
                  underline="always"
                  variant="subtitle2"
                >
                  PRIVACY POLICY
                </Link>
              </Typography>
            </Box>
            {Boolean(
              formik.touched.privacyPolicy && formik.errors.privacyPolicy
            ) && (
              <FormHelperText error>
                {formik.errors.privacyPolicy}
              </FormHelperText>
            )}
            <Box
              sx={
                {
                  // ml: 1,
                }
              }
            >
              <Typography color="textSecondary" variant="body2">
                I would like to receive news and updates by email
              </Typography>
              <RadioGroup row aria-label="receive-news" name="receive-news">
                <FormControlLabel
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value="yes"
                  control={<Radio />}
                  label="Yes (opt-in)"
                  name="receiveNews"
                  checked={formik.values.receiveNews === "yes" ? true : false}
                />
                <FormControlLabel
                  name="receiveNews"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value="no"
                  control={<Radio />}
                  label="No (opt-out)"
                />
              </RadioGroup>
            </Box>
            {Boolean(
              formik.touched.receiveNews && formik.errors.receiveNews
            ) && (
              <FormHelperText error>{formik.errors.receiveNews}</FormHelperText>
            )}
            <Box sx={{ pt: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign Up Now
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body2">
              Have an account?{" "}
              <span style={{ cursor: "pointer" }}>
                <Link
                  variant="subtitle2"
                  underline="hover"
                  onClick={() => navigate(`${signInPath}`)}
                >
                  Sign In
                </Link>
              </span>
            </Typography>
          </form>
        </Container>
      </Box>
      <NotificationComponent />
    </Page>
  );
};
