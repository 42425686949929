import styled from "@emotion/styled";
import { Breadcrumbs } from "@mui/material";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  // styles here
`;

StyledBreadcrumbs.propTypes = Breadcrumbs.propTypes;
StyledBreadcrumbs.displayName = "Breadcrumbs";

export { StyledBreadcrumbs };
