import React from "react";
import { StyledListSubheader } from "./styles";


/**
 * General ListSubheader component in JSDOC
 * @param {object} props Component props
 *
 */
const ListSubheader = React.forwardRef((props, ref) => {
  return (
    <StyledListSubheader {...props} ref={ref}/>
  );
});
ListSubheader.displayName = StyledListSubheader.displayName;
ListSubheader.propTypes = StyledListSubheader.propTypes;
export default ListSubheader;
