import styled from "@emotion/styled";
import { StepConnector } from "@mui/material";

const StyledStepConnector = styled(StepConnector)`
  // styles here
`;

StyledStepConnector.propTypes = StepConnector.propTypes;
StyledStepConnector.displayName = "StepConnector";

export { StyledStepConnector };
