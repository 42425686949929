import React from "react";
import { StyledButton } from "./styles";


/**
 * General Button component in JSDOC
 * @param {object} props Component props
 *
 */
const Button = React.forwardRef((props, ref) => {
  return (
    <StyledButton {...props} ref={ref}/>
  );
});
Button.displayName = StyledButton.displayName;
Button.propTypes = StyledButton.propTypes;
export default Button;
