import React from "react";

import { Component } from "react";

class ErrorBoundary extends Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(
      "The ErrorBoundary component caught an error.",
      error,
      errorInfo
    );
    if (this.props.onError && typeof this.props.onError === "function") {
      this.props.onError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return this.props.renderComponent || null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
