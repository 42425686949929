import React from "react";
import { StyledList } from "./styles";


/**
 * General List component in JSDOC
 * @param {object} props Component props
 *
 */
const List = React.forwardRef((props, ref) => {
  return (
    <StyledList {...props} ref={ref}/>
  );
});
List.displayName = StyledList.displayName;
List.propTypes = StyledList.propTypes;
export default List;
