import React from "react";
import { StyledTab } from "./styles";


/**
 * General Tab component in JSDOC
 * @param {object} props Component props
 *
 */
const Tab = React.forwardRef((props, ref) => {
  return (
    <StyledTab {...props} ref={ref}/>
  );
});
Tab.displayName = StyledTab.displayName;
Tab.propTypes = StyledTab.propTypes;
export default Tab;
