import styled from "@emotion/styled";
import { FilledInput } from "@mui/material";

const StyledFilledInput = styled(FilledInput)`
  // styles here
`;

StyledFilledInput.propTypes = FilledInput.propTypes;
StyledFilledInput.displayName = "FilledInput";

export { StyledFilledInput };
