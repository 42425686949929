import styled from "@emotion/styled";
import { ButtonGroup } from "@mui/material";

const StyledButtonGroup = styled(ButtonGroup)`
  // styles here
`;

StyledButtonGroup.propTypes = ButtonGroup.propTypes;
StyledButtonGroup.displayName = "ButtonGroup";

export { StyledButtonGroup };
