export const grays = {
  white: "#FFFFFF",
  50: "#F8F8F8",
  100: "#EBEBEB",
  150: "#DFDFDF",
  200: "#CCCCCC",
  300: "#B3B3B3",
  500: "#7F7F7F",
  800: "#333333",
  900: "#222222",
  black: "#000000",
};

export const primary = {
  100: "#F6FAFF",
  200: "#EDF6FF",
  300: "#E5F2FF",
  400: "#D4E9FF",
  500: "#BADBFF",
  600: "#A6CCFA",
  main: "#2B7CD3",
  800: "#0A59AD",
};

export const secondary = {
  100: "#FFEFBF",
  main: "#FFC20E",
  600: "#E6AD00",
  700: "#BF8F00",
  900: "#332602",
};

export const success = {
  100: "#D8F1E6",
  main: "#3AB983",
  600: "#22A16B",
};

export const error = {
  100: "#FEDEE0",
  main: "#FA5C62",
  600: "#CC4747",
};

export const warning = {
  100: "#FCEBDB",
  main: "#F0994C",
  600: "#D67D2F",
};
