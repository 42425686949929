import React from "react";
import { StyledToggleButton } from "./styles";


/**
 * General ToggleButton component in JSDOC
 * @param {object} props Component props
 *
 */
const ToggleButton = React.forwardRef((props, ref) => {
  return (
    <StyledToggleButton {...props} ref={ref}/>
  );
});
ToggleButton.displayName = StyledToggleButton.displayName;
ToggleButton.propTypes = StyledToggleButton.propTypes;
export default ToggleButton;
