import styled from "@emotion/styled";
import { Toolbar } from "@mui/material";

const StyledToolbar = styled(Toolbar)`
  // styles here
`;

StyledToolbar.propTypes = Toolbar.propTypes;
StyledToolbar.displayName = "Toolbar";

export { StyledToolbar };
