import React from "react";
import { StyledCheckbox } from "./styles";


/**
 * General Checkbox component in JSDOC
 * @param {object} props Component props
 *
 */
const Checkbox = React.forwardRef((props, ref) => {
  return (
    <StyledCheckbox {...props} ref={ref}/>
  );
});
Checkbox.displayName = StyledCheckbox.displayName;
Checkbox.propTypes = StyledCheckbox.propTypes;
export default Checkbox;
