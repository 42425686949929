import React from "react";
import { StyledTableHead } from "./styles";


/**
 * General TableHead component in JSDOC
 * @param {object} props Component props
 *
 */
const TableHead = React.forwardRef((props, ref) => {
  return (
    <StyledTableHead {...props} ref={ref}/>
  );
});
TableHead.displayName = StyledTableHead.displayName;
TableHead.propTypes = StyledTableHead.propTypes;
export default TableHead;
