import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Page,
  Snackbar,
  Alert,
} from "../../components";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

export const Verification = ({ Auth, product }) => {
  const [usernameProps, setUsernameProps] = useState(undefined);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const locationState = location?.state; // v6 separates access state from history.location (location)
    setUsernameProps(locationState?.username);
  }, [location]);
  const formik = useFormik({
    initialValues: {
      username: usernameProps,
      verificationCode: "",
    },
    validationSchema: Yup.object({
      verificationCode: Yup.string()
        .max(255)
        .trim()
        .required("verificationCode is required"),
    }),
    onSubmit: () => {
      async function submit() {
        let { verificationCode, username } = formik.values;
        try {
          await confirmSignUp(
            usernameProps ? usernameProps : username,
            String(verificationCode).trim()
          );
          alert(
            "Success!\nThanks for verifying your email, now you can use all the features of the app."
          );
          navigate("/");
        } catch (error) {
          // alert(error.error);
          setError(error.error.message);
          formik.setSubmitting(false);
        }
      }
      submit();
    },
  });

  /* Confirm sign up function for MFA */
  const confirmSignUp = async (username, verificationCode) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.confirmSignUp(username, verificationCode);
        /* Once the user successfully confirms their account, update form state to show the sign in form*/
        resolve({ payload: "SUCCESS" });
      } catch (error) {
        console.log("error confirming sign up", error);
        reject({ error: error });
      }
    });
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError("");
  };

  return (
    <Page title={product ? `Verification | ${product}` : "Verification"}>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="sm">
          <Snackbar
            open={String(error).trim() !== ""}
            autoHideDuration={6000}
            onClose={handleCloseToast}
            sx={{
              position: "relative",
              top: "16px",
              left: "0px !important",
              pb: 2,
            }}
            // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseToast}
              severity="error"
              sx={{ width: "100%" }}
              variant="filled"
            >
              {error}
            </Alert>
          </Snackbar>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ my: 3 }}>
              <Typography color="textPrimary" variant="h4">
                Please Verify Your Email
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                You should have received a verification code at the email
                address you provided. Please enter it here.
              </Typography>
            </Box>
            <TextField
              error={Boolean(formik.touched.username && formik.errors.username)}
              fullWidth
              helperText={formik.touched.username && formik.errors.username}
              label="Username"
              margin="normal"
              name="username"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={usernameProps ? usernameProps : formik.values.username}
              disabled={usernameProps ? true : false}
            />

            <TextField
              error={Boolean(
                formik.touched.verificationCode &&
                  formik.errors.verificationCode
              )}
              fullWidth
              helperText={
                formik.touched.verificationCode &&
                formik.errors.verificationCode
              }
              label="Verification Code 'e.g 123456'"
              margin="normal"
              name="verificationCode"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.verificationCode}
            />

            <Box sx={{ pt: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Container>
      </Box>

      {/* <Snackbar
        open={String(error).trim() !== ""}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar> */}
    </Page>
  );
};

Verification.propTypes = {
  Auth: PropTypes.any.isRequired,
  product: PropTypes.string,
};
