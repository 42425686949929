import styled from "@emotion/styled";
import { OutlinedInput } from "@mui/material";

const StyledOutlinedInput = styled(OutlinedInput)`
  // styles here
`;

StyledOutlinedInput.propTypes = OutlinedInput.propTypes;
StyledOutlinedInput.displayName = "OutlinedInput";

export { StyledOutlinedInput };
