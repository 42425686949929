import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const StyledGrid = styled(Grid)`
  // styles here
`;

StyledGrid.propTypes = Grid.propTypes;
StyledGrid.displayName = "Grid";

export { StyledGrid };
