import React from "react";
import { StyledTextareaAutosize } from "./styles";


/**
 * General TextareaAutosize component in JSDOC
 * @param {object} props Component props
 *
 */
const TextareaAutosize = React.forwardRef((props, ref) => {
  return (
    <StyledTextareaAutosize {...props} ref={ref}/>
  );
});
TextareaAutosize.displayName = StyledTextareaAutosize.displayName;
TextareaAutosize.propTypes = StyledTextareaAutosize.propTypes;
export default TextareaAutosize;
