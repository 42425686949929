import React from "react";
import { StyledRating } from "./styles";


/**
 * General Rating component in JSDOC
 * @param {object} props Component props
 *
 */
const Rating = React.forwardRef((props, ref) => {
  return (
    <StyledRating {...props} ref={ref}/>
  );
});
Rating.displayName = StyledRating.displayName;
Rating.propTypes = StyledRating.propTypes;
export default Rating;
