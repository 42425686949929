import React from "react";
import { StyledZoom } from "./styles";


/**
 * General Zoom component in JSDOC
 * @param {object} props Component props
 *
 */
const Zoom = React.forwardRef((props, ref) => {
  return (
    <StyledZoom {...props} ref={ref}/>
  );
});
Zoom.displayName = StyledZoom.displayName;
Zoom.propTypes = StyledZoom.propTypes;
export default Zoom;
