import React from "react";
import { StyledSpeedDialAction } from "./styles";


/**
 * General SpeedDialAction component in JSDOC
 * @param {object} props Component props
 *
 */
const SpeedDialAction = React.forwardRef((props, ref) => {
  return (
    <StyledSpeedDialAction {...props} ref={ref}/>
  );
});
SpeedDialAction.displayName = StyledSpeedDialAction.displayName;
SpeedDialAction.propTypes = StyledSpeedDialAction.propTypes;
export default SpeedDialAction;
