import styled from "@emotion/styled";
import { SnackbarContent } from "@mui/material";

const StyledSnackbarContent = styled(SnackbarContent)`
  // styles here
`;

StyledSnackbarContent.propTypes = SnackbarContent.propTypes;
StyledSnackbarContent.displayName = "SnackbarContent";

export { StyledSnackbarContent };
