import React from "react";
import { StyledMenu } from "./styles";


/**
 * General Menu component in JSDOC
 * @param {object} props Component props
 *
 */
const Menu = React.forwardRef((props, ref) => {
  return (
    <StyledMenu {...props} ref={ref}/>
  );
});
Menu.displayName = StyledMenu.displayName;
Menu.propTypes = StyledMenu.propTypes;
export default Menu;
