import React from "react";
import { StyledFormControlLabel } from "./styles";


/**
 * General FormControlLabel component in JSDOC
 * @param {object} props Component props
 *
 */
const FormControlLabel = React.forwardRef((props, ref) => {
  return (
    <StyledFormControlLabel {...props} ref={ref} />
  );
});
FormControlLabel.displayName = StyledFormControlLabel.displayName;
FormControlLabel.propTypes = StyledFormControlLabel.propTypes;
export default FormControlLabel;
