import styled from "@emotion/styled";
import { TableRow } from "@mui/material";

const StyledTableRow = styled(TableRow)`
  // styles here
`;

StyledTableRow.propTypes = TableRow.propTypes;
StyledTableRow.displayName = "TableRow";

export { StyledTableRow };
