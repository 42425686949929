import styled from "@emotion/styled";
import { MenuItem } from "@mui/material";

const StyledMenuItem = styled(MenuItem)`
  // styles here
`;

StyledMenuItem.propTypes = MenuItem.propTypes;
StyledMenuItem.displayName = "MenuItem";

export { StyledMenuItem };
