import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const StyledTypography = styled(Typography)`
  // styles here
`;

StyledTypography.propTypes = Typography.propTypes;
StyledTypography.displayName = "Typography";

export { StyledTypography };
