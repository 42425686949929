import styled from "@emotion/styled";
import { Hidden } from "@mui/material";

const StyledHidden = styled(Hidden)`
  // styles here
`;

StyledHidden.propTypes = Hidden.propTypes;
StyledHidden.displayName = "Hidden";

export { StyledHidden };
