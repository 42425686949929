import styled from "@emotion/styled";
import { TextareaAutosize } from "@mui/material";

const StyledTextareaAutosize = styled(TextareaAutosize)`
  // styles here
`;

StyledTextareaAutosize.propTypes = TextareaAutosize.propTypes;
StyledTextareaAutosize.displayName = "TextareaAutosize";

export { StyledTextareaAutosize };
