import styled from "@emotion/styled";
import { Input } from "@mui/material";

const StyledInput = styled(Input)`
  // styles here
`;

StyledInput.propTypes = Input.propTypes;
StyledInput.displayName = "Input";

export { StyledInput };
