import styled from "@emotion/styled";
import { ListItemButton } from "@mui/material";

const StyledListItemButton = styled(ListItemButton)`
  // styles here
`;

StyledListItemButton.propTypes = ListItemButton.propTypes;
StyledListItemButton.displayName = "ListItemButton";

export { StyledListItemButton };
