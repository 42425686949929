import styled from "@emotion/styled";
import { ToggleButtonGroup } from "@mui/material";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  // styles here
`;

StyledToggleButtonGroup.propTypes = ToggleButtonGroup.propTypes;
StyledToggleButtonGroup.displayName = "ToggleButtonGroup";

export { StyledToggleButtonGroup };
