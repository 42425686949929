import styled from "@emotion/styled";
import { Tabs } from "@mui/material";

const StyledTabs = styled(Tabs)`
  // styles here
`;

StyledTabs.propTypes = Tabs.propTypes;
StyledTabs.displayName = "Tabs";

export { StyledTabs };
