import styled from "@emotion/styled";
import { InputAdornment } from "@mui/material";

const StyledInputAdornment = styled(InputAdornment)`
  // styles here
`;

StyledInputAdornment.propTypes = InputAdornment.propTypes;
StyledInputAdornment.displayName = "InputAdornment";

export { StyledInputAdornment };
