import styled from "@emotion/styled";
import { MenuList } from "@mui/material";

const StyledMenuList = styled(MenuList)`
  // styles here
`;

StyledMenuList.propTypes = MenuList.propTypes;
StyledMenuList.displayName = "MenuList";

export { StyledMenuList };
