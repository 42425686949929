import styled from "@emotion/styled";
import { StepLabel } from "@mui/material";

const StyledStepLabel = styled(StepLabel)`
  // styles here
`;

StyledStepLabel.propTypes = StepLabel.propTypes;
StyledStepLabel.displayName = "StepLabel";

export { StyledStepLabel };
