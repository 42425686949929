import React from "react";
import { StyledSnackbar } from "./styles";


/**
 * General Snackbar component in JSDOC
 * @param {object} props Component props
 *
 */
const Snackbar = React.forwardRef((props, ref) => {
  return (
    <StyledSnackbar {...props} ref={ref}/>
  );
});
Snackbar.displayName = StyledSnackbar.displayName;
Snackbar.propTypes = StyledSnackbar.propTypes;
export default Snackbar;
