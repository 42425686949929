import React from "react";
import { StyledMobileStepper } from "./styles";


/**
 * General MobileStepper component in JSDOC
 * @param {object} props Component props
 *
 */
const MobileStepper = React.forwardRef((props, ref) => {
  return (
    <StyledMobileStepper {...props} ref={ref}/>
  );
});
MobileStepper.displayName = StyledMobileStepper.displayName;
MobileStepper.propTypes = StyledMobileStepper.propTypes;
export default MobileStepper;
