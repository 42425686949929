import React from "react";
import { StyledSpeedDial } from "./styles";


/**
 * General SpeedDial component in JSDOC
 * @param {object} props Component props
 *
 */
const SpeedDial = React.forwardRef((props, ref) => {
  return (
    <StyledSpeedDial {...props} ref={ref}/>
  );
});
SpeedDial.displayName = StyledSpeedDial.displayName;
SpeedDial.propTypes = StyledSpeedDial.propTypes;
export default SpeedDial;
