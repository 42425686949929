import React from "react";
import { DataGridPro, LicenseInfo, GridToolbar } from "@mui/x-data-grid-pro";
import { Container, Box } from "../../components";
import { rows, columns } from "./data";

LicenseInfo.setLicenseKey(
  "d895c9b125a530bf8bb21848cde30ee0T1JERVI6MzQ0MzQsRVhQSVJZPTE2NzEyMjEwNDkwMDAsS0VZVkVSU0lPTj0x"
);

export const StyledAssets = ({ isLoading = false }) => {
  return (
    <Box
      component="main"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
        minHeight: "100%",
        // bgcolor: "background.paper",
      }}
    >
      <Container
        maxWidth="md"
        component="main"
        sx={{ my: 2 }}
        // style={{ height: 520 }}
      >
        <DataGridPro
          // checkboxSelection
          rows={rows}
          columns={columns}
          pagination
          components={{ Toolbar: GridToolbar }}
          disableSelectionOnClick
          loading={isLoading}
          density="comfortable"
          autoHeight={true}
          sx={{
            border: 0,
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
          }}
          // pinnedColumns={{ left: ["__check__"] }}
          // isRowSelectable={false}`
        />
      </Container>
    </Box>
  );
};
