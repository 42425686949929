import React from "react";
import { StyledAutocomplete } from "./styles";


/**
 * General Autocomplete component in JSDOC
 * @param {object} props Component props
 *
 */
const Autocomplete = React.forwardRef((props, ref) => {
  return (
    <StyledAutocomplete {...props} ref={ref}/>
  );
});
Autocomplete.displayName = StyledAutocomplete.displayName;
Autocomplete.propTypes = StyledAutocomplete.propTypes;
export default Autocomplete;
