import React from "react";
import { StyledListItemSecondaryAction } from "./styles";


/**
 * General ListItemSecondaryAction component in JSDOC
 * @param {object} props Component props
 *
 */
const ListItemSecondaryAction = React.forwardRef((props, ref) => {
  return (
    <StyledListItemSecondaryAction {...props} ref={ref}/>
  );
});
ListItemSecondaryAction.displayName = StyledListItemSecondaryAction.displayName;
ListItemSecondaryAction.propTypes = StyledListItemSecondaryAction.propTypes;
export default ListItemSecondaryAction;
