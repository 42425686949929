import React from "react";
import { StyledCollapse } from "./styles";


/**
 * General Collapse component in JSDOC
 * @param {object} props Component props
 *
 */
const Collapse = React.forwardRef((props, ref) => {
  return (
    <StyledCollapse {...props} ref={ref}/>
  );
});
Collapse.displayName = StyledCollapse.displayName;
Collapse.propTypes = StyledCollapse.propTypes;
export default Collapse;
