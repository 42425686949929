import styled from "@emotion/styled";
import { TableSortLabel } from "@mui/material";

const StyledTableSortLabel = styled(TableSortLabel)`
  // styles here
`;

StyledTableSortLabel.propTypes = TableSortLabel.propTypes;
StyledTableSortLabel.displayName = "TableSortLabel";

export { StyledTableSortLabel };
