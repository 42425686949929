import {
  deleteResponseObject,
  deleteToken,
  retrieveResponse,
  retrieveToken,
  saveTokenAndObject,
  setSignOut,
} from "../utils";
/**
 * Sign in to the SSO service
 * @param {*} username
 * @param {*} password
 * @returns
 */
export async function signInWithUsername(username, password) {
  return new Promise((resolve, reject) => {
    try {
      const iframeWindow = document.getElementById("sso").contentWindow;

      iframeWindow.postMessage(
        {
          type: "SIGNIN_WITH_USERNAME",
          payload: { username, password },
        },
        process.env.REACT_APP_SSO_ORIGIN
      );
      // Event listener for receiving messages
      const handleReceiveMessage = (event) => {
        if (event.origin !== process.env.REACT_APP_SSO_DOMAIN) return; // Check the origin

        if (event.data.type === "SIGNIN_WITH_USERNAME_RESPONSE") {
          // Resolve the promise with the received token
          const res = event.data.payload;

          // async
          async function saveTokenAndObjectClosure(res) {
            await saveTokenAndObject(res);
            // resolve(event.data.payload);
            // Remove the event listener
            window.removeEventListener("message", handleReceiveMessage);
          }
          saveTokenAndObjectClosure(res);
          // window.removeEventListener("message", handleReceiveMessage);
        }
      };
      // Register the event listener
      window.addEventListener("message", handleReceiveMessage);
    } catch (error) {
      console.error("Error in signIn:", error);
      reject(error);
    }
  });
}

export async function signInWithEmail(email, password) {
  return new Promise((resolve, reject) => {
    try {
      const iframeWindow = document.getElementById("sso").contentWindow;

      iframeWindow.postMessage(
        {
          type: "SIGNIN_WITH_EMAIL",
          payload: { email, password },
        },
        process.env.REACT_APP_SSO_ORIGIN
      );
      // Event listener for receiving messages
      const handleReceiveMessage = (event) => {
        if (event.origin !== process.env.REACT_APP_SSO_DOMAIN) return; // Check the origin

        if (event.data.type === "SIGNIN_WITH_EMAIL_RESPONSE") {
          const res = event.data.payload;
          async function saveTokenAndObjectClosure(res) {
            await saveTokenAndObject(res);
            // resolve(event.data.payload);
            // Remove the event listener
            window.removeEventListener("message", handleReceiveMessage);
          }
          saveTokenAndObjectClosure(res);
          // window.removeEventListener("message", handleReceiveMessage);
        }
      };
      // Register the event listener
      window.addEventListener("message", handleReceiveMessage);
    } catch (error) {
      console.error("Error in signIn:", error);
      reject(error);
    }
  });
}

/**
 * Sign up to the SSO service
 * @param {*} pword
 * @param {*} emailAddr
 * @param {*} userName
 * @param {*} organizationId
 * @returns
 */
export async function signUp(
  pword,
  emailAddr,
  userName,
  organizationId,
  countryCode = "US"
) {
  return new Promise((resolve, reject) => {
    try {
      const iframeWindow = document.getElementById("sso").contentWindow;
      iframeWindow.postMessage(
        {
          type: "SIGNUP",
          payload: {
            pword,
            emailAddr,
            userName,
            organizationId,
            countryCode,
          },
        },
        "*"
      );

      const handleReceiveMessage = (event) => {
        if (event.origin !== process.env.REACT_APP_SSO_DOMAIN) return;

        if (event.data.type === "SIGNUP_RESPONSE") {
          resolve(event.data.payload);
          window.removeEventListener("message", handleReceiveMessage);
        }
      };

      window.addEventListener("message", handleReceiveMessage);
    } catch (error) {
      console.error("Error in signUp:", error);
      reject(error);
    }
  });
}
/**
 * Get the token from the SSO service
 * @returns token
 */
export async function getToken() {
  return new Promise((resolve, reject) => {
    try {
      const token = retrieveToken();
      resolve(token);
    } catch (error) {
      console.error("Error in getToken:", error);
      reject(error);
    }
  });
}

/**
 * Get the response object from the SSO service
 * @returns response object
 */
export async function getResponse() {
  return new Promise((resolve, reject) => {
    try {
      const response = retrieveResponse();
      resolve(response);
    } catch (error) {
      console.error("Error in getResponse:", error);
      reject(error);
    }
  });
}

/**
 * Sign out of the app
 * @returns
 */
export async function signOut() {
  return new Promise((resolve, reject) => {
    try {
      deleteToken();
      deleteResponseObject();
      setSignOut(true);
      resolve();
    } catch (error) {
      console.error("Error in signOut:", error);
      reject(error);
    }
  });
}

/**
 * Sign out of SSO Domain
 * @returns
 */
export async function signOutAll() {
  return new Promise((resolve, reject) => {
    try {
      deleteToken();
      deleteResponseObject();
      setSignOut();

      const srcElementHref = process.env.REACT_APP_SSO_DOMAIN + "/sso";
      const redirectUrlWithResponseObject = new URL(srcElementHref);
      redirectUrlWithResponseObject.searchParams.set("signOut", "true");
      const currentTopUrl = window.location.href;
      const redirectUrl = new URL(currentTopUrl);
      redirectUrlWithResponseObject.searchParams.set(
        "redirectUrl",
        redirectUrl.href
      );
      window.location.replace(redirectUrlWithResponseObject.href);
    } catch (error) {
      console.error("Error in signOut:", error);
      reject(error);
    }
  });
}

/**
 * Reset the password
 * @param {*} emailAddr
 * @returns
 */

export async function resetPassword(emailAddr) {
  return new Promise((resolve, reject) => {
    try {
      const iframeWindow = document.getElementById("sso").contentWindow;
      iframeWindow.postMessage(
        {
          type: "RESET_PASSWORD",
          payload: { emailAddr },
        },
        "*"
      );

      const handleReceiveMessage = (event) => {
        if (event.origin !== process.env.REACT_APP_SSO_DOMAIN) return;

        if (event.data.type === "RESET_PASSWORD_RESPONSE") {
          resolve(event.data.payload);
          window.removeEventListener("message", handleReceiveMessage);
        }
      };

      window.addEventListener("message", handleReceiveMessage);
    } catch (error) {
      console.error("Error in resetPassword:", error);
      reject(error);
    }
  });
}
