import React from "react";
import { StyledCard } from "./styles";


/**
 * General Card component in JSDOC
 * @param {object} props Component props
 *
 */
const Card = React.forwardRef((props, ref) => {
  return (
    <StyledCard {...props} ref={ref}/>
  );
});
Card.displayName = StyledCard.displayName;
Card.propTypes = StyledCard.propTypes;
export default Card;
