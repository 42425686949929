import styled from "@emotion/styled";
import { Accordion } from "@mui/material";

const StyledAccordion = styled(Accordion)`
  // styles here
`;

StyledAccordion.propTypes = Accordion.propTypes;
StyledAccordion.displayName = "Accordion";

export { StyledAccordion };
