import React from "react";
import { StyledHidden } from "./styles";


/**
 * General Hidden component in JSDOC
 * @param {object} props Component props
 *
 */
const Hidden = React.forwardRef((props, ref) => {
  return (
    <StyledHidden {...props} ref={ref}/>
  );
});
Hidden.displayName = StyledHidden.displayName;
Hidden.propTypes = StyledHidden.propTypes;
export default Hidden;
