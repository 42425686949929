import React from "react";
import { StyledFormHelperText } from "./styles";


/**
 * General FormHelperText component in JSDOC
 * @param {object} props Component props
 *
 */
const FormHelperText = React.forwardRef((props, ref) => {
  return (
    <StyledFormHelperText {...props} ref={ref}/>
  );
});
FormHelperText.displayName = StyledFormHelperText.displayName;
FormHelperText.propTypes = StyledFormHelperText.propTypes;
export default FormHelperText;
