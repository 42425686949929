import styled from "@emotion/styled";
import { Fade } from "@mui/material";

const StyledFade = styled(Fade)`
  // styles here
`;

StyledFade.propTypes = Fade.propTypes;
StyledFade.displayName = "Fade";

export { StyledFade };
