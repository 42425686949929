import React from "react";
import { StyledFormLabel } from "./styles";


/**
 * General FormLabel component in JSDOC
 * @param {object} props Component props
 *
 */
const FormLabel = React.forwardRef((props, ref) => {
  return (
    <StyledFormLabel {...props} ref={ref}/>
  );
});
FormLabel.displayName = StyledFormLabel.displayName;
FormLabel.propTypes = StyledFormLabel.propTypes;
export default FormLabel;
