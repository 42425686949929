import styled from "@emotion/styled";
import { Alert } from "@mui/material";

const StyledAlert = styled(Alert)`
  // styles here
`;

StyledAlert.propTypes = Alert.propTypes;
StyledAlert.displayName = "Alert";

export { StyledAlert };
