import styled from "@emotion/styled";
import { ToggleButton } from "@mui/material";

const StyledToggleButton = styled(ToggleButton)`
  // styles here
`;

StyledToggleButton.propTypes = ToggleButton.propTypes;
StyledToggleButton.displayName = "ToggleButton";

export { StyledToggleButton };
