import styled from "@emotion/styled";
import { InputLabel } from "@mui/material";

const StyledInputLabel = styled(InputLabel)`
  // styles here
`;

StyledInputLabel.propTypes = InputLabel.propTypes;
StyledInputLabel.displayName = "InputLabel";

export { StyledInputLabel };
