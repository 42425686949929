import styled from "@emotion/styled";
import { BottomNavigationAction } from "@mui/material";

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  // styles here
`;

StyledBottomNavigationAction.propTypes = BottomNavigationAction.propTypes;
StyledBottomNavigationAction.displayName = "BottomNavigationAction";

export { StyledBottomNavigationAction };
