import styled from "@emotion/styled";
import { Button } from "@mui/material";

const StyledButton = styled(Button)`
  // styles here
`;

StyledButton.propTypes = Button.propTypes;
StyledButton.displayName = "Button";

export { StyledButton };
