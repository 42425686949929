import styled from "@emotion/styled";
import { Chip } from "@mui/material";

const StyledChip = styled(Chip)`
  // styles here
`;

StyledChip.propTypes = Chip.propTypes;
StyledChip.displayName = "Chip";

export { StyledChip };
