import React from "react";
import { StyledTabScrollButton } from "./styles";


/**
 * General TabScrollButton component in JSDOC
 * @param {object} props Component props
 *
 */
const TabScrollButton = React.forwardRef((props, ref) => {
  return (
    <StyledTabScrollButton {...props} ref={ref}/>
  );
});
TabScrollButton.displayName = StyledTabScrollButton.displayName;
TabScrollButton.propTypes = StyledTabScrollButton.propTypes;
export default TabScrollButton;
