import styled from "@emotion/styled";
import { ScopedCssBaseline } from "@mui/material";

const StyledScopedCssBaseline = styled(ScopedCssBaseline)`
  // styles here
`;

StyledScopedCssBaseline.propTypes = ScopedCssBaseline.propTypes;
StyledScopedCssBaseline.displayName = "ScopedCssBaseline";

export { StyledScopedCssBaseline };
