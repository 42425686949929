import React from "react";
import { StyledAlertTitle } from "./styles";


/**
 * General AlertTitle component in JSDOC
 * @param {object} props Component props
 *
 */
const AlertTitle = React.forwardRef((props, ref) => {
  return (
    <StyledAlertTitle {...props} ref={ref}/>
  );
});
AlertTitle.displayName = StyledAlertTitle.displayName;
AlertTitle.propTypes = StyledAlertTitle.propTypes;
export default AlertTitle;
