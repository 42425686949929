import React from "react";
import { StyledListItemText } from "./styles";


/**
 * General ListItemText component in JSDOC
 * @param {object} props Component props
 *
 */
const ListItemText = React.forwardRef((props, ref) => {
  return (
    <StyledListItemText {...props} ref={ref}/>
  );
});
ListItemText.displayName = StyledListItemText.displayName;
ListItemText.propTypes = StyledListItemText.propTypes;
export default ListItemText;
