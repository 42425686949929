import styled from "@emotion/styled";
import { TableHead } from "@mui/material";

const StyledTableHead = styled(TableHead)`
  // styles here
`;

StyledTableHead.propTypes = TableHead.propTypes;
StyledTableHead.displayName = "TableHead";

export { StyledTableHead };
