import React from "react";
import { StyledDialogTitle } from "./styles";


/**
 * General DialogTitle component in JSDOC
 * @param {object} props Component props
 *
 */
const DialogTitle = React.forwardRef((props, ref) => {
  return (
    <StyledDialogTitle {...props} ref={ref}/>
  );
});
DialogTitle.displayName = StyledDialogTitle.displayName;
DialogTitle.propTypes = StyledDialogTitle.propTypes;
export default DialogTitle;
