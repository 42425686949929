import React from "react";
import { StyledFab } from "./styles";


/**
 * General Fab component in JSDOC
 * @param {object} props Component props
 *
 */
const Fab = React.forwardRef((props, ref) => {
  return (
    <StyledFab {...props} ref={ref}/>
  );
});
Fab.displayName = StyledFab.displayName;
Fab.propTypes = StyledFab.propTypes;
export default Fab;
