import React from "react";
import { StyledDialogContent } from "./styles";


/**
 * General DialogContent component in JSDOC
 * @param {object} props Component props
 *
 */
const DialogContent = React.forwardRef((props, ref) => {
  return (
    <StyledDialogContent {...props} ref={ref}/>
  );
});
DialogContent.displayName = StyledDialogContent.displayName;
DialogContent.propTypes = StyledDialogContent.propTypes;
export default DialogContent;
