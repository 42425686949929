import styled from "@emotion/styled";
import { Paper } from "@mui/material";

const StyledPaper = styled(Paper)`
  // styles here
`;

StyledPaper.propTypes = Paper.propTypes;
StyledPaper.displayName = "Paper";

export { StyledPaper };
