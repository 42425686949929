import styled from "@emotion/styled";
import { FormGroup } from "@mui/material";

const StyledFormGroup = styled(FormGroup)`
  // styles here
`;

StyledFormGroup.propTypes = FormGroup.propTypes;
StyledFormGroup.displayName = "FormGroup";

export { StyledFormGroup };
