import React from "react";
import { StyledStep } from "./styles";


/**
 * General Step component in JSDOC
 * @param {object} props Component props
 *
 */
const Step = React.forwardRef((props, ref) => {
  return (
    <StyledStep {...props} ref={ref}/>
  );
});
Step.displayName = StyledStep.displayName;
Step.propTypes = StyledStep.propTypes;
export default Step;
