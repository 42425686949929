import React from "react";
import { StyledImageListItem } from "./styles";


/**
 * General ImageListItem component in JSDOC
 * @param {object} props Component props
 *
 */
const ImageListItem = React.forwardRef((props, ref) => {
  return (
    <StyledImageListItem {...props} ref={ref}/>
  );
});
ImageListItem.displayName = StyledImageListItem.displayName;
ImageListItem.propTypes = StyledImageListItem.propTypes;
export default ImageListItem;
