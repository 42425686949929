import styled from "@emotion/styled";
import { AppBar } from "@mui/material";

const StyledAppBar = styled(AppBar)`
  // styles here
`;

StyledAppBar.propTypes = AppBar.propTypes;
StyledAppBar.displayName = "AppBar";

export { StyledAppBar };
