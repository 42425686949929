import styled from "@emotion/styled";
import { DialogActions } from "@mui/material";

const StyledDialogActions = styled(DialogActions)`
  // styles here
`;

StyledDialogActions.propTypes = DialogActions.propTypes;
StyledDialogActions.displayName = "DialogActions";

export { StyledDialogActions };
