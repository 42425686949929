import React from "react";
import { StyledFade } from "./styles";


/**
 * General Fade component in JSDOC
 * @param {object} props Component props
 *
 */
const Fade = React.forwardRef((props, ref) => {
  return (
    <StyledFade {...props} ref={ref}/>
  );
});
Fade.displayName = StyledFade.displayName;
Fade.propTypes = StyledFade.propTypes;
export default Fade;
