import styled from "@emotion/styled";
import { ListSubheader } from "@mui/material";

const StyledListSubheader = styled(ListSubheader)`
  // styles here
`;

StyledListSubheader.propTypes = ListSubheader.propTypes;
StyledListSubheader.displayName = "ListSubheader";

export { StyledListSubheader };
