import styled from "@emotion/styled";
import { CardContent } from "@mui/material";

const StyledCardContent = styled(CardContent)`
  // styles here
`;

StyledCardContent.propTypes = CardContent.propTypes;
StyledCardContent.displayName = "CardContent";

export { StyledCardContent };
