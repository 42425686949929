import styled from "@emotion/styled";
import { FormControl } from "@mui/material";

const StyledFormControl = styled(FormControl)`
  // styles here
`;

StyledFormControl.propTypes = FormControl.propTypes;
StyledFormControl.displayName = "FormControl";

export { StyledFormControl };
