import styled from "@emotion/styled";
import { Slider } from "@mui/material";

const StyledSlider = styled(Slider)`
  // styles here
`;

StyledSlider.propTypes = Slider.propTypes;
StyledSlider.displayName = "Slider";

export { StyledSlider };
