import styled from "@emotion/styled";
import { ButtonBase } from "@mui/material";

const StyledButtonBase = styled(ButtonBase)`
  // styles here
`;

StyledButtonBase.propTypes = ButtonBase.propTypes;
StyledButtonBase.displayName = "ButtonBase";

export { StyledButtonBase };
