import React from "react";
import { StyledSvgIcon } from "./styles";


/**
 * General SvgIcon component in JSDOC
 * @param {object} props Component props
 *
 */
const SvgIcon = React.forwardRef((props, ref) => {
  return (
    <StyledSvgIcon {...props} ref={ref}/>
  );
});
SvgIcon.displayName = StyledSvgIcon.displayName;
SvgIcon.propTypes = StyledSvgIcon.propTypes;
export default SvgIcon;
