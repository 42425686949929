import styled from "@emotion/styled";
import { Rating } from "@mui/material";

const StyledRating = styled(Rating)`
  // styles here
`;

StyledRating.propTypes = Rating.propTypes;
StyledRating.displayName = "Rating";

export { StyledRating };
