import React from "react";
import { StyledSpeedDialIcon } from "./styles";


/**
 * General SpeedDialIcon component in JSDOC
 * @param {object} props Component props
 *
 */
const SpeedDialIcon = React.forwardRef((props, ref) => {
  return (
    <StyledSpeedDialIcon {...props} ref={ref}/>
  );
});
SpeedDialIcon.displayName = StyledSpeedDialIcon.displayName;
SpeedDialIcon.propTypes = StyledSpeedDialIcon.propTypes;
export default SpeedDialIcon;
