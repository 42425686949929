import React from "react";
import { StyledStepContent } from "./styles";


/**
 * General StepContent component in JSDOC
 * @param {object} props Component props
 *
 */
const StepContent = React.forwardRef((props, ref) => {
  return (
    <StyledStepContent {...props} ref={ref}/>
  );
});
StepContent.displayName = StyledStepContent.displayName;
StepContent.propTypes = StyledStepContent.propTypes;
export default StepContent;
