import React from "react";
import { StyledPagination } from "./styles";


/**
 * General Pagination component in JSDOC
 * @param {object} props Component props
 *
 */
const Pagination = React.forwardRef((props, ref) => {
  return (
    <StyledPagination {...props} ref={ref}/>
  );
});
Pagination.displayName = StyledPagination.displayName;
Pagination.propTypes = StyledPagination.propTypes;
export default Pagination;
