import React from "react";
import { StyledStepLabel } from "./styles";


/**
 * General StepLabel component in JSDOC
 * @param {object} props Component props
 *
 */
const StepLabel = React.forwardRef((props, ref) => {
  return (
    <StyledStepLabel {...props} ref={ref}/>
  );
});
StepLabel.displayName = StyledStepLabel.displayName;
StepLabel.propTypes = StyledStepLabel.propTypes;
export default StepLabel;
