import styled from "@emotion/styled";
import { GlobalStyles } from "@mui/material";

const StyledGlobalStyles = styled(GlobalStyles)`
  // styles here
`;

StyledGlobalStyles.propTypes = GlobalStyles.propTypes;
StyledGlobalStyles.displayName = "GlobalStyles";

export { StyledGlobalStyles };
