import styled from "@emotion/styled";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)`
  // styles here
`;

StyledTextField.propTypes = TextField.propTypes;
StyledTextField.displayName = "TextField";

export { StyledTextField };
