import React from "react";
import { StyledAccordionActions } from "./styles";


/**
 * General AccordionActions component in JSDOC
 * @param {object} props Component props
 *
 */
const AccordionActions = React.forwardRef((props, ref) => {
  return (
    <StyledAccordionActions {...props} ref={ref}/>
  );
});
AccordionActions.displayName = StyledAccordionActions.displayName;
AccordionActions.propTypes = StyledAccordionActions.propTypes;
export default AccordionActions;
