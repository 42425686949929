import React from "react";
import { StyledTableSortLabel } from "./styles";


/**
 * General TableSortLabel component in JSDOC
 * @param {object} props Component props
 *
 */
const TableSortLabel = React.forwardRef((props, ref) => {
  return (
    <StyledTableSortLabel {...props} ref={ref}/>
  );
});
TableSortLabel.displayName = StyledTableSortLabel.displayName;
TableSortLabel.propTypes = StyledTableSortLabel.propTypes;
export default TableSortLabel;
