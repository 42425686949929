import React from "react";
import { StyledCardContent } from "./styles";


/**
 * General CardContent component in JSDOC
 * @param {object} props Component props
 *
 */
const CardContent = React.forwardRef((props, ref) => {
  return (
    <StyledCardContent {...props} ref={ref}/>
  );
});
CardContent.displayName = StyledCardContent.displayName;
CardContent.propTypes = StyledCardContent.propTypes;
export default CardContent;
