import styled from "@emotion/styled";
import { ListItemAvatar } from "@mui/material";

const StyledListItemAvatar = styled(ListItemAvatar)`
  // styles here
`;

StyledListItemAvatar.propTypes = ListItemAvatar.propTypes;
StyledListItemAvatar.displayName = "ListItemAvatar";

export { StyledListItemAvatar };
