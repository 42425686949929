import React from "react";
import { useNavigate } from "react-router-dom";
export const AuthRoute = ({ children, verifySignIn, Auth }) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    async function fetchData() {
      try {
        if (verifySignIn) {
          await verifySignIn(navigate, true, "/");
        } else {
          await Auth.currentAuthenticatedUser();
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [Auth, navigate, verifySignIn]);
  return <>{children}</>;
};
