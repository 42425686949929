import React from "react";
import { StyledAvatar } from "./styles";


/**
 * General Avatar component in JSDOC
 * @param {object} props Component props
 *
 */
const Avatar = React.forwardRef((props, ref) => {
  return (
    <StyledAvatar {...props} ref={ref}/>
  );
});
Avatar.displayName = StyledAvatar.displayName;
Avatar.propTypes = StyledAvatar.propTypes;
export default Avatar;
