import React from "react";
import { StyledStepButton } from "./styles";


/**
 * General StepButton component in JSDOC
 * @param {object} props Component props
 *
 */
const StepButton = React.forwardRef((props, ref) => {
  return (
    <StyledStepButton {...props} ref={ref}/>
  );
});
StepButton.displayName = StyledStepButton.displayName;
StepButton.propTypes = StyledStepButton.propTypes;
export default StepButton;
