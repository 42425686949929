import styled from "@emotion/styled";
import { Menu } from "@mui/material";

const StyledMenu = styled(Menu)`
  // styles here
`;

StyledMenu.propTypes = Menu.propTypes;
StyledMenu.displayName = "Menu";

export { StyledMenu };
