import styled from "@emotion/styled";
import { ListItemSecondaryAction } from "@mui/material";

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  // styles here
`;

StyledListItemSecondaryAction.propTypes = ListItemSecondaryAction.propTypes;
StyledListItemSecondaryAction.displayName = "ListItemSecondaryAction";

export { StyledListItemSecondaryAction };
