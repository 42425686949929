import React from "react";
import { StyledInputAdornment } from "./styles";


/**
 * General InputAdornment component in JSDOC
 * @param {object} props Component props
 *
 */
const InputAdornment = React.forwardRef((props, ref) => {
  return (
    <StyledInputAdornment {...props} ref={ref}/>
  );
});
InputAdornment.displayName = StyledInputAdornment.displayName;
InputAdornment.propTypes = StyledInputAdornment.propTypes;
export default InputAdornment;
