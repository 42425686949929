import styled from "@emotion/styled";
import { MobileStepper } from "@mui/material";

const StyledMobileStepper = styled(MobileStepper)`
  // styles here
`;

StyledMobileStepper.propTypes = MobileStepper.propTypes;
StyledMobileStepper.displayName = "MobileStepper";

export { StyledMobileStepper };
