import { createContext, useContext } from "react";

const SSOContext = createContext({
  isReady: false,
  signInWithUsername: async () => {},
  signInWithEmail: async () => {},
  signUp: async () => {},
  getToken: async () => {},
  token: null,
});

export const useSSO = () => {
  return useContext(SSOContext);
};

export default SSOContext;
