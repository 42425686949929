import React from "react";
import { StyledTablePagination } from "./styles";


/**
 * General TablePagination component in JSDOC
 * @param {object} props Component props
 *
 */
const TablePagination = React.forwardRef((props, ref) => {
  return (
    <StyledTablePagination {...props} ref={ref}/>
  );
});
TablePagination.displayName = StyledTablePagination.displayName;
TablePagination.propTypes = StyledTablePagination.propTypes;
export default TablePagination;
