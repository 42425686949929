import React from "react";
import { StyledGrid } from "./styles";

/**
 * General Grid component in JSDOC
 * @param {object} props Component props
 *
 */
const Grid = React.forwardRef((props, ref) => {
  return (
    <StyledGrid {...props} ref={ref} />
  );
});
Grid.displayName = StyledGrid.displayName;
Grid.propTypes = StyledGrid.propTypes;
export default Grid;
