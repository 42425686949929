import styled from "@emotion/styled";
import { ListItemIcon } from "@mui/material";

const StyledListItemIcon = styled(ListItemIcon)`
  // styles here
`;

StyledListItemIcon.propTypes = ListItemIcon.propTypes;
StyledListItemIcon.displayName = "ListItemIcon";

export { StyledListItemIcon };
