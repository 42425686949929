import React from "react";
import { StyledScopedCssBaseline } from "./styles";


/**
 * General ScopedCssBaseline component in JSDOC
 * @param {object} props Component props
 *
 */
const ScopedCssBaseline = React.forwardRef((props, ref) => {
  return (
    <StyledScopedCssBaseline {...props} ref={ref}/>
  );
});
ScopedCssBaseline.displayName = StyledScopedCssBaseline.displayName;
ScopedCssBaseline.propTypes = StyledScopedCssBaseline.propTypes;
export default ScopedCssBaseline;
