import React from "react";
import { Helmet } from "react-helmet";
import Box from "../Box";
const Page = React.forwardRef(({ children, title = "", ...props }, ref) => {
  return (
    <Box ref={ref} {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content={title} />
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.displayName = "Page";
export default Page;
