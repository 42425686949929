import React from "react";
import { StyledBackdrop } from "./styles";


/**
 * General Backdrop component in JSDOC
 * @param {object} props Component props
 *
 */
const Backdrop = React.forwardRef((props, ref) => {
  return (
    <StyledBackdrop {...props} ref={ref}/>
  );
});
Backdrop.displayName = StyledBackdrop.displayName;
Backdrop.propTypes = StyledBackdrop.propTypes;
export default Backdrop;
