import React from "react";
import { StyledCardMedia } from "./styles";


/**
 * General CardMedia component in JSDOC
 * @param {object} props Component props
 *
 */
const CardMedia = React.forwardRef((props, ref) => {
  return (
    <StyledCardMedia {...props} ref={ref}/>
  );
});
CardMedia.displayName = StyledCardMedia.displayName;
CardMedia.propTypes = StyledCardMedia.propTypes;
export default CardMedia;
