import React from "react";
import { Box, Typography } from "../../components";
export const termsOfUse = () => {
  return (
    <Box>
      <div>
        <p align="center">
          <strong>
            FLUKEWORKS<sup>TM</sup> USER TERMS OF SERVICE
          </strong>
        </p>
        <p>
          These User Terms of Service (the “<strong>User Terms</strong>”) govern
          your access and use of our online software workflow platform designed
          to (i) collect data, including from Fluke-branded devices, test tools,
          sensors, and Wi-Fi routers that are enabled to be compatible with the
          Services (“<strong>Fluke Tools</strong>”), about Asset Owner equipment
          or assets (“ <strong>Service Records</strong>”), (ii) analyze,
          measure, and otherwise process those Service Records, and (iii) enable
          owners of equipment or other assets (“<strong>Asset Owner</strong>”)
          or service providers providing services on such equipment or assets (“{" "}
          <strong>Service Providers</strong>”) to access and update those
          Service Records and manage their accounts ((i) through (iii),
          collectively, the “ <strong>Services</strong>”). Please read them
          carefully. Even though you are signing onto an existing workspace,
          these User Terms apply to you as a user of the Services.
        </p>
        <p>
          These User Terms are a legally binding contract between you and us. If
          you access or use the Services, or continue accessing or using the
          Services after being notified of a change to the User Terms, including
          the “Acceptable Use Policy”, you confirm that you have read,
          understand and agree to be bound by the User Terms and the Acceptable
          Use Policy. “We”, “our” and “us” currently refers to the applicable
          Fluke entity in the Contract (defined below).
        </p>
        <p>
          <strong>CUSTOMER’S ROLE</strong>
        </p>
        <p>
          You are an Authorized User participating in an organizational account
          (“Workspace”) controlled by a “Customer”.
        </p>
        <p>
          An organization or other third party that we refer to in these User
          Terms as “Customer” has invited you to a Workspace. If you are joining
          one of your employer’s Workspaces, for example, Customer is your
          employer.
        </p>
        <p>
          Customer has separately agreed to our FlukeWorks<sup>TM</sup> Master
          Services Agreement or entered into a written agreement with us (in
          either case, the “<strong>Contract</strong>”) that permitted Customer
          to create and configure a Workspace so that you and others could join
          (each invitee granted access to the Services, including you, is an “{" "}
          <strong>Authorized User</strong>”). The Contract contains our
          commitment to deliver the Services to Customer, who may then invite
          Authorized Users to join its Workspace(s). When an Authorized User
          (including, you) submits content or information to the Services, such
          as messages or files (“ <strong>Customer Data</strong>”), you
          acknowledge and agree that the Customer Data is owned by Customer and
          the Contract provides Customer with many choices and control over that
          Customer Data. For example, Customer may provision or deprovision
          access to the Services, enable or disable third party integrations,
          manage permissions, retention and export settings, transfer or assign
          workspaces, or consolidate your workspace with other workspaces, and
          these choices and instructions may result in the access, use,
          disclosure, modification or deletion of certain or all Customer Data.
        </p>
        <p>Our Relationship:</p>
        <p>
          AS BETWEEN US AND CUSTOMER, YOU AGREE THAT IT IS SOLELY CUSTOMER’S
          RESPONSIBILITY TO (A) INFORM YOU AND ANY AUTHORIZED USERS OF ANY
          RELEVANT CUSTOMER POLICIES AND PRACTICES AND ANY SETTINGS THAT MAY
          IMPACT THE PROCESSING OF CUSTOMER DATA; (B) OBTAIN ANY RIGHTS,
          PERMISSIONS OR CONSENTS FROM YOU AND ANY AUTHORIZED USERS THAT ARE
          NECESSARY FOR THE LAWFUL USE OF CUSTOMER DATA AND THE OPERATION OF THE
          SERVICES; (C) ENSURE THAT THE TRANSFER AND PROCESSING OF CUSTOMER DATA
          UNDER THE CONTRACT IS LAWFUL; AND (D) RESPOND TO AND RESOLVE ANY
          DISPUTE WITH YOU AND ANY AUTHORIZED USER RELATING TO OR BASED ON
          CUSTOMER DATA, THE SERVICES OR CUSTOMER’S FAILURE TO FULFILL THESE
          OBLIGATIONS. WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, TO YOU RELATING TO THE SERVICES, WHICH ARE
          PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        </p>
        <p>
          <strong>RULES AND ACCEPTABLE USE POLICY</strong>
        </p>
        <p>You Must be Over the Legal Age</p>
        <p>
          To the extent prohibited by applicable law, the Services are not
          intended for and should not be used by anyone under the age of
          sixteen. You represent that you are over the legal age and are the
          intended recipient of Customer’s invitation to the Services. You may
          not access or use the Services for any purpose if either of the
          representations in the preceding sentence is not true. Without
          limiting the foregoing, you must be of legal working age.
        </p>
        <p>
          To help ensure a safe and productive work environment, all Authorized
          Users must comply with the following Acceptable Use Policy and any
          applicable policies established by Customer.
        </p>
        <p>
          <u>ACCEPTABLE USE POLICY</u>: This Acceptable Use Policy sets out a
          list of acceptable and unacceptable conduct for our Services. If we
          believe a violation of the policy is deliberate, repeated or presents
          a credible risk of harm to other users, our customers, the Services or
          any third parties, we may suspend or terminate your access. This
          policy may change as we grow and evolve, so please check back
          regularly for updates and changes.
        </p>
        <p>
          <strong>Do:</strong>
        </p>
        <ul type="disc">
          <li>
            comply with all User Terms of Service, including the terms of this
            Acceptable Use Policy;
          </li>
          <li>
            comply with all applicable laws and governmental regulations,
            including, but not limited to, all intellectual property, data,
            privacy, and export control laws, and regulations promulgated by any
            government agencies, including, but not limited to, the U.S.
            Securities and Exchange Commission, and any rules of any national
            and other securities exchanges;
          </li>
          <li>
            upload and disseminate only Customer Data to which Customer owns all
            required rights under law and under contractual and fiduciary
            relationships (such as proprietary and confidential information
            learned or disclosed as part of employment relationships or under
            nondisclosure agreements) and do so only consistent with applicable
            law;
          </li>
          <li>
            use commercially reasonable efforts to prevent unauthorized access
            to or use of the Services;
          </li>
          <li>keep passwords and all other login information confidential;</li>
          <li>
            monitor and control all activity conducted through your account in
            connection with the Services;
          </li>
          <li>
            promptly notify us if you become aware of or reasonably suspect any
            illegal or unauthorized activity or a security breach involving your
            accounts or teams, including any loss, theft, or unauthorized
            disclosure or use of a username, password, or account; and
          </li>
          <li>
            comply in all respects with all applicable terms of the third party
            applications, including any that Customer elects to integrate with
            the Services that you access or subscribe to in connection with the
            Services.
          </li>
        </ul>
        <p>
          <strong>Do Not:</strong>
        </p>
        <ul type="disc">
          <li>
            permit any third party that is not an Authorized User to access or
            use a username or password for the Services;
          </li>
          <li>
            share, transfer or otherwise provide access to an account designated
            for you to another person;
          </li>
          <li>
            use the Services to store or transmit any Customer Data that may
            infringe upon or misappropriate someone else’s trademark, copyright,
            or other intellectual property, or that may be tortious or unlawful;
          </li>
          <li>
            upload to, or transmit from, the Services any data, file, software,
            or link that contains or redirects to a virus, Trojan horse, worm,
            or other harmful component or a technology that unlawfully accesses
            or downloads content or information stored within the Services or on
            the hardware of Fluke or any third party;
          </li>
          <li>
            attempt to reverse engineer, decompile, hack, disable, interfere
            with, disassemble, modify, copy, translate, or disrupt the features,
            functionality, integrity, or performance of the Services (including
            any mechanism used to restrict or control the functionality of the
            Services), any third party use of the Services, or any third party
            data contained therein (except to the extent such restrictions are
            prohibited by applicable law);
          </li>
          <li>
            attempt to gain unauthorized access to the Services or related
            systems or networks or to defeat, avoid, bypass, remove, deactivate,
            or otherwise circumvent any software protection or monitoring
            mechanisms of the Services;
          </li>
          <li>
            access the Services in order to build a similar or competitive
            product or service or copy any ideas, features, functions, or
            graphics of the Services;
          </li>
          <li>
            engage in activity that incites or encourages violence or hatred
            against individuals or groups;
          </li>
          <li>
            impersonate any person or entity, including, but not limited to, an
            employee of ours, an administrator, an owner, or any other
            Authorized User, or falsely state or otherwise misrepresent your
            affiliation with a person, organization or entity;
          </li>
          <li>
            sublicense, resell, time share or similarly exploit the Services;
          </li>
          <li>
            use the Services for consumer purposes, as it is intended for use by
            businesses and organizations;
          </li>
          <li>
            upload or disseminate any Customer Data that in our sole judgment is
            objectionable or may expose us or others to any harm or liability of
            any type; or
          </li>
          <li>
            authorize, permit, enable, induce, or encourage any third party to
            do any of the above.
          </li>
        </ul>
        <p>
          These User Terms remain effective until Customer’s subscription for
          you expires or terminates, or your access to the Services has been
          terminated by Customer or us.
        </p>
        <p>
          <strong>LIMITATION OF LIABILITY</strong>
        </p>
        <p>
          If we believe that there is a violation of the Contract, User Terms,
          the Acceptable Use Policy, or any of our other policies that can
          simply be remedied by Customer’s removal of certain Customer Data or
          taking other action, we will, in most cases, ask Customer to take
          action rather than intervene. We may directly step in and take what we
          determine to be appropriate action (including disabling your account)
          if Customer does not take appropriate action or we believe there is a
          credible risk of harm to us, the Services, Authorized Users, or any
          third parties. IN NO EVENT WILL YOU OR WE HAVE ANY LIABILITY TO THE
          OTHER FOR ANY LOST PROFITS OR REVENUES OR FOR ANY INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, COVER OR PUNITIVE DAMAGES HOWEVER CAUSED,
          WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY, AND
          WHETHER OR NOT THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. UNLESS YOU ARE ALSO A CUSTOMER (AND WITHOUT LIMITATION TO OUR
          RIGHTS AND REMEDIES UNDER THE CONTRACT), YOU WILL HAVE NO FINANCIAL
          LIABILITY TO US FOR A BREACH OF THESE USER TERMS. OUR MAXIMUM
          AGGREGATE LIABILITY TO YOU FOR ANY BREACH OF THE USER TERMS IS FIFTY
          DOLLARS USD ($50.00) IN THE AGGREGATE. THE FOREGOING DISCLAIMERS WILL
          NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW AND DO NOT LIMIT
          EITHER PARTY’S RIGHT TO SEEK AND OBTAIN EQUITABLE RELIEF.
        </p>
        <p>
          <strong>APPLICATION OF CONSUMER LAW</strong>
        </p>
        <p>
          The Services are a workplace tool intended for use by businesses and
          organizations and not for consumer purposes. To the maximum extent
          permitted by law, you hereby acknowledge and agree that consumer laws
          do not apply. If however any consumer laws do apply and cannot
          otherwise be lawfully excluded, nothing in these User Terms will
          restrict, exclude or modify any statutory warranties, guarantees,
          rights or remedies you have, and our liability is limited (at our
          option) to the replacement, repair or resupply of the Services or the
          pro-rata refund to Customer of pre-paid fees for your subscription
          covering the remainder of the term.
        </p>
        <p>
          <strong>SURVIVAL</strong>
        </p>
        <p>
          The sections titled “Our Relationship”, “Limitation of Liability”, and
          “Survival”, and all of the provisions under the general heading
          “General Provisions” will survive any termination or expiration of the
          User Terms.
        </p>
        <p>
          <strong>GENERAL PROVISIONS</strong>
        </p>
        <p>Email</p>
        <p>
          Except as otherwise set forth herein, all notices under the User Terms
          will be by email, although we may instead choose to provide notice to
          Authorized Users through the Services. Notices to Fluke should be sent
          to
          <a
            href="mailto:flukeworkssupport@flukeind.zendesk.com"
            target="_blank"
            title="mailto:flukeworkssupport@flukeind.zendesk.com"
          >
            flukeworkssupport@flukeind.zendesk.com
          </a>
          , except for legal notices, which must be sent to{" "}
          <a href="mailto:legal@fluke.com">legal@fluke.com</a>. A notice will be
          deemed to have been duly given (a) the day after it is sent, in the
          case of a notice sent through email; and (b) the same day, in the case
          of a notice sent through the Services. Notices under the Contract will
          be delivered solely to Customer in accordance with the terms of that
          agreement.
        </p>
        <p>Privacy Policy</p>
        <p>
          Please review our Privacy Policy (
          <a href="http://en-us.fluke.com/site/privacy/">
            http://en-us.fluke.com/site/privacy/
          </a>
          ) for more information on how we collect and use data relating to the
          use and performance of our products. This Privacy Policy does not
          apply to any third-party applications or software that integrate with
          the Services through the Platform (“
          <strong>Third-Party Services</strong>”), or any other third-party
          products, services or businesses who will provide their services under
          their own terms of service and privacy policy. In addition, a separate
          agreement governs delivery, access, and use of the Services (the “
          <strong>Customer Agreement</strong>”), including the processing of
          data such as messages, files or other content submitted through
          Services accounts (collectively, “<strong>Customer Data</strong>”).
          The organization (e.g., your employer or another entity or person)
          that entered into the Customer Agreement (“<strong>Customer</strong>”)
          controls its instance of the Services (its “<strong>Workspace</strong>
          ”) and any associated Customer Data. If you have any questions about
          specific Workspace settings and privacy practices, please contact the
          Customer whose Workspace you use. If you have received an invitation
          to join a Workspace but have not yet created an account, you should
          request assistance from the Customer that sent the invitation.
        </p>
        <p>Modifications</p>
        <p>
          As our business evolves, we may change these User Terms including the
          Acceptable Use Policy. If we make a material change to these User
          Terms, we will provide you with reasonable notice prior to the change
          taking effect either by emailing the email address associated with
          your account or by messaging you through the Services. You can review
          the most current version of the User Terms at any time by visiting
          <a>
            this page, and by visiting the following for the most current
            versions of the other pages that are referenced in these User Terms
            (LINKS
          </a>
          <a id="_anchor_1" href="#_msocom_1" name="_msoanchor_1">
            [LJ1]
          </a>
          ). Any material revisions to these User Terms will become effective on
          the date set forth in our notice, and all other changes will become
          effective on the date we publish the change. If you use the Services
          after the effective date of any changes, that use will constitute your
          acceptance of the revised terms and conditions.
        </p>
        <p>Waiver</p>
        <p>
          No failure or delay by either party in exercising any right under the
          User Terms, will constitute a waiver of that right. No waiver under
          the User Terms will be effective unless made in writing and signed by
          an authorized representative of the party being deemed to have granted
          the waiver.
        </p>
        <p>Severability</p>
        <p>
          The User Terms will be enforced to the fullest extent permitted under
          applicable law. If any provision of the User Terms is held by a court
          of competent jurisdiction to be contrary to law, the provision will be
          modified by the court and interpreted so as best to accomplish the
          objectives of the original provision to the fullest extent permitted
          by law, and the remaining provisions of the User Terms will remain in
          effect.
        </p>
        <p>Assignment</p>
        <p>
          You may not assign any of your rights or delegate your obligations
          under these User Terms whether by operation of law or otherwise,
          without the prior written consent of us (not to be unreasonably
          withheld). We may assign these User Terms in their entirety (including
          all terms and conditions incorporated herein by reference), without
          your consent, to a corporate affiliate or in connection with a merger,
          acquisition, corporate reorganization, or sale of all or substantially
          all of our assets.
        </p>
        <p>Governing Law; Venue; Fees</p>
        <p>
          The User Terms, and any disputes arising out of or related hereto,
          will be governed exclusively by the same applicable governing law of
          the Contract, without regard to conflicts of laws rules or the United
          Nations Convention on the International Sale of Goods. The courts
          located in the applicable venue of the Contract will have exclusive
          jurisdiction to adjudicate any dispute arising out of or relating to
          the User Terms, or its formation, interpretation or enforcement.
        </p>
        <p>
          Each party hereby consents and submits to the exclusive jurisdiction
          of such courts. In any action or proceeding to enforce rights under
          the User Terms, the prevailing party will be entitled to recover its
          reasonable costs and attorney’s fees.
        </p>
        <p>Entire Agreement</p>
        <p>
          The User Terms, including any terms incorporated by reference into the
          User Terms, constitute the entire agreement between you and us and
          supersede all prior and contemporaneous agreements, proposals or
          representations, written or oral, concerning its subject matter. To
          the extent of any conflict or inconsistency between the provisions in
          these User Terms and any pages referenced in these User Terms, the
          terms of these User Terms will first prevail; provided, however, that
          if there is a conflict or inconsistency between the Contract and the
          User Terms, the terms of the Contract will first prevail, followed by
          the provisions in these User Terms, and then followed by the pages
          referenced in these User Terms (e.g., the Privacy Policy). Customer
          will be responsible for notifying Authorized Users of those conflicts
          or inconsistencies and until such time the terms set forth herein will
          be binding.
        </p>
      </div>
    </Box>
  );
};
export const termsOfUse2 = () => {
  return (
    <Box>
      <div>
        <p align="center">
          <strong>
            FLUKEWORKS<sup>TM</sup> MASTER SERVICES AGREEMENT
          </strong>
        </p>
        <p>
          <a name="service" />
          1. <strong>Services</strong>.
        </p>
        <p>
          1.1 <u>General</u>.
        </p>
        <p>
          (a) <u>Workflow Platform</u>. Fluke offers both free and paid versions
          of the service you have subscribed to (“Services”). “Basic” accounts
          are provided to You without charge for limited features and usage
          parameters of the Platform and “Pro” accounts are provided to You with
          enhanced features and usage parameters. If You purchased a full
          subscription, then Fluke provides an online software platform designed
          to (i) collect data, including from Fluke Tools (as defined below),
          about Asset Owner equipment or assets ("
          <strong>Service Records</strong>"), (ii) analyze, measure, and
          otherwise process those Service Records, and (iii) enable owners of
          equipment or other assets ("<strong>Asset Owners</strong>") or service
          providers providing services on such equipment or assets ("{" "}
          <strong>Service Providers</strong>")<strong> </strong>to access and
          update those Service Records and manage their accounts ((i) through
          (iii), collectively, the "<strong>Platform</strong>").
        </p>
        <p>
          (b) <u>Professional Services</u>. Fluke may also provide onboarding or
          other related support services in connection with operation of the
          Dashboard (the "<strong>Professional Services</strong>").
        </p>
        <p>
          (c) <u>Services</u>. The Services are part of the Platform. Fluke may
          also provide additional software applications or other online services
          that interact with the Platform (the "<strong>Other Services</strong>
          ").
        </p>
        <p>
          (d) <u>Mobile Applications</u>. If You obtain a mobile application to
          the Services, You will be granted license to install, access and use
          the Service from Your mobile device during the Service term(s) in
          accordance with the provisions of this Agreement and, if applicable,
          the additional terms and conditions of the end user license agreement
          of the mobile application. Mobile applications run on smart phones,
          tablets, laptops and PCs, and are available on Android, Apple IOS and
          Windows 10. You may be required to update or upgrade the Service on
          Your mobile device from time to time in order to ensure full
          functionality of the mobile application. Fluke will provide You with
          any additional requirements for optimal functionality of the mobile
          application, if any. Fluke is not required to make its mobile
          applications work with any other mobile devices. In addition to Your
          obligations in sections 2 and 3 of this Agreement, You shall be
          responsible to protect Your data and the content stored on Your mobile
          devices. User identiﬁcation codes, passwords, and any information
          provided to You and the Customer Users and Guest Users as part of
          Fluke’s security procedures must be treated by You and the Customer
          Users and Guest Users as conﬁdential and must not be disclosed to any
          third-party. You are at all times responsible for the Customer Users’
          and Guest Users’ use of the mobile application. You are solely
          responsible for obtaining and paying for internet connectivity of Your
          or Your Guests’ mobile devices.
        </p>
        <p>
          1.2 <u>This Agreement</u>.
        </p>
        <p>
          (a) <u>This MSA</u>. This Master Services Agreement (this "{" "}
          <strong>MSA</strong>"), together with any Orders and SOWs (each as
          defined below), will govern the Services and Professional Services. If
          You access or use the Services or continue accessing or using the
          Services after being notified of a change to the MSA, You confirm that
          you have read, understand, and agree to be bound by the MSA. This MSA
          and all Orders and SOWs are collectively referred to as this "
          <strong>Agreement</strong>." Capitalized terms used but not defined in
          this MSA are defined in the Order or SOW, as applicable.
        </p>
        <p>
          (b) <u>Orders</u>. You may use the Services pursuant to one or more
          mutually executed order forms (each, an "<strong>Order</strong>").{" "}
          <strong> </strong>Each Order will outline, as applicable, (i) the
          Services to be provided, (ii) the period the Services will be
          accessible and provided (each, an "<strong>Order Term</strong>"),
          (iii) the number of Your employees or other personnel permitted by
          Fluke to use the Services (each, a "<strong>Customer User</strong>"),
          (iv) any access parameters with respect to the Services (including,
          without limitation, the Service Records) (the "
          <strong>Access Parameters</strong>"),<strong> </strong>(v) the fees
          for the Services per Order Term (the "<strong>Service </strong>{" "}
          <strong>Fees</strong>"), and (vi) any additional terms agreed to
          between You and Fluke applicable to the Services selected. Subject to
          the terms and conditions of this Agreement, upon Your and Fluke’s
          execution of an Order, Fluke will use commercially reasonable efforts
          to provide the Services described therein. “Fluke” means Fluke
          Electronics Corporation unless otherwise defined as a different Fluke
          Affiliate in an Order.
        </p>
        <p>
          (c) <u>SOWs</u>. You may procure the Professional Services pursuant to
          one or more mutually executed statements of work (each, an "{" "}
          <strong>SOW</strong>"). Each SOW will outline (i) the Professional
          Services to be provided, (ii) the period the Professional Services
          will be provided (each, a "<strong>Professional Services Term</strong>
          "), (iii) the fees for the Professional Services Fees (the "{" "}
          <strong>Professional Services Fees</strong>" and, collectively with
          the Service Fees, the "<strong>Fees</strong>"), and (iv) any
          additional terms agreed to between You and Fluke applicable to the
          Professional Services selected. Subject to the terms and conditions of
          this Agreement, upon Your and Fluke’s execution of an SOW, Fluke will
          use commercially reasonable efforts to provide the Professional
          Services described therein.
        </p>
        <p>
          (d) <u>Additional Terms; Order of Precedence</u>. Additional terms or
          conditions may apply to Your access of Other Services ("{" "}
          <strong>Additional Terms</strong>"). In that case, Fluke will include
          those Additional Terms in an Order, either directly or via an
          accessible link. Those Additional Terms will supplement, but not
          replace, the terms of this Agreement. Notwithstanding the foregoing,
          to the extent that a conflict arises between the terms and conditions
          of an Order (including any Additional Terms) or SOW, on the one hand,
          and the terms and conditions of this MSA, on the other, the conflict
          will be resolved in accordance with the following order of precedence
          (in decreasing order of precedence beginning at (i) and ending at
          (iii)) unless a different order of precedence is expressly stated on
          the Order or SOW: (i) the Additional Terms; followed by (ii) this MSA;
          followed by (iii) the Order (other than the Additional Terms) or the
          SOW.
        </p>
        <p>
          1.3 <u>Fluke Tools</u>. The Services are meant to be used only with
          Fluke-branded devices, test tools, sensors and Wi-Fi routers that are
          enabled to be compatible with the Services ("
          <strong>Fluke Tools</strong>
          "). The Services will collect Service Records from Fluke Tools. This
          Agreement does not alter in any way any terms or conditions of any
          agreement You may have with Fluke for use of the Fluke Tools (a "{" "}
          <strong>Fluke Tools Agreement</strong>"). In the event of a conflict
          between this Agreement and a Fluke Tools Agreement, this Agreement
          will control with respect to that conflict. Other than the Fluke
          Tools, You are solely responsible for providing and maintaining at
          Your own expense all tools, equipment, software, services, and other
          items necessary to use the Services (including, without limitation,
          any computer or device hardware or software, modems, telephone
          service, and Internet access) (" <strong>Customer Equipment</strong>
          ").
        </p>
        <p>
          1.4 <u>Customer Users and Guest Users</u>.
        </p>
        <p>
          (a) <u>Customer Users</u>. You are responsible for (i) identifying and
          authenticating all Customer Users, (ii) approving access to the
          Services by all Customer Users, (iii) ensuring Customer Users'
          compliance with this Agreement, (iv) controlling against unauthorized
          access or use by Customer Users, (v) maintaining the confidentiality
          of Your and Customer Users' usernames, passwords and account
          information, (vi) providing or obtaining any necessary notifications
          or consents from Customer Users as required by applicable law,
          including for any features of Fluke Tools, and (vii) all acts and
          omissions of all Customer Users and activities that occur under or in
          connection with Your and Customer Users' user names, passwords, or
          accounts (except as a result of Fluke's breach of this Agreement). Any
          act or omission of any Customer User will, for purposes of this
          Agreement, be deemed an act or omission of You. Fluke is not
          responsible for any harm caused by Customer Users, including, without
          limitation, individuals who were not authorized to have access to the
          Services but who were able to gain access because usernames, passwords
          or accounts were not terminated on a timely basis in Your local
          identity management infrastructure, Your local computers, or otherwise
          by You or on Your behalf. You will notify Fluke immediately in writing
          of any unauthorized use of the Services and will make every reasonable
          effort to prevent unauthorized third parties from accessing the
          Services. Customer Users must agree to the User Terms of Service
          before they may access or use the Services.
        </p>
        <p>
          (b) <u>Guest Users</u>. In certain instances, You may invite guest
          users (i.e., users other than Customer Users) to access and use the
          Services (" <strong>Guest Users</strong>"). For example, if You are an
          Asset Owner, Guest Users may include Service Provider users. If You
          are a Service Provider, Guest Users may include Asset Owner users.
          Before accessing or using the Services, each Guest User will be
          required to enter into the User Terms of Service before they may
          access or use the Services (" <strong>Guest User Terms</strong>"). You
          will, and are solely responsible for, informing (i) Guest Users and
          (ii) either (A) the Asset Owner (if You are the Service Provider) or
          (B) Service Provider (if You are the Asset Owner) and (iii) any other
          company associated with the permitted Guest User (such Asset Owner,
          Service Provider, or company may be referred to herein as a “
          <strong>Guest</strong>”), of Your policies and practices that are
          relevant to their access of Service Records, including that their
          access to Service Records may at any time be terminated. You are
          responsible and liable for Guest Users to the same extent as you are
          responsible and liable for Customer Users as set forth in this
          Agreement, including Subsection 1.4(a).
        </p>
        <p>
          1.5 <u>Data Privacy and Security</u>. Processing of Personal Data,
          where Fluke processes such Personal Data on Your behalf, and security
          will be governed by the Data Processing Addendum, which is attached
          hereto and made a part of this Agreement.
        </p>
        <p>
          <a name="_3znysh7" />
          <a name="_2et92p0" />
          2. <strong>Records and Content</strong>.
        </p>
        <p>
          2.1 <u>Service Records</u>. You grant Fluke and its Affiliates the
          right to (a) collect, analyze, modify, measure, and otherwise process
          the Service Records and make them available to Customer Users and to
          (b) share the Service Records with, and display the Service Records
          to, (i) Guest Users via the Platform; as long as those Guest Users
          have access to the Platform pursuant to Section 1.4(b) above or (ii) a
          Guest, where such right is granted by You and verified through the
          Platform, as a Fluke customer under their own account, and (iii)
          vendors, consultants, and other service providers who need access to
          the Service Records to carry out work on Fluke's behalf, in each of
          (a) and (b) as necessary or useful for Fluke to develop, provide,
          maintain, and improve the Services and Fluke's other products and
          services.
        </p>
        <p>
          2.2 <u>Usage Data</u>. Fluke and its Affiliates may access and use the
          Usage Data for the following purposes:
        </p>
        <p>
          (a) to provide the Services (including technical support and
          maintenance) to You as defined in an agreed Order Form; and
        </p>
        <p>
          (b) to use the Usage Data alone or in combination with or in other
          data, products, systems or services and create derivative works and
          otherwise benefit from the Usage Data for the purposes of developing
          and improving Fluke’s and its affiliates’ products and services and
          creating statistical and/or benchmarking data for internal and
          external use (“Usage Data Activities”).
        </p>
        <p>
          Fluke and its Affiliates may carry out the Usage Data Activities
          through their authorized contractors, consultants, or other service
          providers, that may be located in different geographical areas, and
          share the Usage Data with them to the extent necessary for the above
          purposes. You acknowledge and agree that Fluke and its Affiliates own
          and reserve all rights, title and interest (including any Intellectual
          Property Rights) in and to aggregated and de-identified Usage Data,
          the Usage Data Activities and any data, results, reports, analysis,
          solution, process, algorithm, material or other information arising
          out of or in connection with the Usage Data Activities. Fluke will
          take reasonable measures to protect any Usage Data with at least the
          same degree of care that Fluke uses to protect its own data of similar
          importance but in no case less than a reasonable degree of care. Fluke
          and its Affiliates will disclose the Usage Data for the purposes of
          the Usage Data Activities in a de-identified or pseudonymized form. In
          the event of any inconsistencies between this Section and the
          confidentiality provisions of this Agreement or any other
          non-disclosure agreement between the Parties, this Section shall
          prevail.
        </p>
        <p>
          “Usage Data” means (i) data relating to the Service Records that is
          either collected or accessed by the Fluke Tools or otherwise obtained
          or accessed by Fluke and its Affiliates; (ii) asset tag information,
          text files, sound files, pictures, videos, work orders and routings
          related to the equipment and/or assets that You may upload to or share
          with Fluke and its Affiliates, (iii) data around the trends, usage and
          activities of the Customer and Guest Users in connection with the
          access and use of the Services. Usage Data does not include Personal
          Data. Any legacy data provided by Customer and Guest Users to Fluke
          for the purposes of the Agreement, that fall under the definition of
          Usage Data, shall also be deemed as Usage Data unless otherwise
          agreed. “Affiliate” means any legal entity that directly or indirectly
          controls, is controlled by, or is under common control with, You or
          Fluke to this Agreement, where “control” means ownership of at least
          fifty (50) percent of the equity having the power to vote on or direct
          the affairs of the entity.
        </p>
        <p>
          2.3 <u>User Content</u>.
        </p>
        <p>
          (a) <u>Definition and Restrictions</u>. Certain features of the
          Services may be designated as "community" features permitting You or
          Customer Users to upload or transmit text, sound, photos, videos,
          graphics, code, templates, scripts, items or other materials, other
          than Service Records (" <strong>User Content</strong>"). You agree
          that User Content may be viewable by others. You are solely
          responsible for Your User Content and will not, and will not permit
          any Customer Users or Guest Users to, create, upload, transmit, or
          store any User Content that:
        </p>
        <p>
          (i) is unlawful, libelous, defamatory, obscene, pornographic,
          indecent, lewd, suggestive, harassing, threatening, invasive of
          privacy or publicity rights, abusive, inflammatory or fraudulent;
        </p>
        <p>
          (ii) would constitute, encourage or provide instructions for a
          criminal offense, violate the rights of any party or otherwise create
          liability or violate any local, state, national or international law;
        </p>
        <p>(iii) may infringe any Intellectual Property Rights of any party;</p>
        <p>
          (iv) impersonates, or misrepresents Your affiliation with, any person
          or entity;
        </p>
        <p>
          (v) contains any private or personal information of a third party
          without that third party's consent;
        </p>
        <p>
          (vi) contains any viruses, corrupted data or other harmful, disruptive
          or destructive files or content; or
        </p>
        <p>
          (vii) in Fluke's sole judgment, is objectionable or may expose Fluke
          or others to any harm or liability of any type.
        </p>
        <p>
          (b) <u>Rights in User Content</u>. You retain all ownership rights in
          the User Content submitted to Fluke through the Services. By uploading
          or transmitting User Content through the Services, You grant Fluke and
          its Affiliates, to the fullest extent permitted by applicable law, a
          nonexclusive, royalty-free, perpetual, irrevocable and fully
          transferable and sublicensable right to use, reproduce, modify, adapt,
          translate, and otherwise exploit the User Content, in whole or in
          part, throughout the world to provide, maintain and improve the
          Services and Fluke's other products and services. By uploading or
          transmitting User Content to the Services, You represent and warrant
          that (i) User Content is not subject to any third-party
          confidentiality obligations that would be breached by the access, use,
          storage and sharing contemplated by this Agreement, (ii) You own and
          control all of the rights to that User Content or You otherwise has
          all necessary rights and consents to create, upload or transmit that
          User Content to the Services, (iii) User Content is accurate and not
          misleading or harmful in any manner, and (iv) User Content, and Your
          use, uploading and transmitting thereof, does not and will not violate
          this Agreement or any applicable laws, rules, standards, and
          regulations.
        </p>
        <p>
          (c) <u>DMCA</u>. In accordance with the Digital Millennium Copyright
          Act and other applicable law, Fluke has adopted a policy of
          terminating, in appropriate circumstances, the accounts of users who
          repeatedly infringe the Intellectual Property Rights of others. If You
          believe that anything on the Services infringes any copyright that You
          own or control, You may follow the process set forth at
          <a href="https://www.fluke.com/en-us/fluke/fluke-terms-of-use">
            https://www.fluke.com/en-us/fluke/fluke-terms-of-use
          </a>
          or any successor link.
        </p>
        <p>
          3. <strong>Fees and Payment Terms</strong>.
        </p>
        <p>
          3.1 <u>Fees</u>. You will pay to Fluke (a) the Service Fees in
          accordance with the payment terms set forth in the applicable Order
          and (b) the Professional Services Fees in accordance with the payment
          terms set forth in the applicable SOW. The Fees do not include taxes.
          Unless otherwise set forth in the Order or SOW, as applicable, Fluke
          will invoice You monthly in advance for all Fees and You will pay all
          invoiced amounts to Fluke within 15 days of the date of the invoice.
          All payments made under this Agreement, the Order, and the applicable
          SOW will be made (i) in U.S. Dollars and (ii) via the proposed payment
          method defined by Fluke. If applicable, You are responsible for paying
          for all Customer User and Guest User licenses ordered for the entire
          initial or renewal Term, whether or not such Customer User or Guest
          User licenses are actively used. You may add licenses subject to the
          following: (i) added licenses will be coterminous with the
          pre-existing Order Term (either initial or renewal Term); (ii) the
          Services Fee for the added licenses will be the then current
          applicable license fee; and (iii) licenses added in the middle of an
          initial or renewal Term will be charged in full for that Term. All
          pricing terms are confidential, and You agrees not to disclose them to
          any third party.
        </p>
        <p>
          3.2 <u>Interest and Taxes</u>. Interest on any late payments will
          accrue at the rate of 1.5% per month or the highest rate permitted by
          law, whichever is lower, from the date the amount is due until the
          date the amount is paid in full. You will be responsible for and will
          pay all sales and similar taxes levied upon the provision of the
          Services, excluding only taxes based solely on Fluke's net income.
          Notwithstanding any terms to the contrary in this Agreement, (a) all
          payment obligations are non-cancelable and Fluke will not be obligated
          to issue any refunds for Fees paid, and (b) Fluke, at its sole
          discretion, may modify the Service Fees (including any minimum
          Customer User licenses) during any Order Term on thirty (30) days’
          notice, provided that modifications will only be effective as of the
          directly subsequent Order Term.
        </p>
        <p>
          4. <strong>Confidentiality</strong>.
          <a name="_Hlk75780102">
            "<strong>Confidential Information</strong>" means all information
            disclosed (whether in oral, written, or other tangible or intangible
            form) by You or Fluke (the "<strong>Disclosing Party</strong>") to
            the other (the "<strong>Receiving Party</strong>") concerning or
            related to this Agreement or the Disclosing Party (whether before,
            on or after the Effective Date) that the Receiving Party knows or
            reasonably should know, given the facts and circumstances
            surrounding the disclosure of the information by the Disclosing
            Party, is confidential information of the Disclosing Party. The
            Receiving Party will maintain in confidence the Confidential
            Information and will not use such Confidential Information except as
            expressly permitted in this Agreement. The Receiving Party will use
            the same degree of care in protecting the Confidential Information
            as the Receiving Party uses to protect its own confidential and
            proprietary information from unauthorized use or disclosure, but in
            no event less than reasonable care. Confidential Information will be
            used by the Receiving Party solely for the purpose of carrying out
            the Receiving Party's obligations or exercising the Receiving
            Party's rights under this Agreement. You and Fluke each agrees that
            the terms and conditions of this Agreement will not be disclosed to
            any third party; provided, however, that You and Fluke may each
            disclose the terms and conditions of this Agreement: (a) to that
            party's equity holders, affiliates, consultants, legal counsel and
            financing sources; (b) in connection with the enforcement of this
            Agreement or rights under this Agreement; or (c) in connection with
            an actual or proposed equity investment, financing, change of
            control, merger, acquisition of all or a portion of the business or
            assets of that party, or any similar transaction. The foregoing
            confidentiality obligations will not apply to the extent that a
            party is required, in the reasonable opinion of that party's legal
            counsel, to disclose information by applicable law, regulation, rule
            (including rule of a stock exchange, listing agency or automated
            quotation system), order of a governmental agency or a court of
            competent jurisdiction or legal process, including tax authorities
          </a>
          .
        </p>
        <p>
          <a name="_3dy6vkm" />
          5. <strong>Representations and Warranties</strong>. You and Fluke each
          represents and warrants that (a) it is validly existing and in good
          standing under the laws of the place of its establishment or
          incorporation, (b) it has full corporate power and authority to
          execute, deliver and perform its obligations under this Agreement,
          including but not limited to the fullest extent as necessary to grant
          Fluke the rights in and to the Usage Data and Usage Data Activities,
          (c) the person signing or otherwise accepting this Agreement on its
          behalf has been duly authorized and empowered to enter into this
          Agreement, and (d) this Agreement is valid, binding and enforceable
          against it in accordance with its terms. Further, You represent,
          warrant and covenant that You will use the Services in accordance with
          all applicable laws, rules, standards, and regulations.
        </p>
        <p>
          <a name="_1t3h5sf" />
          6. <strong>Disclaimer and Limitation of Liability</strong>.{" "}
          <strong> </strong>
        </p>
        <p>
          6.1 <u>Disclaimer</u>. THE SERVICES ARE PROVIDED ON AN "AS IS" AND THE
          PROFESSIONAL SERVICES ARE PROVIDED ON AN "AS AVAILABLE" BASIS. FLUKE
          EXPRESSLY DISCLAIMS, ALL WARRANTIES WITH RESPECT TO THE SERVICES OR
          PROFESSIONAL SERVICES, WHETHER EXPRESS, IMPLIED OR STATUTORY,
          INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY,
          NONINFRINGEMENT, ACCURACY, OR FITNESS FOR A PARTICULAR PURPOSE.
          WITHOUT LIMITING THE FOREGOING, YOU ASSUMES SOLE RESPONSIBILITY AND
          LIABILITY FOR ACTIONS TAKEN OR DECISIONS MADE (OR NOT TAKEN OR MADE)
          BY YOU, ANY CUSTOMER USERS, ANY GUEST USERS, OR ANY ENTITY OR PERSON
          USING YOUR PRODUCTS OR SERVICES AS A RESULT OF YOUR USE OF THE
          SERVICES OR ANY RESULTS OR OUTPUT THEREOF OR CONCLUSIONS DRAWN
          THEREFROM.
        </p>
        <p>
          6.2 <u>Limitation of Liability</u>. FLUKE'S SOLE AND AGGREGATE
          LIABILITY ON ANY AND ALL CLAIMS, LOSSES, OR LIABILITIES ARISING OUT OF
          THIS AGREEMENT, INCLUDING BUT NOT LIMITED TO THE SERVICES AND
          PROFESSIONAL SERVICES PROVIDED PURSUANT TO THIS AGREEMENT, WILL BE
          LIMITED TO DIRECT DAMAGES AND WILL IN NO EVENT EXCEED THE FEES
          ACTUALLY PAID TO FLUKE IN THE PRECEDING 12 MONTH PERIOD UNDER THE
          ORDER OR SOW GIVING RISE TO THE LIABILITY AND ASSOCIATED WITH THE
          SERVICES OR PROFESSIONAL SERVICES PROVIDED. IN NO EVENT WILL FLUKE BE
          LIABLE FOR ANY LOSS OF PROFITS, LOSS OF USE, LOSS OF DATA, LOSS OF
          REVENUE, LOSS OF GOODWILL, ANY INTERRUPTION OF BUSINESS, OR FOR ANY
          INDIRECT, SPECIAL, CONSEQUENTIAL, INCIDENTAL, EXEMPLARY OR PUNITIVE
          DAMAGES.
        </p>
        <p>
          <a name="_4d34og8" />
          7. <strong>Indemnification</strong>.
        </p>
        <p>
          <a name="_2s8eyo1" />
          7.1 <u>Indemnification by Fluke</u>. Fluke, at its sole expense, will
          defend You and Your directors, officers, employees and agents ("{" "}
          <strong>Your Indemnitees</strong>") from and against any and all
          third-party claims, suits, actions or proceedings (each a "{" "}
          <strong>Claim</strong>") against any of Your Indemnitees asserting
          that the Services infringe any Intellectual Property Rights of any
          third party, and will indemnify Your Indemnitees from any resulting
          damages, payments, deficiencies, fines, judgments, settlements,
          liabilities, losses, costs and expenses (including, but not limited
          to, reasonable attorneys’ fees, costs, penalties, interest and
          disbursements) ("<strong>Losses</strong>"). Fluke's defense
          obligations do not extend to Claims, nor do its indemnification
          obligations extend to Losses, to the extent resulting from, arising in
          connection with, or relating to (a) any combination of the Services
          (or any portion thereof) with any of Your business process or any
          equipment, software, data or any other materials not provided by
          Fluke, (b) any modification to the Services by any party other than
          Fluke or its authorized personnel, (c) the use of the Services by You,
          any Customer Users, any Guest Users, or anyone else acting under Your,
          Customer User, or Guest User account or otherwise on Your or any
          Customer User's or Guest User's behalf or at Your or any Customer
          User's or Guest User's authorization in a manner contrary to the terms
          of this Agreement, (d) the continued use of the Services after Fluke
          has provided substantially equivalent non-infringing software or
          services, or (e) any Customer Equipment or other products or services
          of Yours.
        </p>
        <p>
          7.2 <u>Indemnification by You</u>. You, at Your sole expense, will
          defend Fluke and its directors, officers, employees and agents ("{" "}
          <strong>Fluke Indemnitees</strong>") from and against any Claims and
          indemnify the Fluke Indemnitees from any related Losses arising in
          connection with or relating to (a) any Customer Equipment or other
          products or services of Yours, (b) any negligence or willful
          misconduct by You, or any acts or omissions (including, without
          limitation, negligence or willful misconduct) of any Customer User or
          Guest User, (c) (i) any alleged or actual breach of Your obligations
          under this Agreement or (ii) any alleged or actual breach of: (A) a
          Customer User’s obligations under the User Terms or (B) a Guest User's
          obligations under the Guest User Terms, or (d) Your use of the
          Services (including, without limitation, any actions taken or
          decisions made (or not taken or not made) by You based on the results
          or output of the Services or conclusions drawn therefrom).
        </p>
        <p>
          7.3 <u>Infringement Claims</u>. In the event of a Claim pursuant to
          Section 7.1, or if Fluke believes that that Claim may be brought,
          Fluke may, at Fluke's option and at Fluke's expense, (a) replace the
          applicable Services with a non-infringing equivalent, (b) modify the
          applicable Services so they are non-infringing, (c) procure for You
          the right to continue using the Services, or (d) terminate this
          Agreement upon 30 days written notice to You. SECTION 7.1 AND THIS
          SECTION 7.3 SET FORTH YOUR SOLE REMEDY AND FLUKE'S SOLE OBLIGATION FOR
          THE CLAIMS DESCRIBED IN SECTION 7.1.
        </p>
        <p>
          7.4 <u>Procedures</u>. The indemnifying party's indemnification
          obligations under this Section 7 are conditioned upon the indemnified
          party (a) giving prompt written notice of the Claim to the
          indemnifying party once the indemnified party becomes aware of the
          Claim (provided that failure to provide prompt written notice to the
          indemnifying party will not alleviate an indemnifying party's
          obligations under this Section 7 to the extent any associated delay
          does not materially prejudice or impair the defense of the related
          Claims), (b) granting the indemnifying party the option to take sole
          control of the defense (including granting the indemnifying party the
          right to select and use counsel of its own choosing) and settlement of
          the Claim (except that the indemnified party’s prior written approval
          will be required for any settlement that reasonably can be expected to
          require an affirmative obligation of the indemnified party), and (c)
          providing reasonable cooperation to the indemnifying party, and at the
          indemnifying party's request and expense, assistance in the defense or
          settlement of the Claim.
        </p>
        <p>
          <a name="_17dp8vu" />
          8. <strong>Term, Termination and Effect of Termination</strong>.
        </p>
        <p>
          8.1 <u>Term</u>.
        </p>
        <p>
          (a) <u>This Agreement</u>. The term of this Agreement (the "{" "}
          <strong>Term</strong>") will commence on the earlier of the date that
          You use the Platform or Services or the Effective Date of the
          applicable Order and, unless terminated as provided in this Agreement,
          continue until the termination or expiration of the last effective
          Order or SOW, whichever is later.
        </p>
        <p>
          (b) <u>Orders</u>. Each Order will commence on the Order Effective
          Date (as defined therein) and, unless earlier terminated as provided
          in this Agreement or the Order, will continue for the Order Term.
          Thereafter, unless terminated as set forth in Section 8.2 or otherwise
          provided in the Order, the Order will automatically renew for
          successive periods of 12 months unless otherwise set forth on the
          Order (each period referred to as an Order Term) unless either You or
          Fluke provides written notice of non-renewal no less than 30 days
          prior to the end of the then-current Order Term.
        </p>
        <p>
          (c) <u>SOWs</u>. Each SOW will commence on the SOW Effective Date (as
          defined therein) and, unless earlier terminated as provided in this
          Agreement or the SOW, will continue for the Professional Services
          Term.
        </p>
        <p>
          8.2 <u>Termination</u>. Either You or Fluke may terminate the Term, or
          any Order or SOW for cause if the other party materially breaches this
          Agreement or that Order or SOW and does not remedy the breach within
          30 days after its receipt of written notice of the breach. Further,
          notwithstanding any terms to the contrary in this Agreement, Fluke may
          (a) immediately terminate the Term or any Order or SOW on notice to
          You if You are in breach of Section 10 or if any Fees under this
          Agreement are 30 days or more overdue or (b) suspend use of the
          Services (or any portion thereof) without liability if (i) any Fees
          under this Agreement are 30 days or more overdue or (ii) Fluke
          reasonably determines that (A) the security, stability, integrity, or
          availability of the Services are imminently threatened or (B) Fluke is
          required by any applicable law to suspend the Services. Fluke may, for
          its convenience and without penalty, terminate the Term or any Order
          or SOW on at least ten (10) days’ notice to You. Fluke, in its sole
          discretion, may terminate access to free accounts or any part thereof
          at any time with prior notice.
        </p>
        <p>
          <a name="_26in1rg" />
          8.3 <u>Effect of Termination or Expiration</u>. Termination of one
          Order or SOW will not affect any other outstanding Orders or SOWs or
          this Agreement. Termination of the Term will terminate all Orders and
          SOWs then in effect. Upon any termination or expiration of the Term,
          (a) all rights granted to You under this Agreement will immediately
          cease and Fluke will, for 30 days, make the Service Records available
          for Your download in accordance with Your then-current Access
          Parameters, (b) You will immediately pay to Fluke all amounts due and
          payable up to the effective date of termination or expiration and any
          amounts that would be due and owing under this Agreement for the
          remainder of the Term, (c) each party will promptly return to the
          other party all Confidential Information of the other party then in
          its possession or destroy all copies of Confidential Information of
          the other party, at the other party's sole discretion and direction
          and (d) all defined terms and Sections 1.2(d), 1.4(a), 2, 3.1, 3.2, 4,
          6, 7.2, 7.4, 8.3, 9, 10, and 11 will survive indefinitely.
        </p>
        <p>
          <a name="_lnxbz9" />
          9.{" "}
          <strong>
            Additional Rights, Ownership, and Reservation of Rights
          </strong>
          . As between You and Fluke and subject to the express grants within
          this Agreement, Fluke owns all right, title and interest in and to the
          Services (and any and all modifications to or derivative works of the
          Services) and any and all Intellectual Property Rights embodied
          therein or related thereto. Each party reserves all rights not
          expressly granted in this Agreement, and no licenses are granted by
          either Your or Fluke to the other party under this Agreement, whether
          by implication, estoppel or otherwise, except as expressly set forth
          in this Agreement. For the purpose of this Agreement,
          <a name="_Hlk66101063">
            "<strong>Intellectual Property Rights</strong>" means all patents
            and related rights, copyrights and related rights, moral rights,
            trademarks and related rights, trade secret rights and any other
            form of intellectual property rights recognized in any jurisdiction,
            including applications and registrations for any of the foregoing
            and the right to bring actions and recover damages for any past,
            present, or future infringement, misappropriation, or violation of
            any of the foregoing
          </a>
          .
        </p>
        <p>
          10. <strong>Restrictions</strong>. Except as expressly authorized by
          this Agreement, You may not (a) modify, disclose, alter, translate or
          create derivative works of the Services (or any components thereof),
          (b) license, sublicense, resell, distribute, lease, rent, lend,
          transfer, assign or otherwise dispose of the Services (or any
          components thereof), (c) reverse engineer, decompile, disassemble,
          decrypt, re-engineer, reverse assemble, reverse compile or otherwise
          translate, create, or attempt to create the source code of the
          Services or their structural framework (in whole or in part), or
          perform any process intended to determine the source code for the
          Services, (d) use the Services to store or transmit any viruses,
          software routines or other code designed to permit unauthorized
          access, to disable, erase or otherwise harm software, hardware or
          data, or to perform any other harmful actions, (e) copy, frame or
          mirror any part or content of the Services, (f) copy any features or
          functions of the Services or use features or functions of the Services
          to build a competitive product or service, (g) interfere with or
          disrupt the integrity or performance of the Services, (h) attempt to
          gain unauthorized access to the Services or their related systems or
          networks, (i) disclose to any third party any performance information
          or analysis relating to the Services, (j) circumvent or attempt to
          circumvent any technological protection measures intended to restrict
          access to or use of any portion of the Services or the functionality
          of the Services, (k) take any action that imposes an unreasonable or
          disproportionately large load on the Services, (l) use the Services
          outside of the Access Parameters or for any purpose that is illegal in
          any way or that advocates illegal activity, or (m) cause or permit any
          individual or entity to do any of the foregoing.
        </p>
        <p>
          11. <strong>General Provisions</strong>.
        </p>
        <p>
          11.1 <u>Independent Contractors</u>. Neither You nor Fluke will, for
          any purpose, be deemed to be an agent, franchisor, franchise,
          employee, representative, owner or partner of the other party, and the
          relationship between You and Fluke will only be that of independent
          contractors. Neither You nor Fluke will have any right or authority to
          assume or create any obligations or to make any representations or
          warranties on behalf of any other party, whether express or implied,
          or to bind the other party in any respect whatsoever.
        </p>
        <p>
          11.2 <u>Governing Law and Venue</u>. This Agreement will be governed
          by and construed in accordance with the laws of the State of
          Washington, without resort to its conflict of law provisions. You and
          Fluke each irrevocably submits to the exclusive jurisdiction of any
          state or federal court sitting in King County, Washington (the "
          <strong>Chosen Courts</strong>") in any litigation arising out of or
          relating to this Agreement, agrees that all claims in respect of that
          litigation will be heard and decided only in that Chosen Court, waives
          any claim of inconvenient forum or other challenge to venue in that
          Chosen Court, and agrees not to bring or maintain that litigation
          before any tribunal other than the Chosen Courts (except, for clarity,
          in any proper appeal from a Chosen Court).
        </p>
        <p>
          11.3 <u>Publicity</u>. Notwithstanding any terms to the contrary in
          this Agreement, You consent to Fluke's use of Your name and logo on
          the Fluke website and on Fluke’s promotional and marketing related
          materials, identifying You as a customer of Fluke and describing Your
          use of the Services. You will, on Fluke's reasonable request,
          participate in a case study and related blog post with respect to Your
          use of the Services, provided that the case study and blog post will
          not be published until Fluke has obtained Your approval (and that
          approval will not be unreasonably withheld).
        </p>
        <p>
          11.4 <u>Third-Party Services</u>. You acknowledge and agree that Fluke
          may use third-party hosting infrastructures and/or other services in
          connection with the Services ("<strong>Third-Party Services</strong>")
          and, notwithstanding any terms to the contrary in this Agreement,
          Fluke disclaims any liability with respect to the Third-Party
          Services. You agree to abide by the terms and conditions provided by
          Fluke with respect to the Third-Party Services.
        </p>
        <p>
          11.5 <u>Maintenance and Modifications</u>. Notwithstanding any terms
          to the contrary in this Agreement, You acknowledge and agree that (a)
          Fluke may conduct maintenance on the Services from time to time
          without prior notice to You and (b) Fluke may modify features of the
          Services from time to time at Fluke's sole discretion, provided that
          those modifications will not materially degrade the Services.
        </p>
        <p>
          11.6 <u>Assignment</u>. Neither this Agreement nor any right or duty
          under this Agreement may be transferred, assigned or delegated by You,
          by operation of law or otherwise, without the prior written consent of
          Fluke, and any attempted transfer, assignment or delegation without
          that consent will be void and without effect. This Agreement will be
          binding upon and enforceable against any successor or permitted
          assignee.
        </p>
        <p>
          11.7 <u>Amendments and Waivers</u>. No modification, addition or
          deletion, or waiver of any rights under this Agreement will be binding
          on either You or Fluke unless clearly understood by You and Fluke to
          be a modification or waiver and signed by a duly authorized
          representative of each party. No failure or delay (in whole or in
          part) on the part of either Your or Fluke to exercise any right or
          remedy hereunder will operate as a waiver thereof or effect any other
          right or remedy. All rights and remedies hereunder are cumulative and
          are not exclusive of any other rights or remedies provided hereunder
          or by law. The waiver of one breach or default or any delay in
          exercising any rights will not constitute a waiver of any subsequent
          breach or default.
        </p>
        <p>
          11.8 <u>Notices</u>. Any notice or communication required or permitted
          to be given hereunder will be in writing, signed or authorized by the
          party giving notice, and may be delivered by hand, deposited with an
          overnight courier, sent by confirmed email, sent by confirmed
          facsimile, or mailed by registered or certified mail, return receipt
          requested, postage prepaid, in each case to the address of the
          receiving party as identified on this Agreement or at any other
          address as may hereafter be furnished in writing by either You or
          Fluke to the other party. That notice will be deemed to have been
          given as of the date it is delivered. Notice is effective on the
          earlier of 10 days from being deposited for delivery or the date on
          the confirmed facsimile, confirmed email or courier receipt.
        </p>
        <p>
          11.9 <u>Severability</u>. If any provision of this Agreement is
          invalid, illegal or unenforceable in any jurisdiction, (a) that
          invalidity, illegality or unenforceability will not affect any other
          provision of this Agreement or invalidate or render unenforceable that
          provision in any other jurisdiction, and (b) that provision, in that
          jurisdiction, will be replaced by a valid, legal and enforceable
          provision that best reflects Your and Fluke’s intent for that first
          provision.
        </p>
        <p>
          11.10 <u>Audit</u>. Fluke may, by itself or through an independent
          third party, audit Your use of the Services to verify You are in
          compliance with the terms and conditions of this Agreement. You agree
          to provide reasonable access to Your systems and records for purposes
          of conducting these audits.
        </p>
        <p>
          11.11 <u>Counterparts</u>. This Agreement may be executed (a) in two
          or more counterparts, each of which will be deemed an original and all
          of which will together constitute the same instrument; and (b) by the
          parties by exchange of signature pages by mail, facsimile or email (if
          email, signatures in Adobe PDF or similar format).
        </p>
        <p>
          11.12 <u>Force Majeure</u>. Except for payments due under this
          Agreement, neither You nor Fluke will be responsible for any failure
          to perform or delay attributable in whole or in part to any cause
          beyond its reasonable control including, but not limited to, acts of
          God (fire, storm, floods, earthquakes, etc.), acts of terrorism, civil
          disturbances, disruption of telecommunications, disruption of power or
          other essential services, interruption or termination of any services
          provided by any service providers used by Fluke, labor disturbances,
          vandalism, cable cut, computer viruses or other similar occurrences,
          or any malicious or unlawful acts of any third party.
        </p>
      </div>
    </Box>
  );
};
export const termsOfUse0 = `Cubyt Software and Services Agreement 20200408

Cubyt Software and Services Agreement

Last Updated: April 8, 2020

This Cubyt Software and Services Agreement document ("SSA") governs your access to and use of the 
Cubyt application, its related services, including the Cubyt websites (collectively, the "Services")
(together with Services, the "Services"), that are provided by Fluke Electronics Corporation and its 
subsidiaries or affiliates thereof transacting under such or other brands or in their own names 
(collectively, "Fluke" or "we" or "us" or “our”). PLEASE READ THIS SSA CAREFULLY. IT CONTAINS 
IMPORTANT TERMS THAT AFFECT YOU AND YOUR USE OF THE SERVICES. YOU REPRESENT 
AND WARRANT THAT YOU ARE ENTERING INTO THIS SSA IN THE COURSE OF CARRYING ON 
BUSINESS OR FOR BUSINESS PURPOSES (AND NOT AS A CONSUMER). HOWEVER IN THE EVENT 
THAT ANY CONSUMER LAWS APPLY UNDER APPLICABLE LAW, THIS SSA DOES NOT AFFECT 
OR PREJUDICE ANY STATUTORY RIGHTS YOU MAY HAVE UNDER APPLICABLE LAW. IF YOU 
ARE ENTERING INTO THIS SSA ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU 
REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND SUCH COMPANY OR 
ENTITY TO THE TERMS OF THIS SSA, IN WHICH CASE THE TERMS "YOU" OR "YOUR" SHALL 
ALSO REFER TO THE COMPANY OR ENTITY WHICH YOU REPRESENT OR ARE EMPLOYED BY, 
ITS AFFILIATES AND EACH OF THEIR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS AND 
REPRESENTATIVES. BY CLICKING THE "CREATE ACCOUNT" BUTTON, OR INSTALLING, 
ACCESSING, USING OR UPDATING THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS 
OF THIS SSA ON YOUR OWN BEHALF AND, AS APPLICABLE, ON BEHALF OF THE COMPANY OR 
ENTITY WHICH EMPLOYS YOU OR WHICH YOU REPRESENT. IF YOU DO NOT AGREE TO THE 
TERMS OF THIS SSA, DO NOT INSTALL, ACCESS OR USE THE SERVICES. PLEASE NOTE THAT, IF 
YOU DO NOT AGREE TO THE TERMS OF THIS SSA, YOU DO NOT HAVE ANY RIGHT OR LICENSE 
TO INSTALL, ACCESS OR USE THE SERVICES.
1. Information Practices and Privacy Policy. Fluke collects information, including personally identifiable 
information, in connection with the access to and use of the Services. The information that is collected in 
connection with the Services will be used and shared for various purposes, including without limitation 
to provide, maintain and improve the Services that we ordinarily provide or which you have specifically 
requested and as is reasonably necessary to operate our business. For more information about how Fluke 
collects, uses, and shares information, please refer to our Privacy Policy. 
You agree that Fluke may aggregate all or any portion of the information we collect (collectively, 
"Aggregated Data") and that we shall own exclusive rights, including without limitation all intellectual 
property rights, in and to the Aggregated Data and shall be entitled to the unrestricted use and sharing of 
the Aggregated Data for any purpose, commercial or otherwise, subject to compliance with applicable 
data protection and privacy law.
By accessing and using the Services, you consent to the processing and transfer of your personal 
information in and to the United States and other countries (including countries that may provide a lower 
level of legal protection for your information) in the manner described in our Privacy Policy.
2. Eligibility; Registration; Account. The Services are not targeted towards, nor intended for use by, 
anyone under the age of 18 (or the age of majority in your country or jurisdiction of residence). By 
accepting the terms of this SSA, you represent and warrant that you (a) are 18 years of age or older (or 
have reached the age of majority in your country or jurisdiction of residence); and (b) have the right, 
capacity, and authority to accept and be bound by this SSA and that, in doing so, you will not violate any 
other agreement to which you are a party.
Cubyt Software and Services Agreement 20200408
In order to access and use the Services, you will be required to register for an account. When registering 
for an account, you cannot create an account name that incorporates a trademark or service mark without 
authorization from the mark owner. Fluke reserves the right, in its discretion, to reclaim account names, 
or to take other reasonable action as necessary, on behalf of any business or individual that holds legal 
claim, including trademark and service mark rights, in a name. In consideration of your use of the 
Services, you agree to (a) provide accurate, current and complete account information; (b) maintain and 
promptly update your account information; (c) maintain the security of your password and accept all 
risks of unauthorized access to your account and the information you provide to us except as a result of 
Fluke’s breach of this SSA; and (d) promptly notify us if you discover or otherwise suspect any security 
breaches related to the Services. Whenever reference is made in this SSA to the “discretion” of Fluke, 
such reference shall mean the “sole and absolute discretion” of Fluke.
3. Ownership; Services Plans and License; License Restrictions.
3.1 Ownership. Unless otherwise indicated in this SSA, through the Services, or otherwise by 
Fluke, you acknowledge that the Services and all content and other materials therein provided by Fluke 
or our third-party licensors, including, without limitation, the Fluke and Cubyt logos and all designs, 
text, graphics, pictures, information, data, software, sound files, other files and the selection and 
arrangement thereof (collectively, "Content"), are the proprietary property of Fluke or our licensors, as 
applicable, and are protected by U.S. and international copyright and other applicable laws. 
3.2 Right to Access Services. Subject to the terms and conditions of this SSA, Fluke grants you a 
limited, non-exclusive, non-transferable, right, access and use the Services and Content in the regular 
course of your work. Any use of the Services or Content other than as specifically authorized under this 
SSA, and any applicable accompanying commercial terms, without the prior written permission of Fluke, 
is strictly prohibited and will terminate the access rights granted herein with or without notice. Such 
unauthorized use may also violate applicable laws, including without limitation copyright and trademark 
laws and applicable communications regulations and statutes
Fluke may provide updates and upgrades to the Services from time to time. The terms of this SSA will 
govern any updates or upgrades provided by Fluke that modify, replace or supplement the original 
Services, unless such update or upgrade is accompanied by a separate set of terms in which case the 
terms of that separate set of terms will govern.
3.3 License Restrictions. Except as expressly set out in this SSA or as otherwise provided by 
applicable law, the access granted to you under Section 3.2 of this SSA does not allow you to do any of 
the following: (a) download (other than page caching) any portion of the Services, Content or any 
information contained therein, except as expressly permitted on the Services or in this SSA; (b) reverse 
engineer, decompile, disassemble, or attempt to discover any source code or trade secrets related to the 
Services, Content or any proprietary materials of Fluke; (c) use any data mining, robots or similar data 
gathering or extraction methods; (d) modify, alter or create any derivative works of the Services or 
Content or based on any proprietary materials of Fluke; (e) remove, alter, or obscure any copyright, 
trademark, service mark or other proprietary rights notice on or in the Services or Content; (f) work 
around any technical limitations on or in the Services or Content; or (g) use the Services or Content for 
purposes for which it was not designed or intended. Unless explicitly stated herein or otherwise by 
Fluke, nothing in this SSA shall be construed as conferring any license to intellectual property rights, 
whether by estoppel, implication or otherwise.
4. Services Payment Terms. 
Cubyt Software and Services Agreement 20200408
4.1 Access Fees. Fluke reserves the right to charge for access to some or all features, even if those 
features are provided free at any other time, within the Cubyt service according to payment terms, which 
will be provided to you prior to any obligation for payment. 
4.2 Suspension or Termination; Late Fees; Access to User Content post-Term or Termination. 
Fluke may suspend or terminate, in its discretion but subject to applicable law, your access to Cubyt if 
you fail to pay any amounts due or if you or others permitted access by you(“ Permitted Users “) 
otherwise breach the terms of this SSA. Any use of the Services other than as specifically authorized 
under this SSA, without the prior written permission of Fluke, is strictly prohibited, constitutes a breach 
of this SSA, and may result in termination of any or all access to Cubyt, at Fluke’s discretion. Such 
unauthorized use may also violate applicable laws, including without limitation copyright and trademark 
laws and applicable communications regulations and statutes. 
4.3 No Resale. You are not permitted to resell or otherwise transfer your permission to access the 
Services without the express written permission of Fluke, which Fluke is under no obligation to provide. 
5. Permitted Users. You will ensure that all of your Permitted Users agree to the terms of this SSA and 
have been notified of how Fluke collects, uses, and shares information, as described in the Fluke Privacy 
Policy before providing your Permitted Users with access to the Services. All references to “you” or 
“your” in this SSA shall also be construed to refer to your Permitted Users. You are responsible for (a) 
identifying and authenticating all Permitted Users; (b) approving access by such Permitted Users to the 
Services; (c) ensuring Permitted Users’ compliance with this SSA; (d) controlling against unauthorized 
access or use by Permitted Users; (e) maintaining the confidentiality of user names, passwords and 
account information; and (f) providing or obtaining any necessary notifications or consents from 
Permitted Users as required by applicable law. Fluke is not responsible for any harm caused by your 
Permitted Users, including individuals who were not authorized to have access to the Services but who 
were able to gain access because user names, passwords or accounts were not terminated on a timely 
basis in your local identity management infrastructure, your local computers, or otherwise by you or on 
your behalf. You are responsible for all activities that occur under or in connection with your and your 
Permitted Users’ user names, passwords or accounts (except as a result of Fluke’s breach of this SSA) or 
as a result of your or your Permitted Users’ access to the Services and agree to notify Fluke immediately 
in writing of any unauthorized use. You agree to make every reasonable effort to prevent unauthorized 
third parties from accessing the Services.
6. Administrators. To facilitate sharing of certain proprietary information within your own 
organization, you may be required designate one or more Permitted Users as an administrator 
(“Administrator”) of your account. You agree that the Administrator may, on your behalf, grant and 
revoke the access and use rights of other Permitted Users, at any time and for any reason, and set 
permission levels, roles, or otherwise manage your account. You may change your Administrator(s) at 
any time and for any reason.
7. User Content. The Services include features and areas in which you or other users may create, upload, 
transmit or store text, sound, photos, videos, graphics, code, templates, scripts, items or other materials, 
including but not limited to information related to the Tools and users thereof (collectively, "User
Content"). You understand that your User Content may be viewable by others, including other 
Permitted Users. You agree that you are solely responsible for your User Content, for your use of such 
interactive features and areas and that you use them at your own risk, and that if you wish to impose 
restrictions on individuals or entities with whom you share your User Content, you will need to impose 
those obligations directly on those individuals or entities through arrangements other than this SSA. You 
are solely responsible for any decision made by you in connection with any User Content including 
determining that you have all necessary consents to share the User Content on the Services. Fluke makes 
no representation or warranty (a) as to the integrity or value of any User Content; and (b) that it will 
Cubyt Software and Services Agreement 20200408
retain any User Content or other information associated with User Content in the event that the Services
(or any features or portions thereof) are suspended, terminated, discontinued (temporarily or 
permanently), or otherwise made unavailable or in the event that this SSA is suspended or terminated, 
for any reason whatsoever. 
8. Acceptable Use. By using the Services, you agree not to create, upload, transmit, store, distribute or 
otherwise publish through the Services any of the following:
▪ User Content that is reasonably likely to be unlawful, libelous, defamatory, obscene, 
pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity 
rights, abusive, inflammatory, fraudulent or otherwise objectionable;
▪ User Content that is reasonably likely to constitute, encourage or provide instructions for a 
criminal offense, violate the rights of any party (including any right to privacy), or that would 
otherwise create liability or violate any local, state, national or international law;
▪ User Content that may infringe any patent, trademark, trade secret, copyright or other 
intellectual or proprietary right of any party. By uploading or transmitting any User Content, 
you represent and warrant that you have the lawful right to distribute and reproduce such User 
Content;
▪ User Content that contains or depicts any statements, remarks or claims that do not reflect your 
honest views and experiences;
▪ User Content that impersonates any person or entity or otherwise misrepresents your affiliation 
with a person or entity;
▪ Unsolicited promotions, political campaigning, advertising or solicitations;
▪ Personal or private information of any third party (except information related to employees or 
contractors using the Services), including, without limitation, addresses, phone numbers, email 
addresses, Social Security numbers, health or medical information and credit card numbers or 
other financial or personal information, except as specifically authorized by us and such third 
party;
▪ Viruses, corrupted data or other harmful, disruptive or destructive files; or
▪ User Content that Fluke determines in its discretion to be objectionable or inappropriate or which 
restricts or inhibits any other person from using or enjoying the Services, or which may expose 
Fluke or our users to any harm, disrepute or liability of any type.
You further agree that you will not violate any law, regulation, contract, intellectual property or other 
third-party right or commit a tort in connection with your use of the Services, and that you are solely 
responsible for your conduct while using the Services. You agree that you will abide by this SSA and will 
not:
▪ Use the Services in any manner that could interfere with, disrupt, negatively affect or inhibit 
other users from fully enjoying the Services, or that could damage, disable, overburden or impair 
the functioning of the Services in any manner;
▪ Send any unsolicited or unauthorized advertising, solicitations, promotional materials, spam, 
junk mail, chain letters or pyramid schemes, or harvest or collect the email addresses or other 
Cubyt Software and Services Agreement 20200408
contact information of other users from the Services for the purpose of sending spam or other 
commercial messages;
▪ Use any robot, spider, crawler, scraper or other automated means or interface not provided by us 
to access the Services or to extract data;
▪ Reverse engineer any aspect of the Services or do anything that might discover source code or 
bypass or circumvent measures employed to prevent or limit access to any area, content or code 
of the Services (except as otherwise expressly provided by law);
▪ Use or attempt to use another user's account without express written authorization from such 
user and Fluke;
▪ Attempt to circumvent any content filtering techniques Fluke employs, or attempt to access any 
service or area of the Services that you are not expressly authorized to access;
▪ Attempt to indicate in any manner that you have a relationship with us or that we have endorsed 
you or any products or services for any purpose;
▪ Engage in any harassing, intimidating, predatory or stalking conduct;
▪ Develop any third-party applications that interact with the Services without our prior written 
consent, to the full extent permitted by applicable law; or
▪ Use the Services for any illegal or unauthorized purpose or engage in, encourage, or promote any 
activity that violates this SSA.
Except as otherwise provided under applicable law, Fluke is not responsible or liable for the conduct of, 
or your interactions with, any users of the Services (whether online or offline), nor is Fluke responsible or 
liable for any associated loss, damage, liability, injury or harm. As a provider of interactive services, 
Fluke is not liable for any statements, representations or User Content provided by our users through the 
interactive area of the Services. Although Fluke has no obligation to screen, edit or monitor any User 
Content, Fluke reserves the right, and has discretion, to remove, screen or edit any User Content
uploaded to or stored on the Services at any time and for any reason without notice, and you are solely 
responsible for creating backup copies of and replacing any User Content you upload, transmit or store 
on the Services at your sole cost and expense. Any use of the Services in violation of this SSA may result 
in, among all other rights and remedies available to Fluke under law, termination or suspension of your 
rights to use the Services.
9. Rights in User Content. You retain all ownership rights in the User Content you submit to Fluke 
through the Services. By uploading or transmitting User Content through the Services, you grant Fluke, 
to the fullest extent permitted by applicable law, a nonexclusive, royalty-free, perpetual, irrevocable and 
fully transferable and sublicensable right to use, reproduce, modify, adapt, or translate the User Content, 
in whole or in part, throughout the world to provide, maintain and improve the Services and other 
products and services offered by or on behalf of Fluke from time to time. By uploading or transmitting 
User Content through the Services to publicly available, sharing areas, of the Services, you grant Fluke, 
and other Cubyt users, to the fullest extent permitted by applicable law, a nonexclusive, royalty-free, 
perpetual, irrevocable and fully transferable and sublicensable right to use, reproduce, modify, adapt, or 
translate the User Content, in whole or in part, throughout the world to facilitate the ability of the Cubyt 
user community access your shared User Content. By uploading or transmitting User Content to the 
Services, you represent and warrant that (a) such User Content is not subject to any third-party 
confidentiality obligations that would be breached by the access, use, storage and sharing contemplated 
Cubyt Software and Services Agreement 20200408
by this SSA; (b) you own and control all of the rights to the User Content that you create, upload or 
transmit or you otherwise have all necessary rights to create, upload or transmit such User Content to the 
Services; (c) the User Content is accurate and not misleading or harmful in any manner; and (d) the User 
Content, and your use, uploading and transmitting thereof, does not and will not violate this SSA or any 
applicable law, rule or regulation.
10. Trademarks. “FLUKE”, the FLUKE logo, “CUBYT“, and the CUBYT logos and any other Fluke 
product or service names, logos or slogans that may appear in or on the Services are trademarks of Fluke 
in the United States and in other countries, and may not be copied, imitated or used, in whole or in part, 
without the prior written permission of Fluke. Third-party marks appearing in or on the Services are 
owned by their respective companies and may not be used without permission of the applicable 
trademark holder. You may not use any metatags or other "hidden text" utilizing "Fluke" or any other 
name, trademark or product or service name of Fluke without our prior written permission. In addition, 
the look and feel of the Services, including all page headers, custom graphics, button icons and scripts, 
are the service mark, trademark and/or trade dress of Fluke and may not be copied, imitated or used, in 
whole or in part, without our prior written permission. All other trademarks, registered trademarks, 
product names and company names or logos mentioned in or on the Services are the property of their 
respective owners. Reference to any products, services, processes or other information, by name, 
trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship 
or recommendation by Fluke.
11. Third-Party Content. Fluke may provide third-party content, including content provided by other 
users, on the Services and may provide links to web pages and content of third parties (collectively, 
"Third-Party Content") as a service to those interested in this information. Fluke does not control, 
endorse or adopt any Third-Party Content and makes no representations or warranties of any kind 
regarding the Third-Party Content, including without limitation regarding its accuracy or 
completeness. You acknowledge and agree that Fluke is not responsible or liable in any manner for any 
Third-Party Content and undertakes no responsibility to update or review any Third-Party 
Content. Users use such Third-Party Content at their own risk and are urged to review any applicable 
terms and conditions and privacy policies relating to Third-Party Content prior to using any Third-Party 
Content.
12. Advertisements and Promotions; Third-Party Products and Services. Fluke may display 
advertisements and promotions from third parties in or on the Services or may otherwise provide 
information about or links to third-party products or services on Licensed the Services. Your business 
dealings or correspondence with, or participation in promotions of, such third parties, and any terms, 
conditions, warranties or representations associated with such dealings or promotions, are solely between 
you and such third party. Fluke is not responsible or liable for any loss or damage of any sort incurred as 
the result of any such dealings or promotions or as the result of the presence of third-party information in 
or on the Services. Users transact with such third parties at their own risk and are urged to review any 
applicable terms and conditions and privacy policies relating to such transactions and dealings prior to 
entering into any such transactions or dealings.
13. Feedback. You may submit to Fluke questions, comments, suggestions, ideas, plans, notes, drawings, 
original or creative materials or other information or materials about Fluke or the Services (collectively, 
"Feedback"). Feedback, whether submitted through the Services or otherwise is non-confidential and 
shall become the sole property of Fluke. Fluke shall own exclusive rights, including all intellectual 
property rights, in and to such Feedback and shall be entitled to the unrestricted use and dissemination of 
this Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to 
you.
Cubyt Software and Services Agreement 20200408
14. No Warranty. THE LAWS OF SOME STATES OR JURISDICTIONS DO NOT ALLOW THE 
EXCLUSION OF IMPLIED WARRANTIES. TO THE EXTENT THAT THOSE LAWS APPLY, THE 
EXCLUSIONS SET FORTH BELOW AND IN THIS SSA MAY NOT APPLY TO YOU.
SUBJECT TO APPLICABLE LAW, YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT: (A) THE 
SERVICES MAY CONTAIN BUGS, ERRORS, AND DEFECTS; (B) USE OF THE SERVICES IS AT YOUR 
SOLE RISK; AND (C) THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, 
ACCURACY AND EFFORT IS WITH YOU. ACCORDINGLY, THE SERVICES ARE PROVIDED "AS IS," 
"AS AVAILABLE," WITH ALL FAULTS, DEFECTS AND ERRORS AND WITHOUT WARRANTY OF 
ANY KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, FLUKE DISCLAIMS 
ALL WARRANTIES AND CONDITIONS (EXPRESS, LEGAL, OR IMPLIED AND ARISING BY LAW OR 
OTHERWISE) REGARDING THE SERVICES AND THEIR PERFORMANCE OR SUITABILITY FOR 
YOUR INTENDED USE, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF 
MERCHANTABILITY, QUALITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY, TITLE, OR 
NON-INFRINGEMENT. EXCEPT FOR LIABILITY WHICH CANNOT BE EXCLUDED BY LAW, FLUKE 
SHALL HAVE NO LIABILITY OF ANY KIND FOR THE USE OF, OR INABILITY TO USE, THE 
SERVICES OR ANY SERVICE THAT THE SERVICES IS INTENDED TO ACCESS OR FOR ANY LOSS, 
CORRUPTION OR OTHER LIMITATION OF ACCESS TO OR USE OF DATA. TO THE FULLEST 
EXTENT PERMITTED BY APPLICABLE LAW, FLUKE DOES NOT WARRANT THAT THE SERVICES
WILL BE DELIVERED FREE OF ANY INTERRUPTIONS, DELAYS, OMISSIONS OR ERRORS 
(“FAULTS”) OR IN A SECURE MANNER OR THAT ANY FAULTS WILL BE CORRECTED. NO 
VERBAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY FLUKE OR OUR AUTHORIZED 
REPRESENTATIVES SHALL CREATE A WARRANTY. IN THE EVENT THAT THE SERVICES PROVE 
DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR 
CORRECTION. HOWEVER, TO THE EXTENT THE LAWS OF SOME STATES OR JURISDICTIONS DO 
NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN THE EVENT THAT THE SERVICES 
PROVE DEFECTIVE, BUT DO PERMIT FLUKE TO LIMIT THE CONSEQUENCES OF SUCH 
WARRANTIES, FLUKE LIMITS ITS LIABILITY TO THE REPAIR OR REPLACEMENT OR THE 
RESUPPLY, AT ITS ELECTION, OF SUCH GOODS OR SERVICES.
15. Indemnification. You agree to defend, indemnify, and hold harmless Fluke and our subsidiaries, 
affiliates, independent contractors and service providers, and each of their respective members, 
managers, partners, equity owners, directors, officers, employees, representatives and agents 
(collectively, "Representatives") from and against all claims, suits, proceedings, actions, damages, costs, 
liabilities and expenses (including but not limited to reasonable attorneys' fees, and whether pending, 
threatened, settled or otherwise) arising out of or related to your use of, or inability to use, the Services.
16. Limitation of Liability. THE LAWS OF SOME STATES OR JURISDICTIONS DO NOT ALLOW THE 
EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. TO THE EXTENT THOSE LAWS APPLY, 
THE EXCLUSIONS AND LIMITATIONS SET FORTH BELOW AND IN THIS SSA MAY NOT APPLY 
TO YOU. 
TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NO REPRESENTATIVE SHALL 
HAVE ANY LIABILITY (WHETHER IN CONTRACT, WARRANTY, TORT, NEGLIGENCE, EXTRA CONTRACTUAL OR CIVIL LIABILITY OR OTHERWISE) FOR ANY DAMAGES SUSTAINED BY YOU 
ARISING FROM YOUR USE OR INABILITY TO USE THE SERVICES, INCLUDING WITHOUT 
LIMITATION ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE 
DAMAGES OR LOSS OF DATA, REVENUE, PROFIT, ANTICIPATED SAVINGS OR OTHER 
ECONOMIC LOSSES, EVEN IF SUCH REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY 
OF SUCH DAMAGES. IN THE EVENT OF ANY CLAIM BY YOU AGAINST ANY REPRESENTATIVE, 
TO THE EXTENT NOT PROHIBITED BY LAW, YOU SHALL BE PERMITTED TO RECOVER ONLY 
DIRECT DAMAGES FOR THE GREATER OF 500.00 USD OR THE AMOUNT YOU PAID FOR THE 
Cubyt Software and Services Agreement 20200408
SERVICES IN THE PAST SIX MONTHS. NOTWITHSTANDING ANYTHING ELSE SET OUT IN THIS 
SSA, TO THE EXTENT REQUIRED BY APPLICABLE LAW, FLUKE DOES NOT EXCLUDE OR LIMIT 
OUR LIABILITY UNDER THIS SSA FOR (A) DEATH OR PERSONAL INJURY CAUSED BY 
NEGLIGENCE; (B) FRAUD OR FRAUDULENT MISREPRESENTATION; OR (C) ANY OTHER 
LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED BY LAW. 
17. Termination of the SSA. Subject to and in addition to cancellation terms in Section 4, you may 
terminate this SSA at any time by (a) ceasing access to and use of the Services and (b) deleting all copies 
of the Licensed Application in your possession or control. Fluke reserves the right to change, suspend, 
remove, discontinue, or disable access to the Services at any time, with or without notice, and to 
terminate your license to use the Services at any time, for any or no reason. Subject to Section 4.7, in the 
event of termination due to your breach of this SSA, the licenses granted to you herein will automatically 
terminate and you must immediately cease all use of the Services and destroy all copies of the Services
within your possession or control. You agree that Fluke will not be liable for any modification, 
suspension or discontinuance of the Services (or any part thereof).
The following Sections will survive the termination of this SSA: Sections 1, 2, and 4 - 20.
18. Remedy. We reserve the right to seek all remedies available by law and in equity for any violation of 
this SSA. Any rights not expressly granted herein are reserved.
19. Governing Law; Jurisdiction. To the full extent permitted by applicable law, this SSA is governed by 
the laws of the State of Washington, USA, without regard to its choice or conflicts of law principles that 
might refer the interpretation or enforcement of this SSA to the laws of any other jurisdiction. You 
hereby irrevocably consent, and irrevocably waive any right to object, to the jurisdiction of the state and 
federal courts located in King County, Washington with respect to any proceeding regarding this SSA or 
the Services. You will not prosecute any action, suit, proceeding or claim arising under or by reason of 
this SSA or the Services except in such courts. The parties expressly exclude the application of the UN 
Convention on Contracts for the International Sale of Goods to this SSA. 
20. Miscellaneous. If any provision of this SSA is found to be invalid or unenforceable, such provision 
will be deemed to be restated to reflect the original intention of the parties to the maximum extent 
possible and in accordance with applicable law, and the remaining provisions, terms, covenants, and 
restrictions of this SSA will remain in full force and effect. You may not assign this SSA or any of your 
rights under this SSA without the prior written consent of Fluke, which may be granted or conditioned in 
our discretion, and any attempted assignment without such consent shall be null and void. Subject to the 
foregoing restriction, this SSA will be fully binding upon, inure to the benefit of, and be enforceable by 
Fluke and our respective successors and assigns. Any failure by Fluke to insist upon or enforce 
performance by you of any of the provisions of this SSA or to exercise any rights or remedies under this 
SSA or otherwise by law will not be construed as a waiver or relinquishment of any right to assert or rely 
upon the provision, right or remedy in that or any other instance; rather, the provision, right or remedy 
will be and shall remain in full force and effect. This SSA does not create any reasonable expectation or 
promise that the Services will not contain any content that is prohibited by this SSA. This SSA, and the 
terms and policies incorporated by reference, sets forth the entire agreement between you and Fluke with 
respect to your use of the Services.`;
