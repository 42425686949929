import React from "react";
import { StyledDivider } from "./styles";


/**
 * General Divider component in JSDOC
 * @param {object} props Component props
 *
 */
const Divider = React.forwardRef((props, ref) => {
  return (
    <StyledDivider {...props} ref={ref}/>
  );
});
Divider.displayName = StyledDivider.displayName;
Divider.propTypes = StyledDivider.propTypes;
export default Divider;
