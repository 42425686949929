import React from "react";
import { StyledStepIcon } from "./styles";


/**
 * General StepIcon component in JSDOC
 * @param {object} props Component props
 *
 */
const StepIcon = React.forwardRef((props, ref) => {
  return (
    <StyledStepIcon {...props} ref={ref}/>
  );
});
StepIcon.displayName = StyledStepIcon.displayName;
StepIcon.propTypes = StyledStepIcon.propTypes;
export default StepIcon;
