import React from "react";
import { StyledTableFooter } from "./styles";


/**
 * General TableFooter component in JSDOC
 * @param {object} props Component props
 *
 */
const TableFooter = React.forwardRef((props, ref) => {
  return (
    <StyledTableFooter {...props} ref={ref}/>
  );
});
TableFooter.displayName = StyledTableFooter.displayName;
TableFooter.propTypes = StyledTableFooter.propTypes;
export default TableFooter;
