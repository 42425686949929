import styled from "@emotion/styled";
import { CardActions } from "@mui/material";

const StyledCardActions = styled(CardActions)`
  // styles here
`;

StyledCardActions.propTypes = CardActions.propTypes;
StyledCardActions.displayName = "CardActions";

export { StyledCardActions };
