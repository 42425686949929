import styled from "@emotion/styled";
import { CardMedia } from "@mui/material";

const StyledCardMedia = styled(CardMedia)`
  // styles here
`;

StyledCardMedia.propTypes = CardMedia.propTypes;
StyledCardMedia.displayName = "CardMedia";

export { StyledCardMedia };
