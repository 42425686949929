import styled from "@emotion/styled";
import { DialogContentText } from "@mui/material";

const StyledDialogContentText = styled(DialogContentText)`
  // styles here
`;

StyledDialogContentText.propTypes = DialogContentText.propTypes;
StyledDialogContentText.displayName = "DialogContentText";

export { StyledDialogContentText };
