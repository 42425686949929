import React from "react";
import { StyledTableRow } from "./styles";


/**
 * General TableRow component in JSDOC
 * @param {object} props Component props
 *
 */
const TableRow = React.forwardRef((props, ref) => {
  return (
    <StyledTableRow {...props} ref={ref}/>
  );
});
TableRow.displayName = StyledTableRow.displayName;
TableRow.propTypes = StyledTableRow.propTypes;
export default TableRow;
