import styled from "@emotion/styled";
import { Select } from "@mui/material";

const StyledSelect = styled(Select)`
  // styles here
`;

StyledSelect.propTypes = Select.propTypes;
StyledSelect.displayName = "Select";

export { StyledSelect };
