import styled from "@emotion/styled";
import { Pagination } from "@mui/material";

const StyledPagination = styled(Pagination)`
  // styles here
`;

StyledPagination.propTypes = Pagination.propTypes;
StyledPagination.displayName = "Pagination";

export { StyledPagination };
