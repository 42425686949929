import React from "react";
import { StyledSkeleton } from "./styles";


/**
 * General Skeleton component in JSDOC
 * @param {object} props Component props
 *
 */
const Skeleton = React.forwardRef((props, ref) => {
  return (
    <StyledSkeleton {...props} ref={ref}/>
  );
});
Skeleton.displayName = StyledSkeleton.displayName;
Skeleton.propTypes = StyledSkeleton.propTypes;
export default Skeleton;
