import styled from "@emotion/styled";
import { FormHelperText } from "@mui/material";

const StyledFormHelperText = styled(FormHelperText)`
  // styles here
`;

StyledFormHelperText.propTypes = FormHelperText.propTypes;
StyledFormHelperText.displayName = "FormHelperText";

export { StyledFormHelperText };
