import styled from "@emotion/styled";
import { AlertTitle } from "@mui/material";

const StyledAlertTitle = styled(AlertTitle)`
  // styles here
`;

StyledAlertTitle.propTypes = AlertTitle.propTypes;
StyledAlertTitle.displayName = "AlertTitle";

export { StyledAlertTitle };
