import styled from "@emotion/styled";
import { BottomNavigation } from "@mui/material";

const StyledBottomNavigation = styled(BottomNavigation)`
  // styles here
`;

StyledBottomNavigation.propTypes = BottomNavigation.propTypes;
StyledBottomNavigation.displayName = "BottomNavigation";

export { StyledBottomNavigation };
