import React from "react";
import {
  Menu as MenuIcon,
  ArrowBack,
  ExpandMore,
  ExpandLess,
  NotificationsOutlined,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import {
  Badge,
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  Tooltip,
  MenuItem,
} from "../../components";

import { createTheme, ThemeProvider } from "@mui/material/styles";
const pages = ["Work", "Users", "Account"];

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const darThemeAppBar = createTheme({ palette: { mode: "dark" } });

const Header = ({
  appName = "COLUMBIA",
  headerBackgroundImage = "https://d3ab4pgc1q9ec1.cloudfront.net/client/assets-bg.png",
}) => {
  const navigation = [
    { name: "Work", items: ["All"] },
    { name: "Users", items: ["All Users", "Add User"] },
    { name: "Account", items: ["Settings", "Sign Out"] },
  ];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      {/* <ThemeProvider theme={darThemeAppBar}> */}
      <StyledAppBar position="static">
        <Container maxWidth="xl">
          <StyledToolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: `"Roboto-Black", "Roboto Black", "Roboto", sans-serif`,
                // fontWeight: 900,
                color: "#f2f2f2",
              }}
            >
              {appName}
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              {appName}
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            />
            <Box
              sx={{
                //   flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
              style={{ alignItems: "center" }}
            >
              {navigation.map((page) => (
                <>
                  <Tooltip title={page.name} key={page.name}>
                    <Button
                      key={page}
                      // onClick={handleOpenUserMenu}
                      onClick={() => alert("Coming Soon!")}
                      endIcon={anchorElUser ? <ExpandLess /> : <ExpandMore />}
                      sx={{ color: "white" }}
                      style={{ marginRight: 14 }}
                      // sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page.name}
                    </Button>
                  </Tooltip>
                  {/* <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {page.items.map((item) => (
                      <MenuItem key={item} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">{item}</Typography>
                      </MenuItem>
                    ))}
                  </Menu> */}
                </>
              ))}
              <Tooltip title={"Notifications"}>
                <Badge color="error" badgeContent={4}>
                  <NotificationsOutlined style={{ cursor: "pointer" }} />
                </Badge>
              </Tooltip>
            </Box>

            {/* <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
          </StyledToolbar>
        </Container>
      </StyledAppBar>
      {/* </ThemeProvider> */}
      <Box
        sx={{
          height: 110,
          //   opacity: 0.8,
          position: "relative",
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundImage: `linear-gradient(0deg, #000000 0%, #000000 1%, rgba(102, 102, 102, 0) 100%, rgba(102, 102, 102, 0) 100%), url(${headerBackgroundImage})`,
        }}
        style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
      >
        <Container>
          <Button
            startIcon={<ArrowBack />}
            onClick={() => alert("Coming Soon!")}
            component="a"
          >
            Back
          </Button>
          <Typography variant="h4" component="h4" color="white">
            Assets
          </Typography>
        </Container>
      </Box>
    </>
  );
};

const StyledAppBar = styled(AppBar)`
  /* background: #333333; */
`;

const StyledToolbar = styled(Toolbar)`
  /* height: 50px;
  @media (min-width: 600px) {
    min-height: 50px;
  } */
`;
export { Header };
