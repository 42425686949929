import styled from "@emotion/styled";
import { SpeedDialIcon } from "@mui/material";

const StyledSpeedDialIcon = styled(SpeedDialIcon)`
  // styles here
`;

StyledSpeedDialIcon.propTypes = SpeedDialIcon.propTypes;
StyledSpeedDialIcon.displayName = "SpeedDialIcon";

export { StyledSpeedDialIcon };
