import React from "react";
import { StyledAccordionDetails } from "./styles";


/**
 * General AccordionDetails component in JSDOC
 * @param {object} props Component props
 *
 */
const AccordionDetails = React.forwardRef((props, ref) => {
  return (
    <StyledAccordionDetails {...props} ref={ref}/>
  );
});
AccordionDetails.displayName = StyledAccordionDetails.displayName;
AccordionDetails.propTypes = StyledAccordionDetails.propTypes;
export default AccordionDetails;
