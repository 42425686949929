import React from "react";
import { StyledListItemButton } from "./styles";


/**
 * General ListItemButton component in JSDOC
 * @param {object} props Component props
 *
 */
const ListItemButton = React.forwardRef((props, ref) => {
  return (
    <StyledListItemButton {...props} ref={ref}/>
  );
});
ListItemButton.displayName = StyledListItemButton.displayName;
ListItemButton.propTypes = StyledListItemButton.propTypes;
export default ListItemButton;
