import styled from "@emotion/styled";
import { StyledEngineProvider } from "@mui/material";

const StyledStyledEngineProvider = styled(StyledEngineProvider)`
  // styles here
`;

StyledStyledEngineProvider.propTypes = StyledEngineProvider.propTypes;
StyledStyledEngineProvider.displayName = "StyledEngineProvider";

export { StyledStyledEngineProvider };
