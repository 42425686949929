import React from "react";
import { StyledCardActions } from "./styles";


/**
 * General CardActions component in JSDOC
 * @param {object} props Component props
 *
 */
const CardActions = React.forwardRef((props, ref) => {
  return (
    <StyledCardActions {...props} ref={ref}/>
  );
});
CardActions.displayName = StyledCardActions.displayName;
CardActions.propTypes = StyledCardActions.propTypes;
export default CardActions;
