import styled from "@emotion/styled";
import { StepButton } from "@mui/material";

const StyledStepButton = styled(StepButton)`
  // styles here
`;

StyledStepButton.propTypes = StepButton.propTypes;
StyledStepButton.displayName = "StepButton";

export { StyledStepButton };
