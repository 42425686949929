import styled from "@emotion/styled";
import { FormLabel } from "@mui/material";

const StyledFormLabel = styled(FormLabel)`
  // styles here
`;

StyledFormLabel.propTypes = FormLabel.propTypes;
StyledFormLabel.displayName = "FormLabel";

export { StyledFormLabel };
