import styled from "@emotion/styled";
import { Snackbar } from "@mui/material";

const StyledSnackbar = styled(Snackbar)`
  // styles here
`;

StyledSnackbar.propTypes = Snackbar.propTypes;
StyledSnackbar.displayName = "Snackbar";

export { StyledSnackbar };
