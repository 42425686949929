import React from "react";
import { StyledNoSsr } from "./styles";


/**
 * General NoSsr component in JSDOC
 * @param {object} props Component props
 *
 */
const NoSsr = React.forwardRef((props, ref) => {
  return (
    <StyledNoSsr {...props} ref={ref}/>
  );
});
NoSsr.displayName = StyledNoSsr.displayName;
NoSsr.propTypes = StyledNoSsr.propTypes;
export default NoSsr;
