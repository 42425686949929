import styled from "@emotion/styled";
import { ImageList } from "@mui/material";

const StyledImageList = styled(ImageList)`
  // styles here
`;

StyledImageList.propTypes = ImageList.propTypes;
StyledImageList.displayName = "ImageList";

export { StyledImageList };
