import styled from "@emotion/styled";
import { ClickAwayListener } from "@mui/material";

const StyledClickAwayListener = styled(ClickAwayListener)`
  // styles here
`;

StyledClickAwayListener.propTypes = ClickAwayListener.propTypes;
StyledClickAwayListener.displayName = "ClickAwayListener";

export { StyledClickAwayListener };
