import styled from "@emotion/styled";
import { Badge } from "@mui/material";

const StyledBadge = styled(Badge)`
  // styles here
`;

StyledBadge.propTypes = Badge.propTypes;
StyledBadge.displayName = "Badge";

export { StyledBadge };
