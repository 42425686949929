import React from "react";
import { StyledPaper } from "./styles";


/**
 * General Paper component in JSDOC
 * @param {object} props Component props
 *
 */
const Paper = React.forwardRef((props, ref) => {
  return (
    <StyledPaper {...props} ref={ref}/>
  );
});
Paper.displayName = StyledPaper.displayName;
Paper.propTypes = StyledPaper.propTypes;
export default Paper;
