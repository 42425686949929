import styled from "@emotion/styled";
import { TabScrollButton } from "@mui/material";

const StyledTabScrollButton = styled(TabScrollButton)`
  // styles here
`;

StyledTabScrollButton.propTypes = TabScrollButton.propTypes;
StyledTabScrollButton.displayName = "TabScrollButton";

export { StyledTabScrollButton };
