import styled from "@emotion/styled";
import { SwipeableDrawer } from "@mui/material";

const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  // styles here
`;

StyledSwipeableDrawer.propTypes = SwipeableDrawer.propTypes;
StyledSwipeableDrawer.displayName = "SwipeableDrawer";

export { StyledSwipeableDrawer };
