import styled from "@emotion/styled";
import { Divider } from "@mui/material";

const StyledDivider = styled(Divider)`
  // styles here
`;

StyledDivider.propTypes = Divider.propTypes;
StyledDivider.displayName = "Divider";

export { StyledDivider };
