import styled from "@emotion/styled";
import { Grow } from "@mui/material";

const StyledGrow = styled(Grow)`
  // styles here
`;

StyledGrow.propTypes = Grow.propTypes;
StyledGrow.displayName = "Grow";

export { StyledGrow };
