import React from "react";
import { StyledAccordion } from "./styles";


/**
 * General Accordion component in JSDOC
 * @param {object} props Component props
 *
 */
const Accordion = React.forwardRef((props, ref) => {
  return (
    <StyledAccordion {...props} ref={ref}/>
  );
});
Accordion.displayName = StyledAccordion.displayName;
Accordion.propTypes = StyledAccordion.propTypes;
export default Accordion;
