import React from "react";
import { StyledInput } from "./styles";


/**
 * General Input component in JSDOC
 * @param {object} props Component props
 *
 */
const Input = React.forwardRef((props, ref) => {
  return (
    <StyledInput {...props} ref={ref}/>
  );
});
Input.displayName = StyledInput.displayName;
Input.propTypes = StyledInput.propTypes;
export default Input;
