/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ErrorBoundary from "./ErrorBoundary";
import SSOContext from "../hooks/SSOContext";
import {
  getToken,
  signInWithUsername,
  signInWithEmail,
  signUp,
  signOut,
} from "../services";
import {
  base64Decode,
  clearQueries,
  isSignOut,
  removeSignOut,
  retrieveChunckedToken,
  retrieveResponse,
  retrieveToken,
  saveObject,
  saveToken,
  searchQueries,
  setSignIn,
} from "../utils";

const Frame = ({ src, iframeRef }) => {
  return (
    <iframe
      src={src || `${process.env.REACT_APP_SSO_DOMAIN}/sso`}
      id="sso"
      style={{ display: "none" }}
      sandbox="allow-scripts allow-same-origin allow-popups allow-forms allow-modals allow-top-navigation-by-user-activation allow-storage-access-by-user-activation"
      ref={iframeRef}
      title="SSO"
    />
  );
};
const RawSSOComponent = ({ src, onInitDone, version, children }) => {
  if (!src) {
    src = `${process.env.REACT_APP_SSO_DOMAIN}/sso`;
    if (!process.env.REACT_APP_SSO_DOMAIN) {
      throw new Error(
        "REACT_APP_SSO_DOMAIN environment variable is not set. Please set it in .env file."
      );
    }
  }
  const iframeRef = React.useRef();
  const [isReady, setIsReady] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const responseObject = retrieveResponse();
    if (responseObject) {
      onInitDone && onInitDone(responseObject);
      setIsReady(true);
    }
    // saveVersion(version);
    const interval = setInterval(() => {
      const token = retrieveToken();
      if (!token) {
        // Show login screen or do something else
        //  signOut();
      }
    }, 10000); // We check every 10 seconds
    // debugger;
    const urlParams = new URLSearchParams(window.location.search);
    // get sso from query params non case sensitive
    const ssoQuery = urlParams.get("sso") || urlParams.get("SSO");
    const responseObjectQuery = urlParams.get("responseObject");

    let canLoadSSO = ssoQuery || responseObjectQuery;

    if (canLoadSSO ? !isSignOut() || canLoadSSO : !isSignOut() && canLoadSSO) {
      window.addEventListener("message", handleMessages);
    } else {
      const responseObject = retrieveResponse();
      if (responseObject) {
        onInitDone && onInitDone(responseObject);
        setIsReady(true);
        clearQueries();
      } else {
        onInitDone && onInitDone(null);
        setIsReady(true);
        clearQueries();
      }
    }
    return () => {
      window.removeEventListener("message", handleMessages);
      clearInterval(interval); // Cleanup
      return;
    };
  }, []);

  const handleMessages = (event) => {
    // debugger;
    if (event.origin !== process.env.REACT_APP_SSO_DOMAIN) {
      return;
    }
    const newResponse = retrieveResponse();
    if (newResponse) {
      if (onInitDone) {
        onInitDone(newResponse);
        setIsReady(true);
      }
      return;
    }
    if (event.data === "READY") {
      const urlParams = new URLSearchParams(window.location.search);
      const authorize = urlParams.get("authorize");
      const responseObject = retrieveResponse();

      if (!authorize && !responseObject) {
        const srcElementHref = process.env.REACT_APP_SSO_DOMAIN + "/sso";
        const redirectUrlWithAuth = new URL(srcElementHref);
        redirectUrlWithAuth.searchParams.set("authorize", "true");
        const currentTopUrl = window.location.href;
        if (!currentTopUrl) {
          console.error("currentTopUrl is null");
          return;
        }
        const redirectUrl = new URL(currentTopUrl);
        redirectUrlWithAuth.searchParams.set("redirectUrl", redirectUrl.href);
        window.location.replace(redirectUrlWithAuth.href);
      } else if (authorize && !responseObject) {
        const responseToken = urlParams.get("responseObject");
        const incoming = urlParams.get("incoming");
        if (responseToken) {
          let current = urlParams.get("current");
          let size = urlParams.get("size");

          if (current && size) {
            current = parseInt(current);
            size = parseInt(size);
            if (current < size) {
              current = current + 1;
              const srcElementHref = process.env.REACT_APP_SSO_DOMAIN + "/sso";
              const redirectUrlWithAuth = new URL(srcElementHref);
              redirectUrlWithAuth.searchParams.set("authorize", "true");
              const currentTopUrl = window.location.href;
              if (!currentTopUrl) {
                console.error("currentTopUrl is null");
                return;
              }
              const redirectUrl = new URL(currentTopUrl);
              redirectUrlWithAuth.searchParams.set(
                "redirectUrl",
                redirectUrl.href
              );

              async function saveTokenAndObject() {
                try {
                  const chuncks = await retrieveChunckedToken(size);
                  if (!chuncks) {
                    // receiver only
                    const incomingChunckedToken = responseToken;
                    console.log("current", current);
                    saveToken(incomingChunckedToken, current - 1);
                    redirectUrlWithAuth.searchParams.set("authorize", "true");
                    redirectUrlWithAuth.searchParams.set("current", current);
                    redirectUrlWithAuth.searchParams.set("size", size);
                    redirectUrlWithAuth.searchParams.delete("incoming");
                    redirectUrlWithAuth.searchParams.delete("responseObject");
                    window.location.replace(redirectUrlWithAuth.href);
                  } else {
                    if (!incoming) {
                      const nextToken = chuncks?.[current - 1];
                      redirectUrlWithAuth.searchParams.set(
                        "responseObject",
                        nextToken
                      );
                      redirectUrlWithAuth.searchParams.set(
                        "redirectUrl",
                        redirectUrl.href
                      );
                      // give start signal
                      redirectUrlWithAuth.searchParams.set("size", size);
                      redirectUrlWithAuth.searchParams.set("current", current);
                      window.location.replace(redirectUrlWithAuth.href);
                    } else {
                      // receiver only
                      const incomingChunckedToken = responseToken;
                      saveToken(incomingChunckedToken, current - 1);
                      redirectUrlWithAuth.searchParams.set("authorize", "true");
                      redirectUrlWithAuth.searchParams.set("current", current);
                      redirectUrlWithAuth.searchParams.set("size", size);
                      redirectUrlWithAuth.searchParams.delete("incoming");
                      redirectUrlWithAuth.searchParams.delete("responseObject");
                      window.location.replace(redirectUrlWithAuth.href);
                    }
                  }
                } catch (error) {}
              }
              saveTokenAndObject();
              // window.location.replace(redirectUrlWithAuth.href);
            } else {
              // data is ready
              async function handleData() {
                const retrieveChunckedTokenData = await retrieveChunckedToken(
                  size
                );
                const newToken = retrieveChunckedTokenData.join("");
                const decodeResponse = JSON.parse(await base64Decode(newToken));
                // console.info("Response token is ready", decodeResponse);
                saveObject(decodeResponse);
                saveToken(decodeResponse?.token);
                clearQueries();
                if (onInitDone) {
                  removeSignOut();
                  setSignIn();
                  onInitDone(decodeResponse);
                  setIsReady(true);
                }
              }
              handleData();
            }
          }
        } else {
          console.info("Response token is missing");
          if (onInitDone) {
            onInitDone(responseObject);
            setIsReady(true);
          }
        }
        clearQueries();
      } else {
        clearQueries();
        // We have a token already
        if (onInitDone) {
          onInitDone(responseObject);
          setIsReady(true);
        }
      }
    }

    const eventType = event?.data?.type;
    switch (eventType) {
      case "TOKEN":
        setToken(event?.data?.payload);
        break;
      case "ERROR":
        console.error(event?.data?.payload);
        break;
      default:
        break;
    }
  };
  return (
    <SSOContext.Provider
      value={{
        isReady,
        signInWithUsername,
        signInWithEmail,
        signUp,
        getToken,
        token,
        setToken,
        signOut,
      }}
    >
      <Frame iframeRef={iframeRef} src={src} />
      {isReady ? children : ""}
    </SSOContext.Provider>
  );
};

export function SSOComponent({ children, ...props }) {
  return (
    <ErrorBoundary onError={props.onError} renderComponent={children}>
      <RawSSOComponent {...props} children={children} />
    </ErrorBoundary>
  );
}
