import React from "react";
import { StyledAccordionSummary } from "./styles";


/**
 * General AccordionSummary component in JSDOC
 * @param {object} props Component props
 *
 */
const AccordionSummary = React.forwardRef((props, ref) => {
  return (
    <StyledAccordionSummary {...props} ref={ref}/>
  );
});
AccordionSummary.displayName = StyledAccordionSummary.displayName;
AccordionSummary.propTypes = StyledAccordionSummary.propTypes;
export default AccordionSummary;
