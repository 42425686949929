import styled from "@emotion/styled";
import { Switch } from "@mui/material";

const StyledSwitch = styled(Switch)`
  // styles here
`;

StyledSwitch.propTypes = Switch.propTypes;
StyledSwitch.displayName = "Switch";

export { StyledSwitch };
