import styled from "@emotion/styled";
import { Step } from "@mui/material";

const StyledStep = styled(Step)`
  // styles here
`;

StyledStep.propTypes = Step.propTypes;
StyledStep.displayName = "Step";

export { StyledStep };
