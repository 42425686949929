import styled from "@emotion/styled";
import { Stack } from "@mui/material";

const StyledStack = styled(Stack)`
  // styles here
`;

StyledStack.propTypes = Stack.propTypes;
StyledStack.displayName = "Stack";

export { StyledStack };
