import React from "react";
import { StyledChip } from "./styles";


/**
 * General Chip component in JSDOC
 * @param {object} props Component props
 *
 */
const Chip = React.forwardRef((props, ref) => {
  return (
    <StyledChip {...props} ref={ref}/>
  );
});
Chip.displayName = StyledChip.displayName;
Chip.propTypes = StyledChip.propTypes;
export default Chip;
