import styled from "@emotion/styled";
import { SpeedDialAction } from "@mui/material";

const StyledSpeedDialAction = styled(SpeedDialAction)`
  // styles here
`;

StyledSpeedDialAction.propTypes = SpeedDialAction.propTypes;
StyledSpeedDialAction.displayName = "SpeedDialAction";

export { StyledSpeedDialAction };
