import React from "react";
import { StyledDrawer } from "./styles";

/**
 * General Drawer component in JSDOC
 * @param {object} props Component props
 *
 */
const Drawer = React.forwardRef((props, ref) => {
  return (
      <StyledDrawer {...props} ref={ref}/>
  );
});
Drawer.displayName = StyledDrawer.displayName;
Drawer.propTypes = StyledDrawer.propTypes;
export default Drawer;
