import React from "react";
import { StyledDialogContentText } from "./styles";


/**
 * General DialogContentText component in JSDOC
 * @param {object} props Component props
 *
 */
const DialogContentText = React.forwardRef((props, ref) => {
  return (
    <StyledDialogContentText {...props} ref={ref}/>
  );
});
DialogContentText.displayName = StyledDialogContentText.displayName;
DialogContentText.propTypes = StyledDialogContentText.propTypes;
export default DialogContentText;
