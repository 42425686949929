import React from "react";
import { StyledTextField } from "./styles";


/**
 * General TextField component in JSDOC
 * @param {object} props Component props
 *
 */
const TextField = React.forwardRef((props, ref) => {
  return (
    <StyledTextField {...props} ref={ref}/>
  );
});
TextField.displayName = StyledTextField.displayName;
TextField.propTypes = StyledTextField.propTypes;
export default TextField;
