import styled from "@emotion/styled";
import { Tab } from "@mui/material";

const StyledTab = styled(Tab)`
  // styles here
`;

StyledTab.propTypes = Tab.propTypes;
StyledTab.displayName = "Tab";

export { StyledTab };

// GetStatus
// Leave the Height //
