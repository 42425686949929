import React from "react";
import { StyledBottomNavigationAction } from "./styles";


/**
 * General BottomNavigationAction component in JSDOC
 * @param {object} props Component props
 *
 */
const BottomNavigationAction = React.forwardRef((props, ref) => {
  return (
    <StyledBottomNavigationAction {...props} ref={ref}/>
  );
});
BottomNavigationAction.displayName = StyledBottomNavigationAction.displayName;
BottomNavigationAction.propTypes = StyledBottomNavigationAction.propTypes;
export default BottomNavigationAction;
