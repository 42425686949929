import styled from "@emotion/styled";
import { NativeSelect } from "@mui/material";

const StyledNativeSelect = styled(NativeSelect)`
  // styles here
`;

StyledNativeSelect.propTypes = NativeSelect.propTypes;
StyledNativeSelect.displayName = "NativeSelect";

export { StyledNativeSelect };
