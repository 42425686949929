import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";

const StyledTooltip = styled(Tooltip)`
  // styles here
`;

StyledTooltip.propTypes = Tooltip.propTypes;
StyledTooltip.displayName = "Tooltip";

export { StyledTooltip };
