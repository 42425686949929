import styled from "@emotion/styled";
import { Modal } from "@mui/material";

const StyledModal = styled(Modal)`
  // styles here
`;

StyledModal.propTypes = Modal.propTypes;
StyledModal.displayName = "Modal";

export { StyledModal };
