import React from "react";
import { StyledButtonBase } from "./styles";


/**
 * General ButtonBase component in JSDOC
 * @param {object} props Component props
 *
 */
const ButtonBase = React.forwardRef((props, ref) => {
  return (
    <StyledButtonBase {...props} ref={ref}/>
  );
});
ButtonBase.displayName = StyledButtonBase.displayName;
ButtonBase.propTypes = StyledButtonBase.propTypes;
export default ButtonBase;
