import React from "react";
import { StyledSwitch } from "./styles";


/**
 * General Switch component in JSDOC
 * @param {object} props Component props
 *
 */
const Switch = React.forwardRef((props, ref) => {
  return (
    <StyledSwitch {...props} ref={ref}/>
  );
});
Switch.displayName = StyledSwitch.displayName;
Switch.propTypes = StyledSwitch.propTypes;
export default Switch;
