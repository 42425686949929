import styled from "@emotion/styled";
import { TableBody } from "@mui/material";

const StyledTableBody = styled(TableBody)`
  // styles here
`;

StyledTableBody.propTypes = TableBody.propTypes;
StyledTableBody.displayName = "TableBody";

export { StyledTableBody };
