import styled from "@emotion/styled";
import { Backdrop } from "@mui/material";

const StyledBackdrop = styled(Backdrop)`
  // styles here
`;

StyledBackdrop.propTypes = Backdrop.propTypes;
StyledBackdrop.displayName = "Backdrop";

export { StyledBackdrop };
