import styled from "@emotion/styled";
import { Checkbox } from "@mui/material";

const StyledCheckbox = styled(Checkbox)`
  // styles here
`;

StyledCheckbox.propTypes = Checkbox.propTypes;
StyledCheckbox.displayName = "Checkbox";

export { StyledCheckbox };
