import styled from "@emotion/styled";
import { AccordionDetails } from "@mui/material";

const StyledAccordionDetails = styled(AccordionDetails)`
  // styles here
`;

StyledAccordionDetails.propTypes = AccordionDetails.propTypes;
StyledAccordionDetails.displayName = "AccordionDetails";

export { StyledAccordionDetails };
