import styled from "@emotion/styled";
import { TableContainer } from "@mui/material";

const StyledTableContainer = styled(TableContainer)`
  // styles here
`;

StyledTableContainer.propTypes = TableContainer.propTypes;
StyledTableContainer.displayName = "TableContainer";

export { StyledTableContainer };
