import React from "react";
import { StyledStack } from "./styles";


/**
 * General Stack component in JSDOC
 * @param {object} props Component props
 *
 */
const Stack = React.forwardRef((props, ref) => {
  return (
    <StyledStack {...props} ref={ref}/>
  );
});
Stack.displayName = StyledStack.displayName;
Stack.propTypes = StyledStack.propTypes;
export default Stack;
