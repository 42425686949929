import React from "react";
import { StyledListItem } from "./styles";


/**
 * General ListItem component in JSDOC
 * @param {object} props Component props
 *
 */
const ListItem = React.forwardRef((props, ref) => {
  return (
    <StyledListItem {...props} ref={ref}/>
  );
});
ListItem.displayName = StyledListItem.displayName;
ListItem.propTypes = StyledListItem.propTypes;
export default ListItem;
