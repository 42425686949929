import React from "react";
import { StyledClickAwayListener } from "./styles";


/**
 * General ClickAwayListener component in JSDOC
 * @param {object} props Component props
 *
 */
const ClickAwayListener = React.forwardRef((props, ref) => {
  return (
    <StyledClickAwayListener {...props} ref={ref}/>
  );
});
ClickAwayListener.displayName = StyledClickAwayListener.displayName;
ClickAwayListener.propTypes = StyledClickAwayListener.propTypes;
export default ClickAwayListener;
