import styled from "@emotion/styled";
import { AccordionSummary } from "@mui/material";

const StyledAccordionSummary = styled(AccordionSummary)`
  // styles here
`;

StyledAccordionSummary.propTypes = AccordionSummary.propTypes;
StyledAccordionSummary.displayName = "AccordionSummary";

export { StyledAccordionSummary };
