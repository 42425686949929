import React from "react";
import { StyledModal } from "./styles";


/**
 * General Modal component in JSDOC
 * @param {object} props Component props
 *
 */
const Modal = React.forwardRef((props, ref) => {
  return (
    <StyledModal {...props} ref={ref}/>
  );
});
Modal.displayName = StyledModal.displayName;
Modal.propTypes = StyledModal.propTypes;
export default Modal;
