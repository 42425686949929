import React from "react";
import { StyledGlobalStyles } from "./styles";


/**
 * General GlobalStyles component in JSDOC
 * @param {object} props Component props
 *
 */
const GlobalStyles = React.forwardRef((props, ref) => {
  return (
    <StyledGlobalStyles {...props} ref={ref}/>
  );
});
GlobalStyles.displayName = StyledGlobalStyles.displayName;
GlobalStyles.propTypes = StyledGlobalStyles.propTypes;
export default GlobalStyles;
