import styled from "@emotion/styled";
import { AvatarGroup } from "@mui/material";

const StyledAvatarGroup = styled(AvatarGroup)`
  // styles here
`;

StyledAvatarGroup.propTypes = AvatarGroup.propTypes;
StyledAvatarGroup.displayName = "AvatarGroup";

export { StyledAvatarGroup };
