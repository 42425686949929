import React from "react";
import { StyledStyledEngineProvider } from "./styles";


/**
 * General StyledEngineProvider component in JSDOC
 * @param {object} props Component props
 *
 */
const StyledEngineProvider = React.forwardRef((props, ref) => {
  return (
    <StyledStyledEngineProvider {...props} ref={ref}/>
  );
});
StyledEngineProvider.displayName = StyledStyledEngineProvider.displayName;
StyledEngineProvider.propTypes = StyledStyledEngineProvider.propTypes;
export default StyledEngineProvider;
