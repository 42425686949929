import React from "react";
import { StyledBreadcrumbs } from "./styles";


/**
 * General Breadcrumbs component in JSDOC
 * @param {object} props Component props
 *
 */
const Breadcrumbs = React.forwardRef((props, ref) => {
  return (
    <StyledBreadcrumbs {...props} ref={ref}/>
  );
});
Breadcrumbs.displayName = StyledBreadcrumbs.displayName;
Breadcrumbs.propTypes = StyledBreadcrumbs.propTypes;
export default Breadcrumbs;
