import styled from "@emotion/styled";
import { List } from "@mui/material";

const StyledList = styled(List)`
  // styles here
`;

StyledList.propTypes = List.propTypes;
StyledList.displayName = "List";

export { StyledList };
