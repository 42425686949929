import styled from "@emotion/styled";
import { Skeleton } from "@mui/material";

const StyledSkeleton = styled(Skeleton)`
  // styles here
`;

StyledSkeleton.propTypes = Skeleton.propTypes;
StyledSkeleton.displayName = "Skeleton";

export { StyledSkeleton };
