import React from "react";
import { StyledSwipeableDrawer } from "./styles";


/**
 * General SwipeableDrawer component in JSDOC
 * @param {object} props Component props
 *
 */
const SwipeableDrawer = React.forwardRef((props, ref) => {
  return (
    <StyledSwipeableDrawer {...props} ref={ref}/>
  );
});
SwipeableDrawer.displayName = StyledSwipeableDrawer.displayName;
SwipeableDrawer.propTypes = StyledSwipeableDrawer.propTypes;
export default SwipeableDrawer;
