import React from "react";
import { StyledLinearProgress } from "./styles";


/**
 * General LinearProgress component in JSDOC
 * @param {object} props Component props
 *
 */
const LinearProgress = React.forwardRef((props, ref) => {
  return (
    <StyledLinearProgress {...props} ref={ref}/>
  );
});
LinearProgress.displayName = StyledLinearProgress.displayName;
LinearProgress.propTypes = StyledLinearProgress.propTypes;
export default LinearProgress;
