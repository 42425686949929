import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import AlertDialog from "./AlertDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    justifyContent: "space-between",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [bottom, setBottom] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleScroll = (event) => {
    const bottom =
      event.target.scrollHeight - event.target.scrollTop - 2 <
      event.target.clientHeight;
    if (bottom) {
      setBottom(bottom);
    }
  };

  return (
    <>
      <span style={{ cursor: "pointer" }}>
        <Link
          color="primary"
          onClick={handleClickOpen}
          underline="always"
          variant="subtitle2"
        >
          {props.trigger}
        </Link>
      </span>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Typography color="textPrimary" variant="h4">
            {props.title}
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            {props.subTitle}
          </Typography>
        </BootstrapDialogTitle>

        <DialogContent dividers onScroll={handleScroll}>
          {props.content}
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!bottom}
            variant="contained"
            onClick={() => {
              if (props.trigger === "Terms of Use") {
                props.formik.setFieldValue("termsOfUse", true);
              }
              if (props.trigger === "Privacy Policy") {
                props.formik.setFieldValue("privacyPolicy", true);
              }
              return handleClose();
            }}
          >
            Accept
          </Button>
          <AlertDialog>
            <Button variant="contained" color="warning">
              Decline
            </Button>
          </AlertDialog>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
