import React from "react";
import { StyledTableContainer } from "./styles";


/**
 * General TableContainer component in JSDOC
 * @param {object} props Component props
 *
 */
const TableContainer = React.forwardRef((props, ref) => {
  return (
    <StyledTableContainer {...props} ref={ref}/>
  );
});
TableContainer.displayName = StyledTableContainer.displayName;
TableContainer.propTypes = StyledTableContainer.propTypes;
export default TableContainer;
