import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Page,
  Link,
  Snackbar,
  Alert,
} from "../../components";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

// import { useQueryParams } from "../../utils/useQueryParams";

export const ForgotPassword = ({
  signInPath = "/sign-in",
  Auth,
  product,
  resetPassword,
}) => {
  // const { email } = useQueryParams();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const maskEmail = (email) => {
    let maskedEmail = email.split("@");
    maskedEmail[0] = maskedEmail[0][0] + "***";
    if (maskedEmail.length > 1) {
      let maskedUsername = maskedEmail[1].split(".");
      maskedUsername[0] = maskedUsername[0][0] + "***";
      maskedEmail =
        maskedEmail[0] + "@" + maskedUsername[0] + "." + maskedUsername[1];
    }
    return maskedEmail;
  };
  const navigate = useNavigate();

  // get query params
  const isSSO = window?.location?.search?.includes("sso=true");

  const formik = useFormik({
    initialValues: {
      email: "",
      code: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: !verified
      ? Yup.object({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
        })
      : Yup.object({
          code: Yup.string().max(255).trim().required("Code is required"),
          newPassword: Yup.string()
            .min(6)
            .max(255)
            .required("Password is required")
            .matches(/[A-Z]/, "Need at least an uppercase letter")
            .matches(/[a-z]/, "Need at least a lowercase letter")
            .matches(/[0-9]/, "Need at least a number")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
              "Need at least a special character"
            ),
          // .matches(/[^A-Za-z0-9]/, "Need at least a special character"),
          confirmNewPassword: Yup.string()
            .required("Confirm New Password is required")
            .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
        }),
    onSubmit: () => {
      async function submit() {
        let { email, code, newPassword } = formik.values;

        try {
          if (!verified) {
            if (typeof resetPassword === "function" && isSSO) {
              await resetPassword(String(email).trim());
              setSuccess("Password reset link sent to your email.");
              setTimeout(() => {
                navigate(`${signInPath}`);
              }, 5000);
            } else {
              await Auth.forgotPassword(String(email).trim());
              setVerified(true);
            }
            // console.log(formik.values);
            formik.setSubmitting(false);
          } else {
            if (typeof resetPassword === "function" && isSSO) {
              await resetPassword(String(email).trim());
              setSuccess("Password reset link sent to your email.");
              setTimeout(() => {
                navigate(`${signInPath}`);
              }, 5000);
            } else {
              // console.log(formik.values);
              await Auth.forgotPasswordSubmit(
                String(email).trim(),
                String(code).trim(),
                newPassword
              );
              // alert snackbar

              navigate(`${signInPath}`);
            }
          }
        } catch (error) {
          // alert(error.error);
          setError(error.error.message);
          formik.setSubmitting(false);
        }
      }
      submit();
    },
  });

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError("");
  };

  return (
    <Page title={product ? `Forgot Password | ${product}` : "Forgot Password"}>
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container maxWidth="sm">
          <Snackbar
            open={String(success).trim() !== ""}
            autoHideDuration={6000}
            onClose={handleCloseToast}
            sx={{
              position: "relative",
              top: "16px",
              left: "0px !important",
              pb: 2,
            }}
            // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseToast}
              severity="success"
              sx={{ width: "100%" }}
              variant="filled"
            >
              {success}
            </Alert>
          </Snackbar>
          <Snackbar
            open={String(error).trim() !== ""}
            autoHideDuration={6000}
            onClose={handleCloseToast}
            sx={{
              position: "relative",
              top: "16px",
              left: "0px !important",
              pb: 2,
            }}
            // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          >
            <Alert
              onClose={handleCloseToast}
              severity="error"
              sx={{ width: "100%" }}
              variant="filled"
            >
              {error}
            </Alert>
          </Snackbar>
          <form onSubmit={formik.handleSubmit}>
            {!verified ? (
              <>
                {" "}
                <Box sx={{ my: 3 }}>
                  {" "}
                  <Typography color="textPrimary" variant="h4">
                    Forgot your password?
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Please enter your email below.
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                  variant="outlined"
                />
              </>
            ) : (
              <>
                <Box sx={{ my: 3 }}>
                  {" "}
                  <Typography color="textPrimary" variant="h4">
                    Reset Password
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    {`We sent a verification code to ${maskEmail(
                      formik.values.email
                    )}`}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(formik.touched.code && formik.errors.code)}
                  fullWidth
                  helperText={formik.touched.code && formik.errors.code}
                  label="Please enter your verification code 'e.g 123456'"
                  margin="normal"
                  name="code"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.code}
                />
                <TextField
                  error={Boolean(
                    formik.touched.newPassword && formik.errors.newPassword
                  )}
                  fullWidth
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  label="New Password"
                  margin="normal"
                  name="newPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.newPassword}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(
                    formik.touched.confirmNewPassword &&
                      formik.errors.confirmNewPassword
                  )}
                  fullWidth
                  helperText={
                    formik.touched.confirmNewPassword &&
                    formik.errors.confirmNewPassword
                  }
                  label="Confirm New Password"
                  margin="normal"
                  name="confirmNewPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.confirmNewPassword}
                  variant="outlined"
                />
              </>
            )}
            <Box sx={{ pt: 2 }}>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body2">
              Back to{" "}
              <span style={{ cursor: "pointer" }}>
                <Link
                  variant="subtitle2"
                  underline="hover"
                  onClick={() => navigate(`${signInPath}`)}
                >
                  Sign In
                </Link>
              </span>
            </Typography>
          </form>
        </Container>
      </Box>

      {/* <Snackbar
        open={String(error).trim() !== ""}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseToast}
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar> */}
    </Page>
  );
};
