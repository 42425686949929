import styled from "@emotion/styled";
import { Container } from "@mui/material";
import PropTypes from 'prop-types';


const StyledContainer = styled(Container)`
  // styles here
`;

StyledContainer.propTypes = Container.propTypes;
StyledContainer.displayName = "Container";

export { StyledContainer };
