import styled from "@emotion/styled";
import { CardHeader } from "@mui/material";

const StyledCardHeader = styled(CardHeader)`
  // styles here
`;

StyledCardHeader.propTypes = CardHeader.propTypes;
StyledCardHeader.displayName = "CardHeader";

export { StyledCardHeader };
