import { signOut, signOutAll } from "../services";

async function gzipAndBase64Encode(inputString) {
  // Step 1: Gzip the value
  const utf8Encoder = new TextEncoder();
  const compressedStream = new CompressionStream("gzip");
  const writableStream = compressedStream.writable;

  const writer = writableStream.getWriter();
  writer.write(utf8Encoder.encode(inputString));
  writer.close();

  // Step 2: Convert the stream to a Blob
  const blob = await new Response(compressedStream.readable).blob();

  // Step 3: Read the Blob as an ArrayBuffer
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const arrayBuffer = reader.result;

      // Step 4: Convert the ArrayBuffer to a Base64 string
      const base64String = arrayBufferToBase64(arrayBuffer);
      resolve(base64String);
    };
    reader.onerror = reject;

    reader.readAsArrayBuffer(blob);
  });
}

function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;

  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }

  return btoa(binary);
}

async function base64DecodeAndGunzip(base64String) {
  // Step 1: Decode from Base64
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Step 2: Convert binary data to a Blob
  const blob = new Blob([bytes]);

  // Step 3: Decompress using a DecompressionStream
  const decompressedStream = new DecompressionStream("gzip");
  const decompressedBlob = blob.stream().pipeThrough(decompressedStream);

  // Step 4: Read the result and convert it back to a string
  const reader = decompressedBlob.getReader();
  const chunks = [];
  let result;
  while (!(result = await reader.read()).done) {
    chunks.push(result.value);
  }

  const utf8Decoder = new TextDecoder();
  return utf8Decoder.decode(concatenateUint8Arrays(chunks));
}

function concatenateUint8Arrays(arrays) {
  let totalLength = arrays.reduce((sum, arr) => sum + arr.length, 0);
  let result = new Uint8Array(totalLength);
  let offset = 0;
  for (let array of arrays) {
    result.set(array, offset);
    offset += array.length;
  }
  return result;
}

function toUrlSafeBase64(base64String) {
  return base64String
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// Convert from URL-safe Base64
function fromUrlSafeBase64(urlSafeBase64String) {
  let base64String = urlSafeBase64String.replace(/-/g, "+").replace(/_/g, "/");
  while (base64String.length % 4) {
    base64String += "=";
  }
  return base64String;
}

export async function base64Encode(str) {
  if (!str) return null;
  const compressed = await gzipAndBase64Encode(toUrlSafeBase64(str));
  return compressed;
}

export async function base64Decode(str) {
  if (!str) return null;
  const decompressed = await base64DecodeAndGunzip(fromUrlSafeBase64(str));
  return decompressed;
}

export function saveToken(token, index = false) {
  try {
    if (index !== false) {
      localStorage.setItem(`authToken${index}`, token);
    } else {
      localStorage.setItem("authToken", token);
    }
  } catch (error) {
    console.error("Unable to save token:", error);
  }
}

export async function saveChunckedToken(token) {
  return new Promise((resolve, reject) => {
    try {
      const tokenArray = token.match(/.{1,3500}/g);
      tokenArray.forEach((token, index) => {
        localStorage.setItem(`authToken${index}`, token);
      });
      resolve({
        success: true,
        tokenArray,
        length: tokenArray.length,
      });
    } catch (error) {
      console.error("Unable to save token:", error);
      reject(error);
    }
  });
}

export async function retrieveChunckedToken(size) {
  return new Promise((resolve, reject) => {
    try {
      const tokenArray = [];
      for (let index = 0; index < size; index++) {
        const token = localStorage.getItem(`authToken${index}`);
        tokenArray.push(token);
      }
      resolve(tokenArray);
    } catch (error) {
      console.error("Unable to retrieve token:", error);
      reject(error);
    }
  });
}

export function saveObject(object) {
  try {
    if (!object) return;
    localStorage.setItem("authResponseObject", JSON.stringify(object));
  } catch (error) {
    console.error("Unable to save object:", error);
  }
}

export function retrieveToken(index) {
  let token;
  if (index) {
    token = localStorage.getItem(`authToken${index}`);
  } else {
    token = localStorage.getItem("authToken");
  }

  if (!token) {
    return null;
  }

  return token;
}

function simpleBase64Encode(str) {
  return btoa(unescape(encodeURIComponent(str)));
}

function simpleBase64Decode(str) {
  return decodeURIComponent(escape(atob(str)));
}

export function retrieveResponse() {
  let responseObject = localStorage.getItem("authResponseObject");

  if (!responseObject) {
    return null;
  }
  responseObject = JSON.parse(responseObject);
  let token = responseObject?.token;
  const base64DecodeToken = simpleBase64Decode(token);
  let expires = null;
  if (base64DecodeToken) {
    const tokenObject = JSON.parse(base64DecodeToken);
    expires = tokenObject?.expires;
  }
  if (expires) {
    const now = new Date().getTime();
    const expireDate = new Date(expires).getTime();
    if (now > expireDate) {
      signOutAll();
      responseObject = null;
      return responseObject;
    }
  }
  return responseObject;
}
export function deleteToken() {
  localStorage.removeItem("authToken");
  const length = localStorage.length;
  for (let index = 0; index < length; index++) {
    localStorage.removeItem(`authToken${index}`);
  }
}

export function deleteResponseObject() {
  localStorage.removeItem("authResponseObject");
  const length = localStorage.length;
  for (let index = 0; index < length; index++) {
    localStorage.removeItem(`authResponseObject${index}`);
  }
}

export function saveVersion(version) {
  try {
    localStorage.setItem("authVersion", version);
  } catch (error) {
    console.error("Unable to save version:", error);
  }
}

export function retrieveVersion() {
  let version = localStorage.getItem("authVersion");

  if (!version) {
    return null;
  }

  return version;
}

export async function saveTokenAndObject(res) {
  // store response object locally
  const srcElementHref = process.env.REACT_APP_SSO_DOMAIN + "/sso";
  const redirectUrlWithResponseObject = new URL(srcElementHref);
  try {
    const encodeRes = await base64Encode(JSON.stringify(res));
    const chuncks = await saveChunckedToken(encodeRes);
    const chuncksLength = chuncks?.length;
    const tokenArray = chuncks?.tokenArray;
    const firstToken = tokenArray?.[0];

    if (isSignOut()) {
      removeSignOut();
    }

    redirectUrlWithResponseObject.searchParams.set(
      "responseObject",
      firstToken
    );
    const currentTopUrl = window.location.href;
    const redirectUrl = new URL(currentTopUrl);
    redirectUrlWithResponseObject.searchParams.set(
      "redirectUrl",
      redirectUrl.href
    );
    // give start signal
    redirectUrlWithResponseObject.searchParams.set("start", "true");
    redirectUrlWithResponseObject.searchParams.set("size", chuncksLength);
    redirectUrlWithResponseObject.searchParams.set("current", 1);
    window.location.replace(redirectUrlWithResponseObject.href);
  } catch (error) {}
}

export function isSignIn() {
  return localStorage.getItem("signIn");
}

export function setSignIn() {
  localStorage.setItem("signIn", "true");
}

export function removeSignIn() {
  localStorage.removeItem("signIn");
}

export function setSignOut() {
  removeSignIn();
  localStorage.removeItem("authToken");
  localStorage.setItem("signOut", "true");
}

export function isSignOut() {
  return localStorage.getItem("signOut");
}

export function removeSignOut() {
  localStorage.removeItem("signOut");
}

export const searchQueries = [
  "responseObject",
  "authorize",
  "redirectUrl",
  "signOut",
  "current",
  "size",
  "incoming",
  "start",
  "end",
  "SSO",
  "sso",
];

export const clearQueries = () => {
  searchQueries.forEach((query) => {
    const url = new URL(window.location.href);
    url.searchParams.delete(query);
    window.history.replaceState({}, "", url);
  });
};
