import React from "react";
import { StyledListItemAvatar } from "./styles";


/**
 * General ListItemAvatar component in JSDOC
 * @param {object} props Component props
 *
 */
const ListItemAvatar = React.forwardRef((props, ref) => {
  return (
    <StyledListItemAvatar {...props} ref={ref}/>
  );
});
ListItemAvatar.displayName = StyledListItemAvatar.displayName;
ListItemAvatar.propTypes = StyledListItemAvatar.propTypes;
export default ListItemAvatar;
