import React from "react";
import { StyledInputLabel } from "./styles";


/**
 * General InputLabel component in JSDOC
 * @param {object} props Component props
 *
 */
const InputLabel = React.forwardRef((props, ref) => {
  return (
    <StyledInputLabel {...props} ref={ref}/>
  );
});
InputLabel.displayName = StyledInputLabel.displayName;
InputLabel.propTypes = StyledInputLabel.propTypes;
export default InputLabel;
