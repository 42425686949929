import React from "react";
import { StyledRadioGroup } from "./styles";


/**
 * General RadioGroup component in JSDOC
 * @param {object} props Component props
 *
 */
const RadioGroup = React.forwardRef((props, ref) => {
  return (
    <StyledRadioGroup {...props} ref={ref}/>
  );
});
RadioGroup.displayName = StyledRadioGroup.displayName;
RadioGroup.propTypes = StyledRadioGroup.propTypes;
export default RadioGroup;
