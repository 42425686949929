import React from "react";
import { StyledAppBar } from "./styles";


/**
 * General AppBar component in JSDOC
 * @param {object} props Component props
 *
 */
const AppBar = React.forwardRef((props, ref) => {
  return (
    <StyledAppBar {...props} ref={ref}/>
  );
});
AppBar.displayName = StyledAppBar.displayName;
AppBar.propTypes = StyledAppBar.propTypes;
export default AppBar;
