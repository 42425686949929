import styled from "@emotion/styled";
import { SpeedDial } from "@mui/material";

const StyledSpeedDial = styled(SpeedDial)`
  // styles here
`;

StyledSpeedDial.propTypes = SpeedDial.propTypes;
StyledSpeedDial.displayName = "SpeedDial";

export { StyledSpeedDial };
