import React from "react";
import { StyledBadge } from "./styles";


/**
 * General Badge component in JSDOC
 * @param {object} props Component props
 *
 */
const Badge = React.forwardRef((props, ref) => {
  return (
    <StyledBadge {...props} ref={ref}/>
  );
});
Badge.displayName = StyledBadge.displayName;
Badge.propTypes = StyledBadge.propTypes;
export default Badge;
