import styled from "@emotion/styled";
import { NoSsr } from "@mui/material";

const StyledNoSsr = styled(NoSsr)`
  // styles here
`;

StyledNoSsr.propTypes = NoSsr.propTypes;
StyledNoSsr.displayName = "NoSsr";

export { StyledNoSsr };
