import React from "react";
import { StyledStepper } from "./styles";


/**
 * General Stepper component in JSDOC
 * @param {object} props Component props
 *
 */
const Stepper = React.forwardRef((props, ref) => {
  return (
    <StyledStepper {...props} ref={ref}/>
  );
});
Stepper.displayName = StyledStepper.displayName;
Stepper.propTypes = StyledStepper.propTypes;
export default Stepper;
