import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  CreateAccount,
  SignIn,
  Verification,
  AuthRoute,
  ForgotPassword,
} from "../Auth";

function Authenticator({ withRouter, ...props }) {
  return !withRouter ? (
    <AuthenticatorRoutes {...props} />
  ) : (
    <>
      <Router>
        <AuthenticatorRoutes {...props} />
      </Router>
    </>
  );
}

const AuthenticatorRoutes = ({
  onSignIn,
  onSignInRedirect,
  Auth,
  displayLinks,
  product,
  description,
  signInPath,
  signUpPath,
  ssoPath,
  signInWithUsername,
  signInWithEmail,
  signUp,
  resetPassword,
  verifySignIn,
  responseObject,
  onResponseObjectRedirect,
  Mode,
  ssoTimeout = 5000,
}) => (
  <Routes>
    <Route
      path={signUpPath}
      element={
        <AuthRoute Auth={Auth} verifySignIn={verifySignIn}>
          <CreateAccount
            Mode={Mode}
            ssoTimeout={ssoTimeout}
            Auth={Auth}
            product={product}
            signUp={signUp}
          />
        </AuthRoute>
      }
    />
    <Route
      path={signInPath}
      element={
        <AuthRoute Auth={Auth} verifySignIn={verifySignIn}>
          <SignIn
            Auth={Auth}
            ssoPath={ssoPath}
            onSignIn={onSignIn}
            onSignInRedirect={onSignInRedirect}
            displayLinks={displayLinks}
            product={product}
            description={description}
            signInWithEmail={signInWithEmail}
            signInWithUsername={signInWithUsername}
            responseObject={responseObject}
            Mode={Mode}
            ssoTimeout={ssoTimeout}
            onResponseObjectRedirect={onResponseObjectRedirect}
          />
        </AuthRoute>
      }
    />
    <Route
      path="/verify-email"
      element={
        <AuthRoute Auth={Auth} verifySignIn={verifySignIn}>
          <Verification Auth={Auth} product={product} />
        </AuthRoute>
      }
    />
    <Route
      path="/forgot-password"
      element={
        <AuthRoute Auth={Auth} verifySignIn={verifySignIn}>
          <ForgotPassword
            Auth={Auth}
            product={product}
            resetPassword={resetPassword}
          />
        </AuthRoute>
      }
    />
  </Routes>
);

Authenticator.propTypes = {
  withRouter: PropTypes.bool,
  isProtected: PropTypes.bool,
  children: PropTypes.any,
  onSignIn: PropTypes.any,
  onSignInRedirect: PropTypes.string,
  Auth: PropTypes.any.isRequired,
  displayLinks: PropTypes.string,
  product: PropTypes.string,
  description: PropTypes.string,
  signInPath: PropTypes.string,
  signUpPath: PropTypes.string,
};

Authenticator.defaultProps = {
  withRouter: false,
  isProtected: true,
  signInPath: "/sign-in",
  signUpPath: "/create-account",
  product: "Columbia",
  description:
    "This application allows you to do these things and you should sign in to a family of products.",
};

export { Authenticator };
