import React from "react";
import { StyledPopper } from "./styles";


/**
 * General Popper component in JSDOC
 * @param {object} props Component props
 *
 */
const Popper = React.forwardRef((props, ref) => {
  return (
    <StyledPopper {...props} ref={ref}/>
  );
});
Popper.displayName = StyledPopper.displayName;
Popper.propTypes = StyledPopper.propTypes;
export default Popper;
