import styled from "@emotion/styled";
import { Link } from "@mui/material";

const StyledLink = styled(Link)`
  // styles here
`;

StyledLink.propTypes = Link.propTypes;
StyledLink.displayName = "Link";

export { StyledLink };
