import React from "react";
import { StyledOutlinedInput } from "./styles";


/**
 * General OutlinedInput component in JSDOC
 * @param {object} props Component props
 *
 */
const OutlinedInput = React.forwardRef((props, ref) => {
  return (
    <StyledOutlinedInput {...props} ref={ref}/>
  );
});
OutlinedInput.displayName = StyledOutlinedInput.displayName;
OutlinedInput.propTypes = StyledOutlinedInput.propTypes;
export default OutlinedInput;
