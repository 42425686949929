import styled from "@emotion/styled";
import { PaginationItem } from "@mui/material";

const StyledPaginationItem = styled(PaginationItem)`
  // styles here
`;

StyledPaginationItem.propTypes = PaginationItem.propTypes;
StyledPaginationItem.displayName = "PaginationItem";

export { StyledPaginationItem };
