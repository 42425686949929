import styled from "@emotion/styled";
import { ListItem } from "@mui/material";

const StyledListItem = styled(ListItem)`
  // styles here
`;

StyledListItem.propTypes = ListItem.propTypes;
StyledListItem.displayName = "ListItem";

export { StyledListItem };
