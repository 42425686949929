import styled from "@emotion/styled";
import { Drawer } from "@mui/material";


const StyledDrawer = styled(Drawer)`
  // styles here
`;

StyledDrawer.propTypes = Drawer.propTypes;
StyledDrawer.displayName = "Drawer";

export { StyledDrawer };
