import styled from "@emotion/styled";
import { TableFooter } from "@mui/material";

const StyledTableFooter = styled(TableFooter)`
  // styles here
`;

StyledTableFooter.propTypes = TableFooter.propTypes;
StyledTableFooter.displayName = "TableFooter";

export { StyledTableFooter };
