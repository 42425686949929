import styled from "@emotion/styled";
import { Zoom } from "@mui/material";

const StyledZoom = styled(Zoom)`
  // styles here
`;

StyledZoom.propTypes = Zoom.propTypes;
StyledZoom.displayName = "Zoom";

export { StyledZoom };
