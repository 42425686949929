import React from "react";
import { StyledTooltip } from "./styles";


/**
 * General Tooltip component in JSDOC
 * @param {object} props Component props
 *
 */
const Tooltip = React.forwardRef((props, ref) => {
  return (
    <StyledTooltip {...props} ref={ref}/>
  );
});
Tooltip.displayName = StyledTooltip.displayName;
Tooltip.propTypes = StyledTooltip.propTypes;
export default Tooltip;
