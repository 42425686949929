import React from "react";
import { StyledSlide } from "./styles";


/**
 * General Slide component in JSDOC
 * @param {object} props Component props
 *
 */
const Slide = React.forwardRef((props, ref) => {
  return (
    <StyledSlide {...props} ref={ref}/>
  );
});
Slide.displayName = StyledSlide.displayName;
Slide.propTypes = StyledSlide.propTypes;
export default Slide;
