import styled from "@emotion/styled";
import { StepIcon } from "@mui/material";

const StyledStepIcon = styled(StepIcon)`
  // styles here
`;

StyledStepIcon.propTypes = StepIcon.propTypes;
StyledStepIcon.displayName = "StepIcon";

export { StyledStepIcon };
