import styled from "@emotion/styled";
import { Radio } from "@mui/material";

const StyledRadio = styled(Radio)`
  // styles here
`;

StyledRadio.propTypes = Radio.propTypes;
StyledRadio.displayName = "Radio";

export { StyledRadio };
