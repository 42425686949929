import styled from "@emotion/styled";
import { SvgIcon } from "@mui/material";

const StyledSvgIcon = styled(SvgIcon)`
  // styles here
`;

StyledSvgIcon.propTypes = SvgIcon.propTypes;
StyledSvgIcon.displayName = "SvgIcon";

export { StyledSvgIcon };
