import styled from "@emotion/styled";
import { Slide } from "@mui/material";

const StyledSlide = styled(Slide)`
  // styles here
`;

StyledSlide.propTypes = Slide.propTypes;
StyledSlide.displayName = "Slide";

export { StyledSlide };
