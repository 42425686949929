import React from "react";
import { StyledTable } from "./styles";


/**
 * General Table component in JSDOC
 * @param {object} props Component props
 *
 */
const Table = React.forwardRef((props, ref) => {
  return (
    <StyledTable {...props} ref={ref}/>
  );
});
Table.displayName = StyledTable.displayName;
Table.propTypes = StyledTable.propTypes;
export default Table;
