import styled from "@emotion/styled";
import { InputBase } from "@mui/material";

const StyledInputBase = styled(InputBase)`
  // styles here
`;

StyledInputBase.propTypes = InputBase.propTypes;
StyledInputBase.displayName = "InputBase";

export { StyledInputBase };
