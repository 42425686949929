import React from "react";
import { StyledBottomNavigation } from "./styles";


/**
 * General BottomNavigation component in JSDOC
 * @param {object} props Component props
 *
 */
const BottomNavigation = React.forwardRef((props, ref) => {
  return (
    <StyledBottomNavigation {...props} ref={ref}/>
  );
});
BottomNavigation.displayName = StyledBottomNavigation.displayName;
BottomNavigation.propTypes = StyledBottomNavigation.propTypes;
export default BottomNavigation;
