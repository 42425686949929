import styled from "@emotion/styled";
import { Avatar } from "@mui/material";

const StyledAvatar = styled(Avatar)`
  // styles here
`;

StyledAvatar.propTypes = Avatar.propTypes;
StyledAvatar.displayName = "Avatar";

export { StyledAvatar };
