import styled from "@emotion/styled";
import { ImageListItem } from "@mui/material";

const StyledImageListItem = styled(ImageListItem)`
  // styles here
`;

StyledImageListItem.propTypes = ImageListItem.propTypes;
StyledImageListItem.displayName = "ImageListItem";

export { StyledImageListItem };
