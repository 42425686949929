import styled from "@emotion/styled";
import { TablePagination } from "@mui/material";

const StyledTablePagination = styled(TablePagination)`
  // styles here
`;

StyledTablePagination.propTypes = TablePagination.propTypes;
StyledTablePagination.displayName = "TablePagination";

export { StyledTablePagination };
