import React from "react";
import { StyledRadio } from "./styles";


/**
 * General Radio component in JSDOC
 * @param {object} props Component props
 *
 */
const Radio = React.forwardRef((props, ref) => {
  return (
    <StyledRadio {...props} ref={ref}/>
  );
});
Radio.displayName = StyledRadio.displayName;
Radio.propTypes = StyledRadio.propTypes;
export default Radio;
