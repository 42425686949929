import styled from "@emotion/styled";
import { AccordionActions } from "@mui/material";

const StyledAccordionActions = styled(AccordionActions)`
  // styles here
`;

StyledAccordionActions.propTypes = AccordionActions.propTypes;
StyledAccordionActions.displayName = "AccordionActions";

export { StyledAccordionActions };
