import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";

const StyledCircularProgress = styled(CircularProgress)`
  // styles here
`;

StyledCircularProgress.propTypes = CircularProgress.propTypes;
StyledCircularProgress.displayName = "CircularProgress";

export { StyledCircularProgress };
