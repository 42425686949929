import React from "react";
import { StyledSnackbarContent } from "./styles";


/**
 * General SnackbarContent component in JSDOC
 * @param {object} props Component props
 *
 */
const SnackbarContent = React.forwardRef((props, ref) => {
  return (
    <StyledSnackbarContent {...props} ref={ref}/>
  );
});
SnackbarContent.displayName = StyledSnackbarContent.displayName;
SnackbarContent.propTypes = StyledSnackbarContent.propTypes;
export default SnackbarContent;
