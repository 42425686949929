import React, { useState, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/lab/Alert";

function useNotification() {
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSeverity, setNotificationSeverity] = useState("success");
  const [onCloseCallback, setOnCloseCallback] = useState(null);

  const showNotification = useCallback(
    (message, severity = "success", callback = null) => {
      setNotificationMessage(message);
      setNotificationSeverity(severity);
      setOnCloseCallback(() => callback); // Storing the callback
      setNotificationOpen(true);
    },
    []
  );

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setNotificationOpen(false);
      if (onCloseCallback) {
        onCloseCallback(); // Executing the callback if provided
      }
    },
    [onCloseCallback]
  );

  const NotificationComponent = () => (
    <Snackbar
      open={isNotificationOpen}
      autoHideDuration={1000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity={notificationSeverity}
        variant="filled"
        sx={{ color: "#fff" }}
      >
        {notificationMessage}
      </Alert>
    </Snackbar>
  );

  return { showNotification, NotificationComponent };
}

export default useNotification;
