import styled from "@emotion/styled";
import { CardActionArea } from "@mui/material";

const StyledCardActionArea = styled(CardActionArea)`
  // styles here
`;

StyledCardActionArea.propTypes = CardActionArea.propTypes;
StyledCardActionArea.displayName = "CardActionArea";

export { StyledCardActionArea };
