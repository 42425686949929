import styled from "@emotion/styled";
import { Popover } from "@mui/material";

const StyledPopover = styled(Popover)`
  // styles here
`;

StyledPopover.propTypes = Popover.propTypes;
StyledPopover.displayName = "Popover";

export { StyledPopover };
