import React from "react";
import { StyledPaginationItem } from "./styles";


/**
 * General PaginationItem component in JSDOC
 * @param {object} props Component props
 *
 */
const PaginationItem = React.forwardRef((props, ref) => {
  return (
    <StyledPaginationItem {...props} ref={ref}/>
  );
});
PaginationItem.displayName = StyledPaginationItem.displayName;
PaginationItem.propTypes = StyledPaginationItem.propTypes;
export default PaginationItem;
