import styled from "@emotion/styled";
import { Box } from "@mui/material";

const StyledBox = styled(Box)`
  // styles here
`;

StyledBox.propTypes = Box.propTypes;
StyledBox.displayName = "Box";

export { StyledBox };
