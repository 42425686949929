import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  Page,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Card,
  Stack,
  Snackbar,
  Alert,
  Select,
  MenuItem,
} from "../../components";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import logoPng from "./normal_u27.png";
// import backgroundPng from "./sign_in_bg.png";
import logoSvg from "./normal_u7.svg";
import Divider from "./../../components/Divider/index";
import styled from "@emotion/styled";

const StyledCard = styled(Card)`
  /* border-top-left-radius: 0px !important;
  border-top-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 0px !important; */
`;

const SignIn = ({
  product,
  description,
  onSignIn,
  createAccountPath,
  forgotPasswordPath,
  onSignInRedirect,
  Auth,
  displayLinks = true,
  signInWithUsername,
  signInWithEmail,
  responseObject,
  onResponseObjectRedirect,
  Mode,
  ssoTimeout = 5000,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [authMethod, setAuthMethod] = useState("sso");

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().max(255).required("Username is required"),
      password: Yup.string().min(6).max(255).required("Password is required"),
      // .matches(/[A-Z]/, "Need at least an uppercase letter")
      // .matches(/[a-z]/, "Need at least a lowercase letter")
      // .matches(/[0-9]/, "Need at least a number")
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
      //   "Need at least a special character"
      // ),
    }),
    onSubmit: () => {
      async function submit() {
        let { username, password } = formik.values;
        try {
          if (authMethod === "sso") {
            setTimeout(() => {
              setError("Invalid email or password. Please try again.");
              formik.setSubmitting(false);
            }, ssoTimeout);
          }
          await modifiedOnSubmit(username, password);
          if (authMethod === "sso") {
            // if (onSignInRedirect) {
            //   window.location.href = onSignInRedirect;
            // }
          } else {
            try {
              if (onSignIn) {
                await onSignIn();
              }
            } catch (error) {
              console.error(error);
            }

            if (onSignInRedirect || onResponseObjectRedirect) {
              window.location.href =
                onSignInRedirect || onResponseObjectRedirect;
            }
            // navigate({
            //   pathname: onSignInRedirect || onResponseObjectRedirect,
            // });
          }
        } catch (error) {
          setError(error.error.message);
          formik.setSubmitting(false);
        }
      }
      submit();
    },
  });

  function isEmail(input) {
    // Regular expression for validating an email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(input);
  }

  const modifiedOnSubmit = async (email, password) => {
    if (authMethod === "sso") {
      // Handle SSO sign-in logic
      if (isEmail(email)) {
        if (signInWithEmail) {
          return onSubmitSSOEmail(email, password);
        }
      } else {
        if (signInWithUsername) {
          return onSubmitSSOUsername(email, password);
        }
      }
    } else {
      // Regular Cognito sign-in logic
      return onSubmit(email, password);
    }
  };

  /* Sign in function */
  const onSubmit = async (email, password) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await Auth.signIn(email, password);
        resolve({ payload: "SUCCESS" });
      } catch (error) {
        reject({ error: error });
      }
    });
  };

  const onSubmitSSOEmail = async (email, password) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await signInWithEmail(email, password);
        setTimeout(() => {
          console.info("SSO request timed out. Please try again.");
          reject({
            error: {
              message: "SSO request timed out. Please try again.",
            },
          });
        }, ssoTimeout);
        resolve({ payload: "SUCCESS" });
      } catch (error) {
        reject({ error: error });
      }
    });
  };

  const onSubmitSSOUsername = async (email, password) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await signInWithUsername(email, password);
        setTimeout(() => {
          console.info("SSO request timed out. Please try again.");
          reject({
            error: {
              message: "SSO request timed out. Please try again.",
            },
          });
        }, ssoTimeout);
        resolve({ payload: "SUCCESS" });
      } catch (error) {
        reject({ error: error });
      }
    });
  };

  const handleCloseToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setError("");
  };

  useEffect(
    () => {
      if (authMethod === "sso") {
        if (responseObject) {
          if (onSignInRedirect || onResponseObjectRedirect) {
            onSignIn && onSignIn();
            navigate({
              pathname: onSignInRedirect || onResponseObjectRedirect,
            });
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [responseObject]
  );

  useEffect(() => {
    if (Mode) {
      let mode = Mode;
      // convert to lowercase
      mode = mode.toLowerCase();
      // remove all spaces
      mode = mode.replace(/\s/g, "");
      // check if mode is sso
      if (mode === "sso") {
        setAuthMethod("sso");
      } else {
        setAuthMethod("regular");
      }
    }
  }, [Mode]);

  return (
    <Page title={product ? `Sign In | ${product}` : "Sign In"}>
      <Box
        component="main"
        sx={{
          // alignItems: "center",
          // py: 2,
          // add background image

          display: "flex",
          flexGrow: 1,
          minHeight: "100vh",
          // add background image with gradient
          // backgroundImage: `url('https://d3ab4pgc1q9ec1.cloudfront.net/client/image-20230103-172732.png')`,

          // backgroundColor: "rgba(0, 0, 0, 0.5)",
          background:
            "url('https://d3ab4pgc1q9ec1.cloudfront.net/client/image-20230103-172732.png'), linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(0, 0, 0, 0.96) 0%, rgba(0, 0, 0, 0) 95%)",
          //  linear-gradient(-180deg, rgba(255, 255, 255, 0.9) 0%, rgba(0, 0, 0, 0.96) 0%, rgba(0, 0, 0, 0) 95%)

          // backgroundColor: "#333",
          // backgroundImage: `url('https://d3ab4pgc1q9ec1.cloudfront.net/client/image-20230103-172732.png')`,
          // backgroundImage: `url('https://d3ab4pgc1q9ec1.cloudfront.net/client/image-20230103-172732.png'), linear-gradient(-180deg, rgba(255, 255, 255, 0.9) 0%, rgba(0, 0, 0, 0.96) 0%, rgba(0, 0, 0, 0) 95%)`,
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "cover",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "overlay",
        }}
      >
        <Container maxWidth="maxWidth">
          <Box sx={{ my: 2 }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box>
                <img
                  src={logoSvg}
                  // src={"https://d3ab4pgc1q9ec1.cloudfront.net/client/logo0.svg"}
                  alt="Fluke Logo SVG"
                  width="146px"
                />
              </Box>
              <Box>
                <img
                  src={
                    "https://d3ab4pgc1q9ec1.cloudfront.net/client/text_svg.svg"
                  }
                  alt="Fluke Logo SVG2"
                  width="392px"
                />
              </Box>
              {/* <Box
                sx={{ pl: { md: "16px", xs: 0 }, mt: { md: "-4px", xs: 0 } }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "#ffffff",
                    fontFamily: `"RobotoSlab-Bold", "Roboto Slab Bold", "Roboto Slab", sans-serif`,
                    fontWeight: 700,
                    color: `#ffffff`,
                    fontSize: "34px",
                  }}
                >
                  If it's Fluke, it's Seamless.
                </Typography>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontFamily: `"RobotoSlab-Bold", "Roboto Slab Bold", "Roboto Slab", sans-serif`,
                    fontWeight: 500,
                    color: `#ffffff`,
                    fontSize: "14px",
                  }}
                >
                  Here is your secure home for all the Fluke Software Platform.
                </Typography>
              </Box> */}
            </Stack>
          </Box>
          <Stack sx={{ maxWidth: "352px" }}>
            <Snackbar
              open={String(error).trim() !== ""}
              // autoHideDuration={6000}
              onClose={handleCloseToast}
              sx={{
                position: "relative",
                // mt: "16px",
                left: "0px !important",
                mt: 2,
                mb: 1,
              }}
              // anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                onClose={handleCloseToast}
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
              >
                {error}
              </Alert>
            </Snackbar>
            <Box
              sx={{
                alignItems: "flex-end",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "flex-start",
                minHeight: "calc(100vh/2)",
              }}
            >
              <StyledCard
                sx={{
                  width: "100%",
                  p: 2,
                }}
              >
                <Typography
                  sx={{
                    color: "#888888",
                    fontFamily: `'ArialMT', 'Arial', sans-serif`,
                    fontWeight: 700,
                    fontSize: "9px",
                  }}
                  align="right"
                >
                  Version 1.1
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        color: "#888888",
                        fontFamily: `"RobotoSlab-Black", "Roboto Slab Black", "Roboto Slab", sans-serif`,
                        fontWeight: 900,
                        fontSize: "32px",
                      }}
                    >
                      {product}
                    </Typography>
                    {/* <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "flex-start",
                        // mb: 1,
                        mt: -2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: `"Roboto-BoldItalic", "Roboto Bold Italic", "Roboto", sans-serif`,
                          fontStyle: "italic",
                          fontWeight: 700,
                          fontSize: "12px",
                          color: "#333333",
                          pr: 1,
                        }}
                        as="span"
                      >
                        Powered by
                      </Typography>
                      <img
                        src={logoPng}
                        alt="Fluke Logo PNG"
                        width="80px"
                        // height="50px"
                      />
                    </Box> */}
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                      sx={{ mb: 1 }}
                    >
                      {description}
                    </Typography>
                  </Box>
                  {displayLinks
                    ? createAccountPath && (
                        <Divider textAlign="left">
                          <Button
                            size="small"
                            variant="text"
                            color="primary"
                            onClick={() => navigate(createAccountPath)}
                          >
                            New user? Register
                          </Button>
                        </Divider>
                      )
                    : ""}

                  {/* MUI Dropdown for selecting authentication method */}

                  {!Mode && (
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        fontWeight={700}
                        sx={{ mb: "8px", pb: 0, mt: "16px" }}
                        variant="body2"
                      >
                        Sign-In Method
                      </Typography>
                      <FormControl size="small" fullWidth>
                        <Select
                          labelId="auth-method-label"
                          value={authMethod}
                          onChange={(e) => setAuthMethod(e.target.value)}
                          displayEmpty
                          fullWidth
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="regular">Legacy</MenuItem>
                          <MenuItem value="sso">SSO</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}

                  <Box sx={{ mb: 1 }}>
                    {/* <InputLabel sx={{ mb: 0.5 }}>Username</InputLabel> */}
                    <Typography
                      fontWeight={700}
                      sx={{ mb: "8px", pb: 0, mt: "16px" }}
                      variant="body2"
                    >
                      {authMethod === "sso" ? "Email or Username" : "Username"}
                    </Typography>
                    <TextField
                      size="small"
                      error={Boolean(
                        formik.touched.username && formik.errors.username
                      )}
                      fullWidth
                      sx={{ mt: 0 }}
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                      // margin="normal"
                      name="username"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.username}
                    />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    {" "}
                    <Typography
                      fontWeight={700}
                      sx={{ mb: "8px", pb: 0 }}
                      variant="body2"
                    >
                      Password
                    </Typography>
                    {/* <InputLabel sx={{ mb: 0.5 }}>Password</InputLabel> */}
                    <FormControl
                      fullWidth
                      // margin="normal"
                      name="password"
                      variant="outlined"
                      sx={{ mt: 0 }}
                      error={Boolean(
                        formik.touched.password && formik.errors.password
                      )}
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    >
                      <OutlinedInput
                        size="small"
                        id="password"
                        fullWidth
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type={showPassword ? "text" : "password"}
                        value={formik.values.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(
                        formik.touched.password && formik.errors.password
                      ) && (
                        <FormHelperText error>
                          {formik.errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Link
                        color="inherit"
                        variant="body2"
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (authMethod === "sso") {
                            navigate(forgotPasswordPath + "?sso=true");
                          } else {
                            navigate(forgotPasswordPath);
                          }
                        }}
                      >
                        Forgot Password
                      </Link>
                    </div>
                    {/* <Button
                      size="small"
                      variant="text"
                      color="primary"
                      onClick={() => navigate(forgotPasswordPath)}
                    ></Button> */}
                  </Box>

                  <Box sx={{ pt: 2 }}>
                    <Button
                      color="secondary"
                      disabled={formik.isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Log In
                    </Button>
                  </Box>
                  {/* 
                  <Divider sx={{ py: 2 }}>Or</Divider>
                  <Box>
                    <Button
                      color="primary"
                      disabled={formik.isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => navigate(ssoPath)}
                    >
                      Login Via SSO
                    </Button>
                  </Box> */}
                  {/* {displayLinks ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        size="small"
                        variant="text"
                        color="primary"
                        disabled
                        onClick={() => alert("Coming Soon!")}
                      >
                        Forgot Username?
                      </Button>
                      <Divider orientation="vertical" flexItem />
                      <Button
                        size="small"
                        variant="text"
                        color="primary"
                        onClick={() => navigate(forgotPasswordPath)}
                      >
                        Forgot Password?
                      </Button>
                    </Box>
                  ) : (
                    ""
                  )} */}
                </form>
              </StyledCard>
            </Box>

            {/* <Box
              sx={{
                alignItems: "flex-end",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "flex-start",
                minHeight: "calc(100vh/2)",
              }}
            >
              
            </Box> */}
          </Stack>
        </Container>
        <Box
          sx={{
            backgroundColor: "#333333",
            position: "fixed",
            width: "100vw",
            bottom: 0,
            padding: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: `'ArialMT', 'Arial', sans-serif`,
              fontSize: "10px",
              color: "#ffffff",
            }}
          >
            2024 ALL RIGHTS RESERVED.
          </Typography>
        </Box>
      </Box>
    </Page>
  );
};

SignIn.propTypes = {
  product: PropTypes.string,
  description: PropTypes.string,
  onSignIn: PropTypes.any,
  createAccountPath: PropTypes.string,
  forgotPasswordPath: PropTypes.string,
  ssoPath: PropTypes.string,
  onSignInRedirect: PropTypes.string,
  Auth: PropTypes.any.isRequired,
  displayLinks: PropTypes.bool,
};

SignIn.defaultProps = {
  product: "Columbia",
  description:
    "This application allows you to do these things and you should sign in to a family of products.",
  createAccountPath: "/create-account",
  forgotPasswordPath: "/forgot-password",
  onSignInRedirect: "/",
  ssoPath: "/sso/sign-in",
};

export { SignIn };
