import React from "react";
import { StyledListItemIcon } from "./styles";


/**
 * General ListItemIcon component in JSDOC
 * @param {object} props Component props
 *
 */
const ListItemIcon = React.forwardRef((props, ref) => {
  return (
    <StyledListItemIcon {...props} ref={ref}/>
  );
});
ListItemIcon.displayName = StyledListItemIcon.displayName;
ListItemIcon.propTypes = StyledListItemIcon.propTypes;
export default ListItemIcon;
