import styled from "@emotion/styled";
import { Popper } from "@mui/material";

const StyledPopper = styled(Popper)`
  // styles here
`;

StyledPopper.propTypes = Popper.propTypes;
StyledPopper.displayName = "Popper";

export { StyledPopper };
