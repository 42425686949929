import React from "react";
import { StyledTableCell } from "./styles";


/**
 * General TableCell component in JSDOC
 * @param {object} props Component props
 *
 */
const TableCell = React.forwardRef((props, ref) => {
  return (
    <StyledTableCell {...props} ref={ref}/>
  );
});
TableCell.displayName = StyledTableCell.displayName;
TableCell.propTypes = StyledTableCell.propTypes;
export default TableCell;
