import React from "react";
import { StyledStepConnector } from "./styles";


/**
 * General StepConnector component in JSDOC
 * @param {object} props Component props
 *
 */
const StepConnector = React.forwardRef((props, ref) => {
  return (
    <StyledStepConnector {...props} ref={ref}/>
  );
});
StepConnector.displayName = StyledStepConnector.displayName;
StepConnector.propTypes = StyledStepConnector.propTypes;
export default StepConnector;
